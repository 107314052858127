import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Link,
  Spinner,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { Formik } from "formik";
import * as React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { MobileMenu } from "../components/guestportal/MobileMenu";
import { useTrips } from "../contexts/TripContext";
import { handleReview } from "../helpers/guestportalfetch";

const Review = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { paramReservationNumber } = useParams();
  const [reservationLoaded, setReservationLoaded] = React.useState(false);

  const {
    allTripsData,
    updateAllTrips,
    selectedTrip,
    setSelectedTrip,
    isTripsContextLoaded,
    isTripsContextValid,
  } = useTrips();
  const [validTripParam, setValidTripParam] = React.useState(false);

  const [starRating, setStarRating] = React.useState(null);
  const [hoverRating, setHoverRating] = React.useState(null);
  const [formSubmitted, setFormSubmitted] = React.useState(false);

  //console.log("selected trip", selectedTrip)
  //console.log("paramReservationNumber",paramReservationNumber)

  // Google is Default Review Redirect if Not Airbnb or VRBO Types
  const GOOGLE_URL = "https://g.page/r/CVJkG59IPaFLEB0/review";
  const YELP_URL =
    "https://www.yelp.com/writeareview/biz/jPgYaQo4entpA3tXIXAFsQ?return_url=%2Fbiz%2FjPgYaQo4entpA3tXIXAFsQ&review_origin=biz-details-war-button";
  const AIRBNB_URL = "https://www.airbnb.com/trips/v1";
  const VRBO_URL = "https://www.vrbo.com/traveler/th/bookings";

  let channelName = "Google";
  let channelURL = YELP_URL; // Switching from Google to Yelp Review for Direct Bookings as of June 2024

  React.useEffect(() => {
    updateAllTrips();
  }, [paramReservationNumber]);

  React.useEffect(() => {
    if (paramReservationNumber && isTripsContextLoaded) {
      //console.log("paramReservationNumber",paramReservationNumber)
      // Check if the current selectedTrip is not the one specified by paramReservationNumber
      if (selectedTrip?.reservationNumber !== paramReservationNumber) {
        // Find the matching trip from allTripsData using the paramReservationNumber
        const matchingTrip = allTripsData.find(
          (trip) => trip.reservationNumber === paramReservationNumber
        );
        // If a matching trip is found, update selectedTrip with it
        if (matchingTrip) {
          setSelectedTrip(matchingTrip);
          setValidTripParam(true);
          setReservationLoaded(true);

          if (matchingTrip["typename"]) {
            const typeName = matchingTrip["typename"].toLowerCase();

            if (typeName === "SC-ABnB" || typeName.includes("airbnb")) {
              channelName = "Airbnb";
              channelURL = AIRBNB_URL;
            } else if (
              typeName === "HAFamOLB" ||
              typeName.includes("vrbo") ||
              typeName.includes("homeaway")
            ) {
              channelName = "VRBO";
              channelURL = VRBO_URL;
            }
          }
        } else {
          // Optional: Handle the case where no matching trip is found (e.g., navigate to a "trip not found" page or show a message)
          //console.log('No matching trip found for reservation number:', paramReservationNumber);
          setValidTripParam(false);
          setReservationLoaded(true);
          // navigate('/some-path'); // Uncomment and replace '/some-path' with your desired route
        }
      } else if (selectedTrip?.reservationNumber === paramReservationNumber) {
        setValidTripParam(true);
        setReservationLoaded(true);
        //console.log("trip already loaded")
      }
    }
  }, [paramReservationNumber, isTripsContextLoaded]);

  // Opens a new window for the Review Redirect URL only if User Leaves 4 or 5 Stars
  React.useEffect(() => {
    let timer = null;

    if (formSubmitted && starRating > 3) {
      timer = setTimeout(() => {
        window.open(channelURL, "_blank");
      }, 5000);
    }

    // cleanup function to clear the timer if the component unmounts before 5 seconds.
    return () => timer && clearTimeout(timer);
  }, [formSubmitted, starRating]);

  // Review Submits Immediately if 4 or 5 Stars, No Option to Leave Comments
  function clicked(e: any) {
    setStarRating(e.target.dataset.value);
    //console.log("Clicked:",e.target.dataset.value)

    if (e.target.dataset.value >= 4) {
      setFormSubmitted(true);

      const fields = {
        starRating: getStarRatingString(e.target.dataset.value),
        reservationNumber: selectedTrip["reservationNumber"],
        reservationName: selectedTrip["reservationNumber"],
        houseNumber: selectedTrip["houseNumber"],
        comments: "",
      };

      //console.log("Fields:",fields)

      handleReview(fields).then((response) => {
        //console.log("Fields:",fields)
        //console.log("Response:",response);
      });
    }
  }
  // Hover Effects for Rating Stars
  function hover(e: any) {
    setHoverRating(e.target.dataset.value);
  }

  function leaveHover(e: any) {
    setHoverRating(starRating);
  }

  // Converts Star Rating Number to String for HubSpot
  function getStarRatingString(rating) {
    if (rating === 1) {
      return "1 Star";
    }
    return rating + " Stars";
  }

  return (
    <>
      <Flex
        textAlign="center"
        fontSize="xl"
        bg={"white"}
        minH={{ base: 0, md: "1100px" }}
        w={"100vw"}
      >
        {paramReservationNumber && selectedTrip ? (
          <Flex
            bgColor="gray.50"
            width={{ base: "100vw" }}
            flexDirection={"column"}
          >
            <Flex
              direction={"column"}
              alignItems={"start"}
              position="relative"
              textAlign={"start"}
              pt={{ base: 4, md: 16 }}
              px={{ base: 8, md: 20, lg: 12, xl: 20 }}
            >
              <Text
                as="span"
                flexShrink={0}
                pt={3}
                pb={1}
                display={{ base: "none", md: "unset" }}
              >
                Currently reviewing:
              </Text>
              <Flex
                direction={"column"}
                position="relative"
                w={"100%"}
                id="level4"
              >
                {selectedTrip && (
                  <Box
                    bg={"white"}
                    mt={3}
                    pr={{ base: 2, sm: 6 }}
                    w={{ base: "100%" }}
                    border={"solid 1px rgba(83, 180, 255, 1)"}
                  >
                    <Flex height={"100%"}>
                      <Image
                        objectFit="cover"
                        src={selectedTrip.house.images[0].link}
                        alt="House"
                        mr={3}
                        height={"100%"}
                        maxW={"100px"}
                      />
                      <Flex
                        direction={"column"}
                        justifyContent="center"
                        fontSize={{ base: 16, sm: 18, md: 20, lg: 16, xl: 18 }}
                        my={3}
                      >
                        <Text>
                          Reservation #{selectedTrip.reservationNumber}
                        </Text>
                        <Text mt={1}>
                          House {selectedTrip.house.houseNumber}{" "}
                          {selectedTrip.house.name}{" "}
                        </Text>
                      </Flex>
                    </Flex>
                  </Box>
                )}
              </Flex>
            </Flex>
            <Flex>
              {!formSubmitted && selectedTrip && !selectedTrip["rating"] ? (
                <Flex align="center" w={"100%"}>
                  <Flex
                    direction="column"
                    align="center"
                    flex="1"
                    py={{ base: 8, md: 16 }}
                  >
                    <Box maxW="90vw" mx="auto">
                      <Box
                        textAlign="center"
                        maxW={{ base: "full", sm: "lg" }}
                        mx="auto"
                        mt={{ base: 0, md: 16 }}
                      >
                        <Heading fontWeight="extrabold">
                          How was your stay?
                        </Heading>
                        <Text
                          fontSize={{ base: "md", md: "lg" }}
                          mt="6"
                          mb={{ base: 0, md: 3 }}
                        >
                          We strive to earn 5-stars, but if your stay was
                          anything less, we want to know how to improve, let us
                          know below!
                        </Text>
                        <Flex
                          w={"100%"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          flexDirection={"column"}
                          pt={{ base: 0, md: 4 }}
                        >
                          <Formik
                            initialValues={{
                              comments: "",
                            }}
                            onSubmit={async (values) => {
                              setFormSubmitted(true);

                              const fields = {
                                starRating: getStarRatingString(starRating),
                                reservationNumber:
                                  selectedTrip["reservationNumber"],
                                reservationName:
                                  selectedTrip["reservationNumber"],
                                houseNumber: selectedTrip["houseNumber"],
                                comments: values.comments,
                              };

                              //console.log("fields",fields);

                              handleReview(fields).then((response) => {
                                //console.log("fields",fields);
                                //console.log("Response:",response);
                              });
                            }}
                          >
                            {({
                              values,
                              handleChange,
                              handleBlur,
                              handleSubmit,
                              isSubmitting,
                              /* and other goodies */
                            }) => (
                              <form
                                onSubmit={handleSubmit}
                                className="guest-form"
                              >
                                <Flex
                                  flexDirection={"column"}
                                  justifyContent={"space-between"}
                                >
                                  <div
                                    className="star-container"
                                    onMouseLeave={leaveHover}
                                  >
                                    {starRating < 1 && hoverRating < 1 ? (
                                      <span
                                        className="star-rating"
                                        data-value="1"
                                        onMouseEnter={hover}
                                        onClick={clicked}
                                      >
                                        &#9734;
                                      </span>
                                    ) : (
                                      <span
                                        className="star-rating"
                                        data-value="1"
                                        onMouseEnter={hover}
                                        onClick={clicked}
                                      >
                                        &#9733;
                                      </span>
                                    )}
                                    {starRating < 2 && hoverRating < 2 ? (
                                      <span
                                        className="star-rating"
                                        data-value="2"
                                        onMouseEnter={hover}
                                        onClick={clicked}
                                      >
                                        &#9734;
                                      </span>
                                    ) : (
                                      <span
                                        className="star-rating"
                                        data-value="2"
                                        onMouseEnter={hover}
                                        onClick={clicked}
                                      >
                                        &#9733;
                                      </span>
                                    )}
                                    {starRating < 3 && hoverRating < 3 ? (
                                      <span
                                        className="star-rating"
                                        data-value="3"
                                        onMouseEnter={hover}
                                        onClick={clicked}
                                      >
                                        &#9734;
                                      </span>
                                    ) : (
                                      <span
                                        className="star-rating"
                                        data-value="3"
                                        onMouseEnter={hover}
                                        onClick={clicked}
                                      >
                                        &#9733;
                                      </span>
                                    )}
                                    {starRating < 4 && hoverRating < 4 ? (
                                      <span
                                        className="star-rating"
                                        data-value="4"
                                        onMouseEnter={hover}
                                        onClick={clicked}
                                      >
                                        &#9734;
                                      </span>
                                    ) : (
                                      <span
                                        className="star-rating"
                                        data-value="4"
                                        onMouseEnter={hover}
                                        onClick={clicked}
                                      >
                                        &#9733;
                                      </span>
                                    )}
                                    {starRating < 5 && hoverRating < 5 ? (
                                      <span
                                        className="star-rating"
                                        data-value="5"
                                        onMouseEnter={hover}
                                        onClick={clicked}
                                      >
                                        &#9734;
                                      </span>
                                    ) : (
                                      <span
                                        className="star-rating"
                                        data-value="5"
                                        onMouseEnter={hover}
                                        onClick={clicked}
                                      >
                                        &#9733;
                                      </span>
                                    )}
                                  </div>
                                  {starRating < 4 && starRating != null && (
                                    <>
                                      <Textarea
                                        id="comments"
                                        name="comments"
                                        variant="outline"
                                        bg={"whiteAlpha.700"}
                                        size="lg"
                                        height={{ base: "50px", md: "150px" }}
                                        maxLength={300}
                                        placeholder={
                                          "Please let us know how we could have improved your stay."
                                        }
                                        onChange={handleChange}
                                        value={values.comments}
                                        mt={{ base: 4, md: 8 }}
                                        mb={4}
                                        className={
                                          starRating < 4 && starRating != null
                                            ? ""
                                            : "container-hidden"
                                        }
                                      />
                                      <Flex
                                        justifyContent={"end"}
                                        pt={{ base: 2, md: 8 }}
                                        pb={8}
                                        w={"100%"}
                                        className={
                                          starRating < 4 && starRating != null
                                            ? ""
                                            : "container-hidden"
                                        }
                                      >
                                        <Button
                                          type="submit"
                                          width="90px"
                                          className="dm-button-orange-solid"
                                          color={"white"}
                                        >
                                          Send
                                        </Button>
                                      </Flex>
                                    </>
                                  )}
                                </Flex>
                              </form>
                            )}
                          </Formik>
                        </Flex>
                      </Box>
                    </Box>
                  </Flex>
                </Flex>
              ) : (
                <Flex align="center">
                  <Flex
                    direction="column"
                    align="center"
                    flex="1"
                    py={{ base: 8, md: 16 }}
                  >
                    {starRating > 3 ? (
                      <Box w={"100vw"} mx="auto">
                        <Box
                          textAlign="center"
                          maxW={{ base: "90vw" }}
                          mx="auto"
                          mt={{ base: 0, md: 16 }}
                        >
                          <Heading fontWeight="extrabold">
                            Sending you to review Online at {channelName}!
                          </Heading>

                          <Flex
                            pt="10"
                            width={"100%"}
                            textAlign={"center"}
                            justifyContent={"center"}
                          >
                            <Text fontSize={{ base: "md", md: "lg" }}>
                              We really appreciate you taking the time to share
                              your rating with us. Could we ask you to take a
                              few more minutes and share your experience on{" "}
                              {channelName}?
                              <br />
                              <br />
                              We'll open a new tab in a few seconds. Don't see
                              it?{" "}
                              <Link
                                href={channelURL}
                                target={"_blank"}
                                textDecoration={"underline"}
                              >
                                Click here
                              </Link>
                              .
                            </Text>
                          </Flex>
                          <Flex
                            pt="10"
                            width={"100%"}
                            textAlign={"center"}
                            justifyContent={"center"}
                          >
                            Ready to book your next adventure?
                          </Flex>
                          <Flex
                            pt="10"
                            width={"100%"}
                            textAlign={"center"}
                            justifyContent={"center"}
                          >
                            <Link
                              href="https://book.thisisdelmar.com/"
                              w={{ base: "100%", lg: "auto" }}
                              className="no-underline"
                              target={"_blank"}
                            >
                              <Button
                                className="dm-button-orange-pill"
                                w={{ base: "100%", lg: "300px" }}
                                boxShadow="base"
                              >
                                Book another home
                              </Button>
                            </Link>
                          </Flex>
                          <Flex
                            w={"100%"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            flexDirection={"column"}
                            pt={4}
                          ></Flex>
                        </Box>
                      </Box>
                    ) : (
                      <Box w={"100vw"}>
                        <Box
                          textAlign="center"
                          maxW={{ base: "90vw" }}
                          mx="auto"
                          py={{ base: 8, md: 16 }}
                        >
                          <Heading fontWeight="extrabold">Thank you.</Heading>

                          <Flex
                            pt="10"
                            width={"100%"}
                            textAlign={"center"}
                            justifyContent={"center"}
                          >
                            <Text>
                              We really appreciate you taking the time to share
                              your feedback.
                              <br />
                              <br />
                            </Text>
                          </Flex>

                          <Link
                            href="/trips"
                            w={{ base: "100%", lg: "auto" }}
                            className="no-underline"
                          >
                            <Button
                              className="dm-button-orange-pill"
                              w={{ base: "100%", lg: "300px" }}
                              maxW={"90vw"}
                              boxShadow="base"
                              my={12}
                            >
                              Back to My Trips
                            </Button>
                          </Link>

                          <Flex
                            w={"100%"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            flexDirection={"column"}
                            pt={4}
                          ></Flex>
                        </Box>
                      </Box>
                    )}
                  </Flex>
                </Flex>
              )}
            </Flex>
          </Flex>
        ) : reservationLoaded ? (
          <Flex
            w={"100%"}
            h={"80vh"}
            justifyContent={"center"}
            alignItems={"center"}
            flexDirection={"column"}
          >
            <Text mb={6}>No Matching Reservation Found</Text>
            <Flex
              gap={4}
              flexDirection={{ base: "column", md: "row" }}
              w={{ base: "100%", lg: "auto" }}
            >
              <Link
                href="/trips"
                w={{ base: "100%", lg: "auto" }}
                className="no-underline"
              >
                <Button
                  className="dm-button-orange-pill"
                  w={{ base: "100%", lg: "300px" }}
                  maxW={"90vw"}
                  boxShadow="base"
                >
                  Back to My Trips
                </Button>
              </Link>
              <Link
                href="/"
                target="_self"
                w={{ base: "100%", lg: "auto" }}
                className="no-underline"
              >
                <Button
                  className="dm-button-navy-outline"
                  w={{ base: "100%", lg: "300px" }}
                  maxW={"90vw"}
                  boxShadow="base"
                  onClick={() => window.open("/", "_self")}
                >
                  Start a New Search
                </Button>
              </Link>
            </Flex>
          </Flex>
        ) : (
          <Flex
            w={"100%"}
            h={"90vh"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Spinner />
          </Flex>
        )}
      </Flex>

      {isTripsContextLoaded && allTripsData.length > 0 && (
        <MobileMenu
          activeReservationData={allTripsData[allTripsData.length - 1]}
          contactOwner={{
            name: "Amanda Perry",
            title: "Guest Services Associate",
            avatar: "./media/Amanda.png",
          }}
        />
      )}
    </>
  );
};

export { Review };
