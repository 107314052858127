import {
  Box, Button, Flex, Grid, Image,
  ListItem,
  Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay,
  Spinner, Text,
  UnorderedList,
  useDisclosure
} from "@chakra-ui/react";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import * as React from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Beach } from "../components/guestportal/Beach";
import { CheckInInfo } from "../components/guestportal/CheckInInfo";
import { CheckOutInfo } from "../components/guestportal/CheckOutInfo";
import { FAQ } from "../components/guestportal/FAQ";
import { HowThisHomeWorks } from "../components/guestportal/HowThisHomeWorks";
import { PlacesModal } from "../components/guestportal/PlacesModal";
import { RebookCalendar } from "../components/guestportal/RebookCalendar";
import { ReviewModal } from "../components/guestportal/ReviewModal";
import { TipMyCleaner } from "../components/guestportal/TipMyCleaner";
import { CustomGridItem } from "../components/guestportal/TripDetailsGridIcon";
import { WelcomeDetails } from "../components/guestportal/WelcomeDetails";
import config from "../config";
import { fetchReservationDataByHouse } from "../helpers/fetch";


const stripePromise = loadStripe(config.stripeKey)

const QRCodes = () => {

  const navigate = useNavigate();
  let { encoding } = useParams();
  const qrHash = config.qrHash;
  
  const [activeReservation, setActiveReservation] = React.useState(null);
  const [searchParams] = useSearchParams();

  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState(null);
  
  const { isOpen, onOpen, onClose: mainModalOnClose  } = useDisclosure(); // Main Modal

  const [extrasClickedOnce, setExtrasClickedOnce] = React.useState(false);
  const [rebookClickedOnce, setRebookClickedOnce] = React.useState(false);

  React.useEffect(() => {
    const fetchReservationData = async () => {
      if (encoding) {
        // Extract houseNumber and hash from encoding
        const base64HouseNumber = encoding.slice(0, 8);
        const hash = encoding.slice(8);
        const houseNumber = atob(base64HouseNumber);

        try {
          // Fetch reservation data by houseNumber and hash
          const fetchedReservationData = await fetchReservationDataByHouse(houseNumber, qrHash);
          console.log('fetchedReservationData', fetchedReservationData.reservation);
          setActiveReservation(fetchedReservationData.reservation);
        } catch (error) {
          console.error('Error fetching reservation data:', error);
        }
      }
    };

    fetchReservationData();
  }, [encoding]);
  

  React.useEffect(() => {
    // Normalize function to remove spaces and hyphens and convert to lower case
    const normalize = (str) => str.replace(/[\s-]/g, '').toLowerCase();
  
    // Check URL params to open a modal by default based on the open parameter
    const openParam = searchParams.get('open');
    const validItems = [
      'Rebook', 'Tip My Cleaner', 'Shop', 'Merch', 'Review',
      'FAQs', 'Check-Out Instructions', 'Access Information', 'Report Damage',
      'Beach Stickers', 'Home Guide', 'Pre-Arrival Guide', 'House Rules', 'Help',
      'Things To Do', 'Local Discounts'
    ];
  
    if (openParam && activeReservation) {
      const normalizedOpenParam = normalize(openParam);
      const matchedItem = validItems.find(item => normalize(item) === normalizedOpenParam);
  
      if (matchedItem) {
        setSelectedItem(matchedItem);
        if (matchedItem === 'Shop') {
          window.open(
            `/store/${activeReservation.reservationNumber}`,
            "_blank"
          )
        } else if (matchedItem === 'Merch') {
          window.open(
            "https://delmarvacations.myshopify.com/collections/products",
            "_blank"
          )
        } else {
          onOpen();
        }
      }
    }
  }, [searchParams, onOpen, activeReservation]);

    // Custom onClose for the "Tip My Cleaner" modal
    const onCloseModalRefresh = () => {
      mainModalOnClose();
      //console.log('selectedItem',selectedItem)
      if (selectedItem === 'Tip My Cleaner') {
        // Put in a refresh of the reservation data (same as initial fetch)
      }
    };
  

  React.useEffect(() => {
    // Check local storage to see if the item has been clicked before
    const hasExtrasBeenClicked = localStorage.getItem('extrasClicked');

    if (hasExtrasBeenClicked) {
      setExtrasClickedOnce(true);
    }
  }, []);

  const handleExtrasItemClick = () => {
    if (!rebookClickedOnce) {
      // Update local storage to indicate the item has been clicked
      localStorage.setItem('extrasClicked', 'true');
      setExtrasClickedOnce(true);
    }
  };

  React.useEffect(() => {
    // Check local storage to see if the item has been clicked before
    const hasRebookBeenClicked = localStorage.getItem('rebookClicked');

    if (hasRebookBeenClicked) {
      setRebookClickedOnce(true);
    }
  }, []);

  const handleRebookItemClick = () => {
    if (!rebookClickedOnce) {
      // Update local storage to indicate the item has been clicked
      localStorage.setItem('rebookClicked', 'true');
      setRebookClickedOnce(true);
    }
  };
    
  return (
    <Flex textAlign="center" fontSize="xl" bg={"white"} 
      minH={activeReservation ? '1100px' : '70vh'}
      w={'100%'}
    >
      {(!activeReservation) ? (
        <Flex width="100%" justifyContent={'center'} alignItems={'center'}>
          <Spinner size='xl' />
        </Flex>
      ) : (
        <>
          <Flex
            width={'100%'}
            flexDirection={'column'}
            alignItems={'center'}
            px={3} 
          >
            <Flex direction={"column"} alignItems={"start"} position="relative" textAlign={'start'} w={'100%'} maxW={'500px'}>
              <Flex direction={"column"} position="relative" w={'100%'}>
              {activeReservation && (
                <Box
                  cursor="pointer"
                  _hover={{ bg: "gray.100" }}
                  bg={"white"}
                  pr={{base:2, sm:6}} mt={{base:4,md:6}}
                  w={{base:'100%'}}
                  onClick={() => setDropdownOpen(!dropdownOpen)}
                  border={'solid 1px rgba(83, 180, 255, 1)'} 
                  borderRadius="16px"
                  boxShadow={'dmShadow'}
                  overflow='hidden'
                >
                  <Flex height={'100%'} borderRadius="16px" overflow='hidden'>
                    <Image
                      objectFit="cover"
                      src={activeReservation.house.images[0].link}
                      alt="House"
                      mr={3}
                      height={'100%'}
                      maxW={'80px'}
                    />
                    <Flex direction={'column'} justifyContent="center" fontSize={{base:14, sm:14, md:16, lg:16, xl:16}} mt={3} mb={2} gap={1}>
                      <Text><b>Res #:</b> {activeReservation.reservationNumber}</Text>
                      <Text><b>House #:</b> {activeReservation.houseNumber}</Text>
                      <Text><b>Address:</b> {activeReservation.house.address.split(', United States')[0]} </Text>
                    </Flex>
                  </Flex>
                </Box>
              )}
              
              </Flex>
            </Flex>

            <Grid
              h="auto" w="100%" maxW={'500px'}
              pt={6} pb={4} margin="0 auto"
              templateRows="repeat(2, 1fr)"
              gap={{ base: 4, sm: 8 }} // Unified gap property for simplicity
              // Use a responsive setup for columns to allow wrapping
              templateColumns={{
                base: "repeat(auto-fill, minmax(70px, 1fr))", // Adjust minmax() as needed for your content
                sm: "repeat(auto-fill, minmax(80px, 1fr))", // Example breakpoint adjustment
              }}
            >
              <Box onClick={() => {handleExtrasItemClick(); window.open(`/store/${activeReservation.reservationNumber}`, "_blank")}}>
                <CustomGridItem setSelectedItem={setSelectedItem} onOpen={onOpen} bgImage='/media/whiteicons/shop.png' title='Shop' status={extrasClickedOnce ? 'complete' : 'alert'} reservationNumber={activeReservation.reservationNumber} redirectAllowed={false} />
              </Box>
              <Box onClick={() => window.open(
                          "https://delmarvacations.myshopify.com/collections/products",
                          "_blank"
                        )}>
                <CustomGridItem setSelectedItem={setSelectedItem} onOpen={onOpen} bgImage='/media/whiteicons/merch.png' title='Merch' />
              </Box>
              <CustomGridItem setSelectedItem={setSelectedItem} onOpen={onOpen} bgImage='/media/whiteicons/tipmycleaner.png' title='Tip My Cleaner' />
              <CustomGridItem setSelectedItem={setSelectedItem} onOpen={onOpen} bgImage='/media/whiteicons/review.png' title='Review' disable={activeReservation ? activeReservation['disableReview'] : true} />

              {/* Allow Rebook if House Still Active */}
              {activeReservation.house.activeListing &&
                <Box onClick={handleRebookItemClick}>
                  <CustomGridItem setSelectedItem={setSelectedItem} onOpen={onOpen} bgImage='/media/whiteicons/rebook.png' title='Rebook' status={rebookClickedOnce ? 'complete' : 'alert'} />
                </Box>
              }

              <CustomGridItem setSelectedItem={setSelectedItem} onOpen={onOpen} bgImage='/media/whiteicons/prearrivalguide.png' title='Pre-Arrival Guide' />
              <CustomGridItem setSelectedItem={setSelectedItem} onOpen={onOpen} bgImage='/media/whiteicons/checkininfo.png' title='Access Information' />
              <CustomGridItem setSelectedItem={setSelectedItem} onOpen={onOpen} bgImage='/media/whiteicons/beachstickers.png' title='Beach Stickers' />
              <CustomGridItem setSelectedItem={setSelectedItem} onOpen={onOpen} bgImage='/media/whiteicons/homeguide.png' title='Home Guide' />              
              <CustomGridItem setSelectedItem={setSelectedItem} onOpen={onOpen} bgImage='/media/whiteicons/houserules.png' title='House Rules' />
              <CustomGridItem setSelectedItem={setSelectedItem} onOpen={onOpen} bgImage='/media/whiteicons/checkoutinstructions.png' title='Check-Out Instructions' />
              <CustomGridItem setSelectedItem={setSelectedItem} onOpen={onOpen} bgImage='/media/whiteicons/faqs.png' title='FAQs' />
              <CustomGridItem setSelectedItem={setSelectedItem} onOpen={onOpen} bgImage='/media/whiteicons/thingstodo.png' title='Things To Do' redirectAllowed={false} />              
              <CustomGridItem setSelectedItem={setSelectedItem} onOpen={onOpen} bgImage='/media/whiteicons/localdiscounts.png' title='Local Discounts' redirectAllowed={false} />              
            </Grid>

            <Button colorScheme={'dmOrange'} mt={2} mb={{base:4, md:16}} onClick={() => navigate('../trips')}>Sign in to view My Trips for Full Access</Button>
          </Flex>
        </>
      )}
      
      {selectedItem === 'Review' &&
        <ReviewModal
          onReviewClose={mainModalOnClose}
          reservationData={activeReservation}
          modalInitiateOpen={true}
        />
      }


      {selectedItem === 'Rebook' &&
        <RebookCalendar
          modalInitiateOpen={isOpen}
          reservationData={activeReservation}
          onModalClose={mainModalOnClose}
        />
      }

      {selectedItem === 'Tip My Cleaner' &&
        <Modal isOpen={isOpen} onClose={onCloseModalRefresh} size={{base:'full',md:'2xl'}}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Tip My Cleaner</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Elements stripe={stripePromise}>
                <TipMyCleaner reservationData={activeReservation} onSuccessfulPayment={onCloseModalRefresh} />
              </Elements>
            </ModalBody>
          </ModalContent>
        </Modal>
      }

      {selectedItem === 'FAQs' &&
        <Modal isOpen={isOpen} onClose={mainModalOnClose} size={{base:'full',md:'6xl'}}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Frequently Asked Questions</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FAQ reservationNumber={activeReservation.reservationNumber} />
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="dmNavy" onClick={mainModalOnClose}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      }

      {selectedItem === 'Check-Out Instructions' &&
        <Modal isOpen={isOpen} onClose={mainModalOnClose} size={{base:'full',md:'6xl'}}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Check-Out Instructions</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <CheckOutInfo
                reservationData={activeReservation}
              />
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="dmNavy" onClick={mainModalOnClose}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      }

      {selectedItem === 'Access Information' &&
        <Modal isOpen={isOpen} onClose={mainModalOnClose} size={{base:'full',md:'6xl'}}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Home Access Information</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <CheckInInfo
                reservationData={activeReservation}
              />
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="dmNavy" onClick={mainModalOnClose}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      }

      {selectedItem === "Beach Stickers" &&
        <Modal isOpen={isOpen} onClose={mainModalOnClose} size={{base:'full',md:'6xl'}}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Beach Stickers</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Beach 
                openItems={[0,1]}
                town={activeReservation.house.town}
              />
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="dmNavy" onClick={mainModalOnClose}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      }

      {selectedItem === "Home Guide" &&
        <Modal isOpen={isOpen} onClose={mainModalOnClose} size={{base:'full',md:'6xl'}}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Home Guide</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
            <HowThisHomeWorks 
              reservationData={activeReservation}
            />
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="dmNavy" onClick={mainModalOnClose}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>        
      }

      {selectedItem === "Pre-Arrival Guide" &&
        <Modal isOpen={isOpen} onClose={mainModalOnClose} size={{base:'full',md:'6xl'}}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Pre-Arrival Guide</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
            <WelcomeDetails 
              town={activeReservation.house.town}
              channel={activeReservation.channel}
              standalone={false}
              laundry={activeReservation.house.laundry}
            />
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="dmNavy" onClick={mainModalOnClose}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>        
      }

      {selectedItem === "House Rules" &&
        <Modal isOpen={isOpen} onClose={mainModalOnClose} size={{base:'full',md:'6xl'}}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>House Rules</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Flex direction='column'>
                <Box>We're so excited to host you at our home. To ensure you have the best vacation possible, and our home is well taken care of, please abide by the following house rules:
                  <UnorderedList ml={6}>
                    <ListItem>No smoking in the home or on the property</ListItem>
                    <ListItem>Leave sandy/dirty items outside (beach towels, sandals, shoes)</ListItem>
                    <ListItem>Only flush toilet paper–all other items should be disposed of in the trash</ListItem>
                    <ListItem>If the AC is on, ensure all windows and doors are closed</ListItem>
                    <ListItem>IF the home has central A/C please do not put the temperature below 69° - keeping the temperature any lower runs the risk of freezing the system. If the system freezes, you will likely be without AC until it defrosts and a vendor can repair the system.</ListItem>
                    <ListItem>The only guests allowed at the home overnight are those accounted for on your guest agreement. Do not exceed the maximum number of guests allowed at the home.</ListItem>
                    <ListItem>IF home is dog friendly, please do not allow them on furniture. Dogs are not to be left unattended and uncrated in the home.</ListItem>
                    <ListItem>IF there is any accidental damage to the home, please report it. You are covered for up to $500 of accidental damage.</ListItem>
                  </UnorderedList>
                </Box>
              </Flex>
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="dmNavy" onClick={mainModalOnClose}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>        
      }

      {selectedItem === "Things To Do" &&
        <Modal isOpen={isOpen} onClose={mainModalOnClose} size={'full'}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Things To Do</ModalHeader>
            <ModalCloseButton />
            <ModalBody p={0}>
            <PlacesModal 
              houseData={activeReservation.house}
              initialType={'Beaches/Ponds'}
            />
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="dmNavy" onClick={mainModalOnClose}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>        
      }

      {selectedItem === "Local Discounts" &&
        <Modal isOpen={isOpen} onClose={mainModalOnClose} size={'full'}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Local Discounts</ModalHeader>
            <ModalCloseButton />
            <ModalBody p={0}>
            <PlacesModal 
              houseData={activeReservation.house}
              initialType={'Discounts/Partners'}
            />
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="dmNavy" onClick={mainModalOnClose}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>        
      }
    
    </Flex>
  );
}

export { QRCodes };

