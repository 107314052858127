import { CheckCircleIcon } from "@chakra-ui/icons";
import {
  AspectRatio,
  Button,
  Flex,
  Image,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Skeleton,
  SkeletonText,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { differenceInMonths } from "date-fns";
import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchHouseDetails, getReservationQuote } from "../helpers/fetch";
import { reformatDateString } from "../helpers/helpers";
import { deleteQuoteItem, updateQuoteItem } from "../helpers/quoteService";

type Props = {
  houseNumber: String;
  checkinDate?: string;
  checkoutDate?: string;
  guests: number;
  price?: number;
  adminMode: boolean;
  rebookMode?: boolean;
  quoteId: string;
  quoteItemId: string;
  note?: string;
  coupon?: string;
  onRemove: (quoteItemId: string) => void; // Callback to remove the component
};

const QuoteCard: React.FC<Props> = ({
  houseNumber,
  checkinDate,
  checkoutDate,
  guests,
  price,
  adminMode,
  rebookMode,
  quoteId,
  quoteItemId,
  note,
  coupon,
  onRemove,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  /// Load House Data if Not in Context Already
  const [houseLoading, setHouseLoading] = React.useState(true);
  const [houseData, setHouseData] = React.useState(null);
  const [reservationQuote, setReservationQuote] = React.useState(null);
  const [quoteLoading, setQuoteLoading] = React.useState(true);
  const [quoteValid, setQuoteValid] = React.useState(false);
  const [isEditing, setIsEditing] = React.useState(false);
  const [editedNote, setEditedNote] = React.useState(note);
  const [editedCoupon, setEditedCoupon] = React.useState(coupon);
  const [editedGuests, setEditedGuests] = React.useState(guests);
  const [totalPrice, setTotalPrice] = React.useState("");
  const [couponValid, setCouponValid] = React.useState(null);
  const [errorType, setErrorType] = React.useState("");

  const fetchHouseAndQuoteData = async () => {
    setHouseLoading(true);
    try {
      //console.log("Fetching data for", houseNumber);
      const params = { houseNumber: houseNumber };

      const response = await fetchHouseDetails(params);

      if (!response.ok) {
        throw new Error(`fetchHouseDetails Error! Status: ${response.status}`);
      }

      const returnedHouseData = await response.json();
      setHouseData(returnedHouseData.results);
      //console.log('House Data:', returnedHouseData.results);

      if (
        new Date(checkinDate) < new Date() ||
        returnedHouseData.results.activeListing === false
      ) {
        setQuoteValid(false);
        setHouseLoading(false);
        setQuoteLoading(false);
        return;
      }

      const listingId = returnedHouseData.results.guestyHouseId;

      const queryParams = {
        checkInDateLocalized: checkinDate,
        checkOutDateLocalized: checkoutDate,
        listingId,
        guestsCount: guests,
        ...(editedCoupon ? { coupons: editedCoupon } : {}),
      };

      const quoteResponse = await getReservationQuote(queryParams);
      //console.log('Quote Response:', quoteResponse)
      if (!quoteResponse.ok) {
        const errorResponse = await quoteResponse.json();
        console.error("Error fetching house or quote data", errorResponse);
        throw new Error(
          errorResponse.error.message ||
            `HTTP error! Status: ${quoteResponse.status}`
        );
      }

      const reservationQuote = await quoteResponse.json();
      if (reservationQuote.coupons && reservationQuote.coupons.length > 0) {
        setCouponValid(true);
      }
      //console.log('Reservation Quote:', reservationQuote);
      setTotalPrice(
        reservationQuote.rates.ratePlans[0].ratePlan.money.hostPayout.toFixed(2)
      );
      setReservationQuote(reservationQuote);
      setQuoteValid(true);
    } catch (error) {
      setQuoteValid(false);
      console.error("Error fetching house or quote data", error);
      if (error.message.includes("coupons")) {
        setErrorType("coupon");
        setIsEditing(true);
      } else if (error.message.includes("restriction")) {
        setErrorType("dates");
      } else {
        setErrorType("unknown");
      }
    } finally {
      setHouseLoading(false);
      setQuoteLoading(false);
    }
  };

  const handleCheckout = () => {
    if (
      reservationQuote.rates.ratePlans[0].ratePlan.money.hostPayout > 300 &&
      rebookMode &&
      differenceInMonths(new Date(checkinDate), new Date()) > 6
    ) {
      navigate(`/${houseNumber}/payment?source=quotingtool-${quoteId}`, {
        state: {
          reservationQuote: reservationQuote,
          houseData: houseData,
          guests: guests,
          fromLocation: location.pathname,
        },
      });
    } else {
      navigate(`/${houseNumber}/checkout?source=quotingtool-${quoteId}`, {
        state: {
          reservationQuote: reservationQuote,
          houseData: houseData,
          guests: guests,
          fromLocation: location.pathname,
        },
      });
    }
  };

  const handleSave = async () => {
    try {
      await updateQuoteItem(quoteId, quoteItemId, {
        coupon: editedCoupon,
        note: editedNote,
        guests: editedGuests,
      });
      setIsEditing(false);
      if (couponChanged) {
        setCouponValid(null);
        fetchHouseAndQuoteData();
      }
      //console.log('editedCoupon:', editedCoupon, 'editedNote:', editedNote, 'editedGuests:', editedGuests)
    } catch (error) {
      console.error("Error updating quote item", error);
    }
  };

  const handleDelete = async () => {
    try {
      await deleteQuoteItem(quoteId, quoteItemId);
      onRemove(quoteItemId); // Notify parent to remove this component
    } catch (error) {
      console.error("Error deleting quote item", error);
    }
  };

  const couponChanged = editedCoupon !== coupon;

  React.useEffect(() => {
    fetchHouseAndQuoteData();
  }, [houseNumber, quoteItemId]);

  return !quoteLoading && !houseLoading && houseData ? (
    <Flex
      className="listing-card"
      flexDirection={"column"}
      gap={2}
      position="relative"
      textAlign={"left"}
    >
      <Flex flexDirection={{ base: "column", lg: "row" }} gap={2}>
        <Flex gap={2} w={"100%"}>
          <AspectRatio
            className="listing-photo"
            width="100%"
            maxW={"15vw"}
            ratio={1.2}
          >
            <Image
              src={houseData.thumbnail || houseData.pictures[0]}
              alt={"Photo of Del Mar #" + houseNumber}
              objectFit="cover"
              width="100%"
              height="100%"
              className="listing-rating-star"
            />
          </AspectRatio>

          <Flex
            className="listing-details"
            w={"100%"}
            flexDirection={"column"}
            gap={1}
            minW={"200px"}
          >
            <Flex
              className="listing-number"
              px={1}
              fontWeight={500}
              fontSize={"16px"}
            >
              #{houseNumber} - {houseData.name}
            </Flex>

            <Flex
              className="listing-description"
              fontSize={"15px"}
              textAlign={"left"}
              color={"dmNavy.500"}
              px={1}
            >
              {houseData.title}
            </Flex>

            <Flex
              className="listing-reviews-parent"
              alignItems={"center"}
              px={1}
            >
              {houseData.reviews !== null &&
                !isNaN(houseData.reviews) &&
                houseData.reviews > 0 && (
                  <>
                    <Flex className="listing-rating" fontSize={"15px"}>
                      {houseData.rating !== null && !isNaN(houseData.rating)
                        ? Number(houseData.rating.toFixed(1))
                        : 0}
                    </Flex>
                    <Image
                      src={"../media/ratingstar.png"}
                      alt="Home Rating"
                      objectFit={"cover"}
                      align={"left"}
                      width={"15px"}
                      height={"15px"}
                      className="listing-rating-star"
                      mx={1.5}
                    />
                  </>
                )}
              <Flex className="listing-reviews" fontSize={"15px"}>
                {houseData.reviews !== null &&
                  !isNaN(houseData.reviews) &&
                  (houseData.reviews === 0
                    ? "New Listing"
                    : `${houseData.reviews as number} Reviews`)}
              </Flex>
            </Flex>

            <Flex
              className="listing-details"
              fontSize={"14px"}
              gap={2}
              alignItems={"flex-start"}
              color={"dmNavy.400"}
              px={1}
            >
              <Text className="listing-occupancy-text">
                {!isNaN(houseData.occupancy)
                  ? (houseData.occupancy as number)
                  : 0}{" "}
                Guests
              </Text>
              {houseData.bedrooms && (
                <>
                  <Text color={"dmOrange.500"} fontWeight={400}>
                    \
                  </Text>
                  <Text className="listing-bedrooms-text">
                    {!isNaN(houseData.bedrooms)
                      ? (houseData.bedrooms as number)
                      : 0}{" "}
                    Bd
                  </Text>
                </>
              )}
              {houseData.bathrooms && (
                <>
                  <Text color={"dmOrange.500"} fontWeight={400}>
                    \
                  </Text>
                  <Text className="listing-bathrooms-text">
                    {!isNaN(houseData.bathrooms)
                      ? (houseData.bathrooms as number)
                      : 0}{" "}
                    Ba
                  </Text>
                </>
              )}
            </Flex>

            <Flex
              className="listing-price"
              fontSize={"15px"}
              px={1}
              flexDirection="column"
              textAlign={"left"}
            >
              <Text fontWeight={400}>
                {houseData.town ? houseData.town + ", MA" : ""}
              </Text>
              <Text fontWeight={400} color={"gray"}>
                {!isNaN(price) && price !== null
                  ? `$${houseData.price.toFixed(0)} avg per night`
                  : ""}
              </Text>
            </Flex>
          </Flex>
        </Flex>

        <Flex
          className={"listing-pricing"}
          w={"100%"}
          flexDirection={"column"}
          fontSize={"15px"}
          gap={1}
          borderLeft={{ base: "none", lg: "solid 1px #DEDEDE" }}
          ml={{ base: 0, lg: 3 }}
          pl={{ base: 0, lg: 4 }}
          textAlign={"start"}
          maxW={"300px"}
        >
          <Text>
            <b>Check-In:</b> {reformatDateString(checkinDate)}
          </Text>
          <Text>
            <b>Check-Out:</b> {reformatDateString(checkoutDate)}
          </Text>

          {isEditing ? (
            <Flex className="quote-guests" flexDirection={"column"}>
              <b>Guests:</b>
              <NumberInput
                value={editedGuests}
                min={1}
                max={houseData.occupancy}
                onChange={(valueString) =>
                  setEditedGuests(parseInt(valueString))
                }
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </Flex>
          ) : (
            <Text>
              <b>Guests:</b> {guests}
            </Text>
          )}

          {isEditing ? (
            <Flex className="quote-coupon" flexDirection={"column"}>
              <b>Coupon:</b>
              <Flex gap={2} width={"100%"}>
                <Input
                  value={editedCoupon}
                  onChange={(e) => setEditedCoupon(e.target.value)}
                />
              </Flex>
            </Flex>
          ) : (
            couponValid &&
            editedCoupon && (
              <Flex
                className="quote-coupon"
                alignItems={"center"}
                gap={1}
                w={"100%"}
              >
                {(adminMode || (editedCoupon && editedCoupon.length > 0)) && (
                  <>
                    <Text>
                      <b>Coupon: </b>
                    </Text>
                    <Text mr={2}>{editedCoupon}</Text>
                  </>
                )}
                {couponValid && (
                  <Flex gap={1} width={"100%"}>
                    <CheckCircleIcon color="green" />
                    <Text color="green" fontSize={"small"}>
                      Coupon Applied!
                    </Text>
                  </Flex>
                )}
              </Flex>
            )
          )}

          {!quoteLoading && quoteValid && !houseLoading ? (
            <>
              <Text>
                <b>Total Price:</b> ${totalPrice}
              </Text>
              <Flex
                mt={1}
                gap={2}
                w={"100%"}
                justifyContent={"start"}
                alignItems={"start"}
              >
                {adminMode ? (
                  <Flex gap={2} flexWrap={"wrap"}>
                    <Button
                      size={"sm"}
                      colorScheme={"dmOrange"}
                      variant={"solid"}
                      onClick={handleCheckout}
                    >
                      Preview
                    </Button>
                    <Button
                      size={"sm"}
                      colorScheme={"red"}
                      variant={"solid"}
                      onClick={handleDelete}
                    >
                      Remove
                    </Button>
                    <Button
                      size={"sm"}
                      colorScheme={"dmOrange"}
                      variant={isEditing ? "solid" : "outline"}
                      onClick={() => {
                        if (isEditing) {
                          handleSave();
                        }
                        setIsEditing(!isEditing);
                      }}
                    >
                      {isEditing ? "Save" : "Edit"}
                    </Button>
                    <Button
                      size={"sm"}
                      colorScheme={"pink"}
                      variant={"solid"}
                      onClick={() =>
                        navigator.clipboard.writeText(
                          `${houseData.airbnbLink}/?check_in=${checkinDate}&check_out=${checkoutDate}&guests=${guests}`
                        )
                      }
                      isDisabled={!houseData.airbnbLink}
                    >
                      Copy Airbnb Link
                    </Button>
                    <Button
                      size={"sm"}
                      colorScheme={"blue"}
                      variant={"solid"}
                      onClick={() =>
                        navigator.clipboard.writeText(
                          `${houseData.vrboLink}/?startDate=${checkinDate}&endDate=${checkoutDate}&adults=${guests}`
                        )
                      }
                      isDisabled={!houseData.vrboLink}
                    >
                      Copy VRBO Link
                    </Button>
                    <Button
                      size={"sm"}
                      colorScheme={"blue"}
                      variant={"solid"}
                      onClick={() =>
                        navigator.clipboard.writeText(
                          `${houseData.marriottLink}/?startDate=${checkinDate}&endDate=${checkoutDate}&number_of_guests=${guests}`
                        )
                      }
                      isDisabled={!houseData.marriottLink}
                    >
                      Copy Marriott Link
                    </Button>
                    <Button
                      size={"sm"}
                      colorScheme={"pink"}
                      variant={"outline"}
                      onClick={() =>
                        window.open(
                          `${houseData.airbnbLink}/?check_in=${checkinDate}&check_out=${checkoutDate}&guests=${guests}`
                        )
                      }
                      isDisabled={!houseData.airbnbLink}
                    >
                      Vew Airbnb Link
                    </Button>
                    <Button
                      size={"sm"}
                      colorScheme={"blue"}
                      variant={"outline"}
                      onClick={() =>
                        window.open(
                          `${houseData.vrboLink}/?startDate=${checkinDate}&endDate=${checkoutDate}&adults=${guests}`
                        )
                      }
                      isDisabled={!houseData.vrboLink}
                    >
                      View VRBO Link
                    </Button>
                    <Button
                      size={"sm"}
                      colorScheme={"blue"}
                      variant={"outline"}
                      onClick={() =>
                        window.open(
                          `${houseData.marriottLink}/?startDate=${checkinDate}&endDate=${checkoutDate}&number_of_guests=${guests}`
                        )
                      }
                      isDisabled={!houseData.marriottLink}
                    >
                      View Marriott Link
                    </Button>
                  </Flex>
                ) : (
                  <Button
                    size={"sm"}
                    colorScheme={"dmOrange"}
                    onClick={handleCheckout}
                  >
                    Confirm and Pay
                  </Button>
                )}
              </Flex>
            </>
          ) : (
            <>
              {errorType === "coupon" && (
                <Text mt={1} color={"dmOrange.500"}>
                  <b>This coupon is no longer valid.</b>
                </Text>
              )}
              {errorType === "dates" && (
                <Text mt={1} color={"dmOrange.500"}>
                  <b>These dates are no longer available.</b>
                </Text>
              )}
              {errorType === "unknown" && (
                <Text mt={1} color={"dmOrange.500"}>
                  <b>There was an error processing this quote item.</b>
                </Text>
              )}
              <Flex
                mt={1}
                gap={2}
                w={"100%"}
                justifyContent={"start"}
                alignItems={"start"}
              >
                {adminMode ? (
                  <>
                    <Button
                      size={"sm"}
                      colorScheme={"dmNavy"}
                      onClick={handleDelete}
                    >
                      Remove from Quote
                    </Button>
                    {errorType === "coupon" && isEditing && (
                      <Button
                        size={"sm"}
                        colorScheme={"dmOrange"}
                        variant={"solid"}
                        onClick={() => {
                          handleSave();
                          setIsEditing(false);
                        }}
                        isDisabled={!couponChanged}
                      >
                        Save
                      </Button>
                    )}
                  </>
                ) : (
                  <Button size={"sm"} colorScheme={"dmNavy"}>
                    Find Other Dates
                  </Button>
                )}
              </Flex>
            </>
          )}
        </Flex>
      </Flex>

      {isEditing ? (
        <Flex className="quote-note" pt={2} flexDirection={"column"} gap={1}>
          <Text fontSize={"15px"} color={"dmNavy"}>
            <b>Message to Guest :</b> {editedNote}
          </Text>
          <Textarea
            value={editedNote}
            onChange={(e) => setEditedNote(e.target.value)}
          />
        </Flex>
      ) : (
        editedNote &&
        editedNote.length > 0 && (
          <Flex className="quote-note" pt={2}>
            <Text fontSize={"15px"} color={"dmNavy"}>
              <b>Notes from Del Mar:</b> {editedNote}
            </Text>
          </Flex>
        )
      )}
    </Flex>
  ) : (
    <QuoteCardSkeleton />
  );
};

export { QuoteCard };

export const QuoteCardSkeleton: React.FC = () => {
  return (
    <Flex
      className="listing-card"
      flexDirection={"row"}
      gap={2}
      position="relative"
      textAlign={"left"}
    >
      <AspectRatio
        className="listing-photo"
        width="100%"
        maxW={"150px"}
        ratio={1.2}
      >
        <Skeleton height="100%" width="100%" />
      </AspectRatio>

      <Flex
        className="listing-details"
        w={"100%"}
        flexDirection={"column"}
        gap={1}
      >
        <Skeleton height="16px" width="100%" />
        <SkeletonText noOfLines={1} spacing="4" skeletonHeight="15px" />

        <Flex className="listing-reviews-parent" alignItems={"center"} px={1}>
          <Skeleton height="15px" width="30px" />
          <Skeleton height="15px" width="15px" mx={1.5} />
          <Skeleton height="15px" width="60px" />
        </Flex>

        <Flex
          className="listing-details"
          fontSize={"14px"}
          gap={2}
          alignItems={"flex-start"}
          color={"dmNavy.400"}
          px={1}
        >
          <Skeleton height="14px" width="40px" />
          <Skeleton height="14px" width="20px" />
          <Skeleton height="14px" width="40px" />
          <Skeleton height="14px" width="20px" />
          <Skeleton height="14px" width="40px" />
        </Flex>

        <Flex
          className="listing-price"
          fontSize={"15px"}
          px={1}
          flexDirection="column"
          textAlign={"left"}
        >
          <Skeleton height="15px" width="80px" />
          <Skeleton height="15px" width="120px" />
        </Flex>
      </Flex>

      <Flex
        className={"listing-pricing"}
        w={"100%"}
        flexDirection={"column"}
        fontSize={"15px"}
        gap={1}
        borderLeft={"solid 1px #DEDEDE"}
        ml={3}
        pl={4}
        textAlign={"start"}
      >
        <Skeleton height="15px" width="120px" />
        <Skeleton height="15px" width="120px" />
        <Skeleton height="15px" width="80px" />
        <Skeleton height="15px" width="180px" mt={1} />
        <Flex
          mt={2}
          gap={2}
          w={"100%"}
          justifyContent={"start"}
          alignItems={"start"}
        >
          <Skeleton height="30px" width="100px" />
          <Skeleton height="30px" width="120px" />
        </Flex>
      </Flex>
    </Flex>
  );
};
