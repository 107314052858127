import Cookies from "js-cookie";
import config from "../config";

const defaultHeaders = () => {
  const token = Cookies.get("__session");
  return {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
};

const serviceRequestHeaders = () => {
  const token = Cookies.get("__session");
  return {
    Authorization: `Bearer ${token}`,
  };
};

const API_ENDPOINT = config.apiEndpoint;

export const fetchPlaces = (options?) => {
  const isGuestChoice = options.isGuestChoice;
  const type = options.type;

  let query = "";
  if (isGuestChoice !== undefined) {
    query = `?isGuestChoice=${isGuestChoice}`;
  }
  if (type !== undefined) {
    query = `?type=${type}`;
  }
  //console.log(query);
  return fetch(`${API_ENDPOINT}/places${query}`, {
    method: "GET",
    headers: defaultHeaders(),
  });
};

export const fetchReservations = (options?) => {
  const { tenantEmail } = options.tenantEmail;
  let query = "";
  if (tenantEmail !== undefined) {
    query = `?auth=${Cookies.get("__session")}`;
  }
  return fetch(`${API_ENDPOINT}/reservations${query}`, {
    method: "GET",
    headers: defaultHeaders(),
  });
};

export const fetchHouses = (options?) => {
  const houseNumber = options.houseNumber;
  let query = "";
  //console.log(options);
  if (houseNumber !== undefined) {
    query = `?houseNumber=${houseNumber}`;
  }
  return fetch(`${API_ENDPOINT}/houses${query}`, {
    method: "GET",
    headers: defaultHeaders(),
  });
};

export const fetchSupportManager = (options?) => {
  const { tenantEmail } = options.tenantEmail;
  let query = "";
  if (tenantEmail !== undefined) {
    query = `?auth=${Cookies.get("__session")}`;
  }
  //console.log("endpoint",`${API_ENDPOINT}/support${query}`)
  return fetch(`${API_ENDPOINT}/support${query}`, {
    method: "GET",
    headers: defaultHeaders(),
  });
};

export const handleReview = (fields) => {
  return fetch(`${API_ENDPOINT}/hsforms/handleReview`, {
    method: "POST",
    headers: defaultHeaders(),
    body: JSON.stringify(fields),
  });
};

export const handleCheckout = (fields) => {
  return fetch(`${API_ENDPOINT}/hsforms/handleCheckout`, {
    method: "POST",
    headers: defaultHeaders(),
    body: JSON.stringify(fields),
  });
};

export const handleFooterEmail = (fields) => {
  return fetch(`${API_ENDPOINT}/hsforms/handleFooterEmail`, {
    method: "POST",
    headers: defaultHeaders(),
    body: JSON.stringify(fields),
  });
};

export const handleServiceRequest = (fields) => {
  return fetch(`${API_ENDPOINT}/hsforms/handleServiceRequest`, {
    method: "POST",
    headers: serviceRequestHeaders(),
    body: fields,
  });
};

export const rebookReservation = (fields) => {
  return fetch(`${API_ENDPOINT}/hsforms/rebookReservation`, {
    method: "POST",
    headers: defaultHeaders(),
    body: JSON.stringify(fields),
  });
};

export const createGuests = (input) => {
  return fetch(
    `${API_ENDPOINT}/reservations/${input.reservationNumber}/guests`,
    {
      method: "POST",
      headers: defaultHeaders(),
      body: JSON.stringify(input),
    }
  );
};

export const findReservation = (fields) => {
  return fetch(`${API_ENDPOINT}/reservations/findReservation`, {
    method: "POST",
    headers: defaultHeaders(),
    body: JSON.stringify(fields),
  });
};

export const searchAvailability = async (options) => {
  //console.log("options",options)
  const listingId = options.listingId;
  const calendarStart = options.calendarStart;
  const calendarEnd = options.calendarEnd;
  let query = `${API_ENDPOINT}/houses/${listingId}/availability?startDate=${calendarStart}&endDate=${calendarEnd}`;
  //console.log("Search Availability Query",query)

  const response = await fetch(`${query}`, {
    method: "GET",
    headers: defaultHeaders(),
  });

  const data = await response.json();
  return data;
};

export const generateReservationQuote = async (options) => {
  const listingId = options.listingId;
  const checkInDateLocalized = options.checkInDateLocalized;
  const checkOutDateLocalized = options.checkOutDateLocalized;
  const guestsCount = options.guestsCount;

  let query = `${API_ENDPOINT}/houses/generateReservationQuote?checkInDateLocalized=${checkInDateLocalized}&checkOutDateLocalized=${checkOutDateLocalized}&listingId=${listingId}&guestsCount=${guestsCount}`;
  //console.log("Reservation Quote Query",query)

  const response = await fetch(query, {
    method: "POST",
    headers: defaultHeaders(),
  });

  const data = await response.json();
  return data;
};
