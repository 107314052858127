import {
  CheckCircleIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@chakra-ui/icons";
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Image,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { loadScript } from "@guestyorg/tokenization-js";
import { format } from "date-fns";
import addDays from "date-fns/addDays";
import { Field, Form, Formik, FormikProps, useFormikContext } from "formik";
import isEqual from "lodash.isequal";
import * as React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactPixel from "react-facebook-pixel";
import { MdKeyboardBackspace } from "react-icons/md";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";
import { SearchResultsContext } from "../contexts/SearchResultsContext";
import { MAX_END_DATE } from "../helpers/constants";
import {
  createInstantReservation,
  getReservationQuote,
  sendHubspotEvent,
} from "../helpers/fetch";
import {
  ScrollToTop,
  calculateDaysBetweenDates,
  calculateDaysToCheckin,
  createDateAtLocalMidnight,
  formatDateToYYYYMMDD,
  reformatDateString,
} from "../helpers/helpers";
import {
  createReservedReservation,
  getCheckOutById,
  updateReservation,
  upsertDbReservation,
} from "../helpers/reservationService";

interface GuestFormValues {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

const Checkout = () => {
  const navigate = useNavigate();

  // Get House Number from URL (e.g., book.thisisdelmar.com/20001)
  let { houseNumber } = useParams();

  // All Contexts
  const {
    contextDateRange,
    setContextDateRange,
    guests,
    setGuests,
    houseData,
    setHouseData,
    houseCalendar,
  } = React.useContext(SearchResultsContext);

  /// Rebook Mode Variables ///
  const [rebookMode, setRebookMode] = React.useState(false);
  const [rebookParameterReservation, setRebookParameterReservation] =
    React.useState(null);
  const [rebookCoupon, setRebookCoupon] = React.useState(null);

  React.useEffect(() => {
    // Check localStorage to see if rebook mode is active
    const rebookParameter = localStorage.getItem("dm-rebook-mode");
    setRebookParameterReservation(
      localStorage.getItem("dm-rebook-reservation")
    );
    const oneDayInMilliseconds = 24 * 60 * 60 * 1000;
    const currentTime = Date.now();

    if (rebookParameter) {
      const rebookTimestamp = parseInt(rebookParameter, 10);
      if (currentTime - rebookTimestamp < oneDayInMilliseconds) {
        setRebookMode(true);
      }
    }
  }, []);

  React.useEffect(() => {
    const getRebookCheckoutDateAndCoupon = async () => {
      console.log("rebookParameterReservation", rebookParameterReservation);
      if (rebookParameterReservation === "stayfi") {
        setRebookCoupon("FJCP97YWVP9J");
        return;
      }
      if (rebookParameterReservation === "4be10cd6da4b41ee20492eab7bec109d") {
        setRebookCoupon("1KRYHEMOUFVN"); // 5
        return;
      }
      if (rebookParameterReservation === "2f206aa4d52f82d7ee10d01300aa59b7") {
        setRebookCoupon("DXDJJQDVJJID"); // 10
        return;
      }
      const response = await getCheckOutById(rebookParameterReservation);
      const data = await response.json();
      console.log("rebook data", data);

      if (data && data.checkoutDate) {
        const rebookCheckOutDate = data.checkoutDate; // Date String
        const timeDifference =
          new Date().getTime() - new Date(rebookCheckOutDate).getTime();
        const daysDifference = timeDifference / (1000 * 3600 * 24);
        console.log(
          "daysDifference",
          daysDifference,
          "timeDifference",
          timeDifference
        );
        if (daysDifference < 1) {
          setRebookCoupon("FJCP97YWVP9J"); // 12% (stayfi)
        } else if (daysDifference <= 14) {
          setRebookCoupon("DXDJJQDVJJID"); // 10%
        } else {
          setRebookCoupon("1KRYHEMOUFVN"); // 5%
        }
      }
    };
    if (rebookParameterReservation && rebookMode) {
      getRebookCheckoutDateAndCoupon();
      console.log(
        "rebookParameterReservation",
        rebookParameterReservation,
        "rebookCoupon",
        rebookCoupon,
        "rebookMode",
        rebookMode
      );
    }
  }, [rebookParameterReservation]);

  /// END Rebook Mode Variables ///

  // Terms Modal Handler
  const {
    isOpen: isTermsModalOpen,
    onOpen: onOpenTermsModal,
    onClose: onCloseTermsModal,
  } = useDisclosure();

  //// Submit for Reservation Quote ////
  const [nightlyRates, setNightlyRates] = React.useState(null);
  const [taxesAndFees, setTaxesAndFees] = React.useState(null); // Array
  const [totalTaxesAndFees, setTotalTaxesAndFees] = React.useState(null); // Sum
  const [taxes, setTaxes] = React.useState(null);
  const [fees, setFees] = React.useState(null);
  const [totalPrice, setTotalPrice] = React.useState(null);
  const [reservationQuote, setReservationQuote] = React.useState(null);
  const [reservationConfirmation, setReservationConfirmation] =
    React.useState(null);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [isQuoteLoading, setIsQuoteLoading] = React.useState(true);
  const [isBooking, setIsBooking] = React.useState(false);
  const [numNights, setNumNights] = React.useState(null);
  const [daysToCheckin, setDaysToCheckin] = React.useState(null);

  const [shouldReturnToListing, setShouldReturnToListing] =
    React.useState(false);
  const [fromLocation, setFromLocation] = React.useState(null);

  const [editMode, setEditMode] = React.useState(false);
  const [isTaxesExpanded, setIsTaxesExpanded] = React.useState(false);
  const [isFeesExpanded, setIsFeesExpanded] = React.useState(false);
  const toggleTaxesExpand = () => setIsTaxesExpanded(!isTaxesExpanded);
  const toggleFeesExpand = () => setIsFeesExpanded(!isFeesExpanded);
  const [totalTaxes, setTotalTaxes] = React.useState(null);
  const [totalFees, setTotalFees] = React.useState(null);

  const [coupon, setCoupon] = React.useState(null);
  const [discount, setDiscount] = React.useState(null);
  const [couponValidated, setCouponValidated] = React.useState(null);
  const [editCouponMode, setEditCouponMode] = React.useState(false);

  // Handling Cancel Edit Quote
  const [oldDates, setOldDates] = React.useState([null, null]);
  const [oldCoupon, setOldCoupon] = React.useState(null);
  const [oldQuote, setOldQuote] = React.useState(null);

  const [checkoutStartDate, setCheckoutStartDate] = React.useState(
    reservationQuote ? reservationQuote.checkInDateLocalized : null
  );
  const [checkoutEndDate, setCheckoutEndDate] = React.useState(
    reservationQuote ? reservationQuote.checkOutDateLocalized : null
  );
  const [checkoutDateRange, setCheckoutDateRange] = React.useState([
    null,
    null,
  ]);

  // Abandon Checkout Variables
  const [sessionId] = React.useState(uuidv4());

  const [abandonCheckoutData, setAbandonCheckoutData] = React.useState({
    firstName: "", // String
    lastName: "", // String
    email: "", // String
    phone: "", // String
    houseNumber: houseNumber, // String
    checkinDate: checkoutStartDate || null, // String or null
    checkoutDate: checkoutEndDate || null, // String or null
    guests: guests || null, // Integer or null
    coupon: coupon || null, // String or null
    totalPrice: totalPrice || null, // Float or null
    sessionId: sessionId, // String generated by uuidv4(), ex: 7d1669f1-05e5-4aa9-8669-1a19cb4d0662
  });

  const prevAbandonCheckoutData = React.useRef(abandonCheckoutData);

  const getHubspotUTK = () => {
    const cookies = document.cookie.split(";");
    const hubspotCookie = cookies.find((cookie) =>
      cookie.trim().startsWith("hubspotutk=")
    );
    return hubspotCookie ? hubspotCookie.split("=")[1] : null;
  };

  React.useEffect(() => {
    const hasChanged = !isEqual(
      abandonCheckoutData,
      prevAbandonCheckoutData.current
    );

    if (hasChanged) {
      prevAbandonCheckoutData.current = abandonCheckoutData;

      if (
        abandonCheckoutData.firstName ||
        abandonCheckoutData.lastName ||
        abandonCheckoutData.email ||
        abandonCheckoutData.phone ||
        abandonCheckoutData.checkinDate ||
        abandonCheckoutData.checkoutDate ||
        abandonCheckoutData.guests ||
        abandonCheckoutData.coupon ||
        abandonCheckoutData.totalPrice
      ) {
        const hubspotUTK = getHubspotUTK();
        const eventName = "pe21053466_checkout_form_initiated";
        const properties = {
          firstname: abandonCheckoutData.firstName,
          lastname: abandonCheckoutData.lastName,
          email: abandonCheckoutData.email,
          phone: abandonCheckoutData.phone,
          housenumber: abandonCheckoutData.houseNumber,
          checkindate: abandonCheckoutData.checkinDate,
          checkoutdate: abandonCheckoutData.checkoutDate,
          guests: abandonCheckoutData.guests,
          coupon: abandonCheckoutData.coupon,
          totalprice: abandonCheckoutData.totalPrice,
          sessionid: abandonCheckoutData.sessionId,
        };

        const eventData = {
          eventName,
          properties,
          objectType: "contacts",
          email: abandonCheckoutData.email || undefined,
          utk: hubspotUTK || undefined,
        };

        if (abandonCheckoutData.email || abandonCheckoutData.phone) {
          // Fire and forget
          (async () => {
            try {
              await sendHubspotEvent(eventData);
            } catch (error) {
              console.error("Error sending HubSpot event:", error);
            }
          })();
        }
      }
      prevAbandonCheckoutData.current = abandonCheckoutData;
    }
  }, [abandonCheckoutData]);

  // Function to Process Reservation Quote on Page Load and on Date/Guests Edit
  function processReservationQuote(reservationQuote) {
    const rates = reservationQuote.rates.ratePlans[0].ratePlan.money;

    setNightlyRates(rates.fareAccommodation);

    const calculatedTaxesAndFees = rates.invoiceItems.filter(
      (item) => item.type !== "ACCOMMODATION_FARE" && item.type !== "DISCOUNT"
    );

    setTaxesAndFees(calculatedTaxesAndFees);

    const taxItems = calculatedTaxesAndFees.filter((item) =>
      item.title.toLowerCase().includes("tax")
    );
    const feeItems = calculatedTaxesAndFees.filter((item) =>
      item.title.toLowerCase().includes("fee")
    );

    setTaxes(taxItems);
    setFees(feeItems);

    const totalTaxes = taxItems.reduce((acc, item) => acc + item.amount, 0);
    const totalFees = feeItems.reduce((acc, item) => acc + item.amount, 0);

    const discountItemAmount = rates.invoiceItems.find(
      (item) => item.type === "DISCOUNT"
    )?.amount;
    if (discountItemAmount) {
      setDiscount(discountItemAmount);
    }

    setTotalTaxesAndFees(totalTaxes + totalFees);
    setTotalPrice(
      rates.fareAccommodation +
        totalTaxes +
        totalFees +
        (discountItemAmount || 0)
    );
    setTotalTaxes(totalTaxes);
    setTotalFees(totalFees);

    /*console.log("Reservation Quote:", reservationQuote);
    console.log(
      "Quote Breakdown:",
      "nightlyRates",
      rates.fareAccommodation,
      "taxesAndFees",
      totalTaxes + totalFees,
      "totalPrice",
      rates.fareAccommodation + totalTaxes + totalFees,
      "quoteId",
      reservationQuote._id,
      "reservationQuote.rates.ratePlans[0].ratePlan._id",
      reservationQuote.rates.ratePlans[0].ratePlan._id
    );
    console.log(
      "reservation quote",
      reservationQuote,
      "houseData",
      houseData,
      "editMode",
      editMode
    );*/

    setNumNights(
      calculateDaysBetweenDates(
        reservationQuote.checkInDateLocalized,
        reservationQuote.checkOutDateLocalized
      )
    );
    //console.log('numNights',calculateDaysBetweenDates(reservationQuote.checkInDateLocalized,reservationQuote.checkOutDateLocalized));

    setDaysToCheckin(
      calculateDaysToCheckin(reservationQuote.checkInDateLocalized)
    );
    console.log(
      "days to checkin:",
      calculateDaysToCheckin(reservationQuote.checkInDateLocalized)
    );

    //console.log('coupon',coupon,'discount',discountItemAmount)

    setCheckoutStartDate(reservationQuote.checkInDateLocalized);
    setCheckoutEndDate(reservationQuote.checkOutDateLocalized);
    setAbandonCheckoutData((prev) => ({
      ...prev,
      checkinDate: reservationQuote.checkInDateLocalized,
      checkoutDate: reservationQuote.checkOutDateLocalized,
      guests: reservationQuote.guestsCount,
      coupon: coupon,
      totalPrice:
        rates.fareAccommodation +
        totalTaxes +
        totalFees +
        (discountItemAmount || 0),
    }));
    setAbandonCheckoutData["checkoutDate"] =
      reservationQuote.checkOutDateLocalized;
    setCheckoutDateRange([
      createDateAtLocalMidnight(reservationQuote.checkInDateLocalized),
      createDateAtLocalMidnight(reservationQuote.checkOutDateLocalized),
    ]);
  }

  // Receive Initial Reservation Quote from Listing.tsx
  const location = useLocation();
  //console.log('location',location)
  React.useEffect(() => {
    if (location.state?.reservationQuote) {
      setReservationQuote(location.state.reservationQuote);
      processReservationQuote(location.state.reservationQuote);
    }
    setIsQuoteLoading(false);
    if (location.state?.houseData) {
      setHouseData(location.state.houseData);
    }
    if (location.state?.guests) {
      setGuests(location.state.guests);
    }
    if (location.state?.fromLocation) {
      setFromLocation(location.state.fromLocation);
    }
    console.log("location.state", location.state);
  }, [location.state]);

  //// Form Scroll to Error Handling ////
  const getFieldErrorNames = (formikErrors) => {
    const transformObjectToDotNotation = (obj, prefix = "", result = []) => {
      Object.keys(obj).forEach((key) => {
        const value = obj[key];
        if (!value) return;

        const nextKey = prefix ? `${prefix}.${key}` : key;
        if (typeof value === "object") {
          transformObjectToDotNotation(value, nextKey, result);
        } else {
          result.push(nextKey);
        }
      });

      return result;
    };

    return transformObjectToDotNotation(formikErrors);
  };

  const ScrollToFieldError = ({
    scrollBehavior = {
      behavior: "smooth",
      block: "center",
    } as ScrollIntoViewOptions,
  }) => {
    const { submitCount, isValid, errors } = useFormikContext();

    React.useEffect(() => {
      if (isValid) return;

      const fieldErrorNames = getFieldErrorNames(errors);
      if (fieldErrorNames.length <= 0) return;

      const element = document.querySelector(
        `input[name='${fieldErrorNames[0]}']`
      );
      if (!element) return;

      // Scroll to first known error into view
      element.scrollIntoView(scrollBehavior);

      // Formik doesn't (yet) provide a callback for a client-failed submission,
      // thus why this is implemented through a hook that listens to changes on
      // the submit count.
    }, [submitCount]); // eslint-disable-line react-hooks/exhaustive-deps

    return null;
  };
  //// END Form Scroll to Error Handling ////

  // Edit Date/Guests
  function editQuote() {
    //setOldDates(contextDateRange);
    setOldDates([checkoutStartDate, checkoutEndDate]);
    setOldCoupon(coupon);
    setOldQuote(reservationQuote);
    setEditMode(true);
  }

  // Handler for Checkout Edit, Generate New Quote
  const handleEditSubmit = async (
    action: string,
    e?: React.MouseEvent<HTMLButtonElement>
  ) => {
    if (e) e.preventDefault();

    if (action !== "couponEdit") {
      setEditCouponMode(true);
    } else {
      setEditMode(true);
    }
    setIsQuoteLoading(true);

    const oldQuote = reservationQuote; // Saving oldQuote for coupon error handling

    try {
      if (!houseData) {
        console.error("houseData is not available");
        return;
      }

      setErrorMessage(null);
      setNightlyRates(null);
      setTaxesAndFees(null);
      setTotalPrice(null);
      setErrorMessage(null);

      const formattedStartDate = format(checkoutDateRange[0], "yyyy-MM-dd");
      const formattedEndDate = format(checkoutDateRange[1], "yyyy-MM-dd");

      const listingId = houseData.guestyHouseId;

      const queryParams = {
        checkInDateLocalized: formattedStartDate,
        checkOutDateLocalized: formattedEndDate,
        listingId,
        guestsCount: guests,
        ...(coupon ? { coupons: coupon } : {}), // Conditionally add coupons only if coupon is truthy
      };

      // Fetch call to backend server
      const response = await getReservationQuote(queryParams);

      if (!response.ok) {
        const errorResponse = await response.json();
        throw new Error(
          errorResponse.error.message ||
            `HTTP error! Status: ${response.status}`
        );
      }
      // Process the response as needed
      const reservationQuote = await response.json();
      //console.log('Reservation Quote:', reservationQuote);
      processReservationQuote(reservationQuote);
      setReservationQuote(reservationQuote);
      setCouponValidated(true);
      setEditCouponMode(false);
    } catch (error) {
      console.error("Error:", error.message);
      setErrorMessage(error.message);

      if (error.message.includes("coupons")) {
        processReservationQuote(oldQuote);
        setReservationQuote(oldQuote);
        setCoupon(null);
        //console.log("coupon error")
      } else {
        setReservationQuote(null);
        //console.log("non-coupon error")
      }
    } finally {
      setEditMode(false);
      setIsQuoteLoading(false);
    }
  };

  // Edit Cancel
  const handleEditCancel = () => {
    //console.log(oldDates);
    //setContextDateRange(oldDates);
    setCheckoutStartDate(oldDates[0]);
    setCheckoutEndDate(oldDates[1]);
    setCoupon(oldCoupon);
    setReservationQuote(oldQuote);
    setEditMode(false);
  };

  // Return to Listing Page with Search URL Params
  const returnToListing = async () => {
    if (fromLocation && fromLocation.includes("quote")) {
      navigate(`${fromLocation}`);
      return;
    }

    // Serialize parameters into a query string
    const queryParams = new URLSearchParams();
    if (checkoutStartDate && checkoutEndDate) {
      const startDate = checkoutStartDate;
      const endDate = checkoutEndDate;
      queryParams.set("startdate", startDate);
      queryParams.set("enddate", endDate);
    }
    if (guests) {
      queryParams.set("guests", JSON.stringify(guests));
    }

    const queryString = queryParams.toString();
    const url = `/${houseNumber}?${queryString}`;
    //console.log("queryString",queryString)

    navigate(url);
  };

  // Return to Listing Page without Search URL Params
  const returnToListingBlank = async () => {
    const url = `/${houseNumber}`;
    window.open(url, "_self");
  };

  //// Payment Tokenization ////
  const [guestyTokenization, setGuestyTokenization] = React.useState(null);
  const [isSdkLoaded, setIsSdkLoaded] = React.useState(false);

  React.useEffect(() => {
    const loadGuestySDK = async () => {
      if (!isSdkLoaded) {
        try {
          //console.log("Loading Guesty SDK...");
          const loadedGuestyTokenization = await loadScript();
          setGuestyTokenization(loadedGuestyTokenization);

          //console.log("SDK loaded.");
          setIsSdkLoaded(true);
        } catch (error) {
          console.error(
            "Failed to load/render the Guesty Tokenization JS SDK script",
            error
          );
          setErrorMessage(
            "Error loading payment processor. Please try again later."
          );
        }
      }
    };

    loadGuestySDK();
  }, []); // Make sure this effect runs once on mount

  React.useEffect(() => {
    if (reservationQuote && isSdkLoaded) {
      if (guestyTokenization) {
        renderGuestyTokenization(guestyTokenization);
      }
    }
  }, [reservationQuote, isSdkLoaded, guestyTokenization, totalPrice]);

  const renderGuestyTokenization = async (tokenization) => {
    const container = document.getElementById("guesty-tokenization-container");
    if (container && tokenization) {
      container.innerHTML = ""; // Clear existing content if needed

      //console.log("passing total price to guesty tokenization", totalPrice)

      try {
        await tokenization.render({
          containerId: "guesty-tokenization-container",
          providerId: "647abc2a4389560040699f19",
          amount: 5,
          currency: "USD",
          initialValues: { country: "US" },
        });
        //console.log("Guesty tokenization rendered successfully.");
      } catch (error) {
        console.error("Error rendering Guesty tokenization:", error);
      }
    }
  };
  //// END Payment Tokenization ////

  //// Form Submission Handling ////
  const formikRef = React.useRef<FormikProps<GuestFormValues>>(null);

  const validationSchema = Yup.object({
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    email: Yup.string().email("Invalid email address").required("Required"),
    phone: Yup.string().required("Required"),
  });

  const handleSubmit = async (values, actions) => {
    // Assuming guest details are validated at this point
    //console.log("Guest Data", values);
    actions.setSubmitting(true);

    const errors = await actions.validateForm();
    if (!Object.keys(errors).length) {
      // No errors
      // Show modal with loading spinner
      setIsModalOpen(true);
      setModalContent({
        message: "Processing your reservation...",
        isLoading: true,
      });

      try {
        if (!guestyTokenization) {
          throw new Error(
            "Payment system is not ready. Please try again later."
          );
        }
        //console.log("Submitting Payment Form...",guestyTokenization);
        const paymentMethod = await guestyTokenization.submit();
        //console.log("Payment method:", paymentMethod);

        if (!paymentMethod) {
          setModalContent({
            message: "Please complete all fields and try again.",
            isLoading: false,
          });
        }

        // Proceed with reservation creation if payment succeeds
        const reservationBooking = await handleCreateReservation(
          paymentMethod,
          values
        );
        //console.log("ReservationBooking response", reservationBooking);
        setModalContent({
          message: "Reservation successful!",
          isLoading: false,
        });
        // Navigate to success page or show success message as needed
      } catch (error) {
        console.error("Reservation process failed", error);

        // If reservation from quote (booking api) fails, likely due to payment issues,
        // then create a Guesty Reservation in "reserved" status for manual follow up
        if (error.message.includes("402")) {
          const formattedStartDate = checkoutStartDate;
          const formattedEndDate = checkoutEndDate;
          const reservedReservationData = {
            guest: {
              firstName: values.firstName,
              lastName: values.lastName,
              email: values.email,
              phone: values.phone,
            },
            checkInDateLocalized: formattedStartDate,
            checkOutDateLocalized: formattedEndDate,
            houseNumber: houseData.houseNumber,
            guests: guests,
          };
          //console.log('reservedReservationData',reservedReservationData)
          try {
            await createReservedReservation(reservedReservationData);
            //console.log("create reservation successful, next line")
            setModalContent({
              message:
                "Your vacation dates are confirmed! You will receive a confirmation email within the next 48 hours.",
              isLoading: false,
            });
            setShouldReturnToListing(true);
            actions.setSubmitting(false);

            // Fire the purchase event
            ReactPixel.track("Purchase", {
              value: totalPrice,
              currency: "USD",
              content_name: "House Number",
              content_ids: [houseNumber],
              content_type: "booking",
            });
          } catch (error) {
            console.error("Manual reservation process failed too", error);
            setErrorMessage(
              error.message || "An unexpected error occurred. Please try again."
            );
            setModalContent({
              message: "Sorry, there has been an issue!",
              isLoading: false,
            });
            actions.setSubmitting(false);
          }
        }
      } finally {
        actions.setSubmitting(false);
      }
    } else {
      actions.setSubmitting(false);
    }
  };

  //// Submitting Reservation ////

  // Submission and Error Handling in UI
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [modalContent, setModalContent] = React.useState({
    message: "",
    isLoading: true,
  });

  const handleCreateReservation = async (paymentMethod, formValues) => {
    // Clear Error Message
    setErrorMessage(null);

    // Prepare data for reservation creation
    const quoteId = reservationQuote._id;
    const ratePlanId = reservationQuote.rates.ratePlans[0].ratePlan._id;
    const ccToken = paymentMethod._id;
    const guest = {
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      email: formValues.email,
      phone: formValues.phone,
    };

    // Proceed to create the reservation
    const result = await createInstantReservation(
      quoteId,
      ratePlanId,
      ccToken,
      guest
    );

    if (result.error) {
      console.error("Error creating reservation:", result.error);
      setErrorMessage("Error creating reservation. Please try again later.");
    } else {
      //console.log('Reservation created successfully:', result);
      setModalContent({ message: "Payment Successful", isLoading: false });
      setReservationConfirmation(result);

      // Get attribution variable from local storage
      const dmAttribution = localStorage.getItem("dm-attribution");

      // Prepare data for DB insertion
      const dbReservationData = {
        checkinDate: result.checkInDateLocalized,
        checkoutDate: result.checkOutDateLocalized,
        checkinTime: "16:00",
        checkoutTime: "09:00",
        houseNumber: houseData.houseNumber,
        tenantEmail: formValues.email,
        status: "Booked",
        adults: result.guestsCount,
        typename: "Direct",
        channel: "Direct",
        firstName: formValues.firstName,
        lastName: formValues.lastName,
        nightlyRates:
          reservationQuote.rates.ratePlans[0].ratePlan.money
            .fareAccommodationAdjusted,
        taxes: reservationQuote.rates.ratePlans[0].ratePlan.money.totalTaxes,
        cleaningFee:
          reservationQuote.rates.ratePlans[0].ratePlan.money.fareCleaning,
        totalPrice: totalPrice,
        guestyId: result._id,
        attribution: dmAttribution || null,
      };

      async function hashEmail(email) {
        const encoder = new TextEncoder();
        const data = encoder.encode(email.trim().toLowerCase());
        const hashBuffer = await crypto.subtle.digest("SHA-256", data);
        const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
        const hashHex = hashArray
          .map((b) => b.toString(16).padStart(2, "0"))
          .join("");
        return hashHex;
      }

      // Proceed to create the reservation in your own database
      try {
        const dbResponse = await upsertDbReservation(
          result.confirmationCode,
          dbReservationData
        );
        const dbData = await dbResponse.json();

        if (!dbResponse.ok) {
          throw new Error(`DB error! Status: ${dbResponse.status}`);
        }
        console.log("DB Reservation created successfully:", dbData);

        // Attribution logic handler
        if (dmAttribution && dmAttribution.trim() !== "") {
          updateReservation(result.confirmationCode, {
            attribution: dmAttribution,
          })
            .then(() => console.log("Update reservation called"))
            .catch((err) =>
              console.error("Failed to call update reservation:", err)
            );
        }

        // Hash the email before sending it to GTM
        hashEmail(formValues.email)
          .then((hashedEmail) => {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: "bookingComplete",
              reservationNumber: result.confirmationCode,
              nightlyRates: dbReservationData.nightlyRates,
              sha256_email_address: hashedEmail, // Now sending hashed email
            });
          })
          .catch((error) => {
            console.error("Error hashing email:", error);
            // Handle the error appropriately
          });
      } catch (dbError) {
        console.error("Error creating DB reservation:", dbError.message);
        setErrorMessage(
          "Error processing reservation. Please contact Del Mar Vacations for assistance."
        );

        // Handle the DB creation error
      }

      // Navigate to Confirmation Page
      navigate(`/confirmation/${result.confirmationCode}`);
    }
  };

  /// Form Abandonment Handling ///

  const handleFieldBlur = (field, value) => {
    setAbandonCheckoutData((prev) => ({ ...prev, [field]: value }));
  };

  React.useEffect(() => {
    return () => {
      if (
        abandonCheckoutData.firstName ||
        abandonCheckoutData.lastName ||
        abandonCheckoutData.email ||
        abandonCheckoutData.phone
      ) {
        // Handle the submission or storage of the abandon checkout data here
        console.log("Abandon Checkout Data:", abandonCheckoutData);
        // Example: Send the data to your backend or analytics service
        // sendAbandonCheckoutData(abandonCheckoutData);
      }
    };
  }, [abandonCheckoutData]);

  /// End Form Abandonment Handling ///

  // Helpers for -/+ Guest Count
  function decrementGuestInput() {
    setGuests((prevGuests) => {
      const currentGuests = isNaN(prevGuests) ? 1 : prevGuests;
      return Math.max(1, currentGuests - 1);
    });
  }
  function incrementGuestInput() {
    setGuests((prevGuests) => {
      const currentGuests = isNaN(prevGuests) ? 1 : prevGuests;
      return Math.min(houseData.occupancy, currentGuests + 1);
    });
  }

  //// Guesty Calendar Handling ////

  const [guestyData, setGuestyData] = React.useState([]);
  const [startDatePicker, endDatePicker] = contextDateRange;
  const [bookedDates, setBookedDates] = React.useState([]);
  const [datesNotOkayToCheckIn, setDatesNotOkayToCheckIn] = React.useState([]);
  const [datesToGrayOut, setDatesToGrayOut] = React.useState([]);
  const [bookedAndNotOkayToCheckOutDates, setBookedAndNotOkayToCheckOutDates] =
    React.useState([]);
  const [currentlyShowing, setCurrentlyShowing] = React.useState("checkIn");

  // This is to adjust the Guesty date data if needed to make sure it blanks out the right dates i the datepicker
  const hoursOffset = new Date().getTimezoneOffset() / 60;

  React.useEffect(() => {
    if (houseCalendar) {
      // Get the Guesty data on initial page load (should only do this once, all other changes to datepicker will be done via state changes)
      const guestyDataRaw = houseCalendar;
      // Transform the data to get rid of fields we don't need and alter dates if needed, saves a COPY to be used for Check Outs later
      const guestyDataTransformed = transformGuestyData(
        guestyDataRaw,
        hoursOffset
      );
      setGuestyData([...guestyDataTransformed]);
      // Save the dates that are booked to make them a different style/color in the datepicker
      const getBookedDates = guestyDataTransformed
        .filter((date) => date.status === "booked")
        .map((date) => Date.parse(date.startDate));
      setBookedDates(getBookedDates);
      // Add checkInOkay = true/false field to each date
      const guestyDataCheckInsProcessed = processCheckIns(
        guestyDataTransformed
      );
      // Create an array of all dates that have checkInOkay = false, then grab the startDate from each of them so it's just an array of dates for the datepicker
      const badCheckInDates = guestyDataCheckInsProcessed
        .filter((date) => !date.checkInOkay && date.startDate)
        .map((date) => date.startDate);
      // Set that array of dates to be grayed out
      setDatesToGrayOut(badCheckInDates);
      // (And save that array of dates so we can gray them again after user clicks an end date)
      setDatesNotOkayToCheckIn(badCheckInDates);
    }
  }, [houseCalendar]);

  // Trims down the Guesty data to only the parts we care about within the next year
  function transformGuestyData(guestyDataRaw, hoursOffset) {
    const oneYearFromToday = new Date(MAX_END_DATE);

    return (
      guestyDataRaw.data.days
        // Filter out dates that are past a year from now
        .filter(
          (day) => new Date(day.date).getTime() <= oneYearFromToday.getTime()
        )
        .map((day) => {
          let guestyDate = new Date(day.date);
          guestyDate.setHours(0);
          guestyDate.setMinutes(0);
          guestyDate.setSeconds(0);
          // If hoursOffset is greater than 0, add 1 day to all dates
          if (hoursOffset > 0)
            guestyDate = new Date(guestyDate.setDate(guestyDate.getDate() + 1));

          return {
            startDate: guestyDate,
            status: day.status,
            booked: day.status === "available" ? false : true,
            minDays: day.minNights,
            price: day.price,
            cta: day.cta,
            ctd: day.ctd,
          };
        })
    );
  }

  // Can only check in on a day that's *not* already book AND where CTA is false
  // AND if that date will have a valid Check Out date too
  function processCheckIns(guestyDataTransformed) {
    for (const date of guestyDataTransformed) {
      if (
        date.booked === false &&
        date.cta !== true &&
        processCheckOuts(date.startDate, guestyDataTransformed).length > 0
      ) {
        date.checkInOkay = true;
      } else {
        date.checkInOkay = false;
      }
    }

    return guestyDataTransformed;
  }

  function processCheckOuts(pickedDate, allDates) {
    const pickedDateRange = allDates.find(
      (range) => range.startDate.getTime() === pickedDate.getTime()
    );
    if (!pickedDateRange)
      throw new Error(
        `Date range not found for the picked date: ${pickedDate}`
      );

    // Sort allDates in ascending order of startDate
    allDates.sort((a, b) => a.startDate.getTime() - b.startDate.getTime());

    // Find the first startDate after the pickedDate that is booked
    const firstBookedDateRange = allDates.find(
      (range) =>
        range.booked === true &&
        range.startDate.getTime() > pickedDate.getTime()
    );
    const availableDates = [];

    for (const dateRange of allDates) {
      const startDate = dateRange.startDate;
      const diffTime = startDate.getTime() - pickedDate.getTime();
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      // If the start date of the range is not booked and is at least minDays in the future or is in the past, add it to the available dates
      if (
        dateRange.booked === false &&
        startDate.getTime() >= pickedDate.getTime() &&
        diffDays >= pickedDateRange.minDays &&
        dateRange.ctd !== true
      ) {
        // Stop adding to availableDates if the date is later than the first booked date
        if (
          firstBookedDateRange &&
          startDate.getTime() >= firstBookedDateRange.startDate.getTime()
        )
          break;

        availableDates.push(startDate);
      }
    }

    // Add the first booked startDate after the pickedDate as checkout on this date should be allowed
    if (
      firstBookedDateRange &&
      Math.ceil(
        (firstBookedDateRange.startDate.getTime() - pickedDate.getTime()) /
          (1000 * 60 * 60 * 24)
      ) >= pickedDateRange.minDays
    ) {
      availableDates.push(firstBookedDateRange.startDate);
    }

    //console.log(availableDates);
    return availableDates;
  }

  //// END Guesty Calendar Handling ////

  return (
    <>
      {(reservationQuote && houseData) || (editMode && !editCouponMode) ? (
        <Box minH={"calc(100vh - 236px)"}>
          <Flex
            maxWidth="1440px"
            width={"100%"}
            pt={5}
            px={{ base: 3, md: 5 }}
            textAlign={"left"}
            alignItems={"start"}
            flexDirection={"column"}
            margin={"0 auto"}
          >
            <Flex
              alignItems={"center"}
              gap={2}
              onClick={returnToListing}
              _hover={{ cursor: "pointer" }}
              pb={{ base: 0, md: 3 }}
              display={{ base: "flex", md: "flex" }}
            >
              <MdKeyboardBackspace />
              {fromLocation && fromLocation.includes("quote") ? (
                <Text>Return to Quote</Text>
              ) : (
                <Text>Return to Listing</Text>
              )}
            </Flex>
          </Flex>
          <Flex
            position={"relative"}
            width={"100%"}
            maxW={"1440px"}
            data-id={"checkout"}
            justifyContent={"start"}
            flexDirection={{ base: "column", md: "row" }}
            alignItems={"start"}
            margin={"0 auto"}
            gap={3}
          >
            <Flex direction="column" w={"100%"}>
              {/* House Summary */}
              <ScrollToTop />
              <Flex
                width={{ base: "100%" }}
                py={{ base: 3, md: 2 }}
                px={{ base: 3, md: 5 }}
                textAlign={"left"}
                alignItems={"start"}
                flexDirection={"column"}
              >
                <Flex maxWidth="1440px" width={"100%"} pt={{ base: 1, md: 3 }}>
                  <Flex
                    width={{ base: "100vw", md: "25vw", lg: "20vw" }}
                    textAlign={"left"}
                    alignItems={"start"}
                    flexDirection={"column"}
                  >
                    <Image
                      src={houseData.pictures[0].original}
                      alt="Listing Image 1"
                      height="100%"
                      maxH={"180px"}
                      width="100%"
                      objectFit="cover"
                      borderRadius={8}
                    />
                  </Flex>
                  <Flex flexDirection={"column"} pl={5}>
                    {/* House Number */}
                    <Flex
                      fontSize={{ base: "16px", md: "20px" }}
                      fontWeight={500}
                      color={"#00000090"}
                      pt={{ base: 0, md: 0 }}
                    >
                      #{houseNumber}
                    </Flex>
                    {/* Listing Title */}
                    <Flex
                      mr={8}
                      fontSize={{ base: "16px", md: "20px" }}
                      mb={{ base: 0, md: 0 }}
                      fontWeight={500}
                    >
                      {houseData.title}
                    </Flex>
                    <Flex
                      mr={8}
                      fontSize={{ base: "14px", md: "16px" }}
                      mb={{ base: 0, md: 0 }}
                      color={"#00000090"}
                      mt={2}
                    >
                      {houseData.town}, MA
                    </Flex>
                    {/* Occupancy, Bedroom, Bathroom Icons */}
                    <Flex
                      className="listing-details"
                      fontSize={"12px"}
                      alignItems={"flex-start"}
                      mt={{ base: 1, md: 4 }}
                      px={1}
                    >
                      <Flex
                        flexDirection={"column"}
                        className="listing-occupancy"
                        alignItems={"center"}
                        width={"max-content"}
                        me={3}
                      >
                        <Image
                          src={"../media/occupancy.png"}
                          alt="Max Occupancy"
                          objectFit={"cover"}
                          align={"left"}
                          width={"25px"}
                          height={"25px"}
                          className="listing-occupancy-image"
                        />
                        <Text className="listing-occupancy-text">
                          {!isNaN(houseData.occupancy)
                            ? (houseData.occupancy as number)
                            : 0}{" "}
                          Guests
                        </Text>
                      </Flex>
                      <Flex
                        flexDirection={"column"}
                        className="listing-bedrooms"
                        alignItems={"center"}
                        width={"max-content"}
                        me={3}
                      >
                        <Image
                          src={"../media/bedrooms.png"}
                          alt="Bedrooms"
                          objectFit={"cover"}
                          align={"left"}
                          width={"25px"}
                          height={"25px"}
                          className="listing-bedrooms-image"
                        />
                        <Text className="listing-bedrooms-text">
                          {!isNaN(houseData.bedrooms)
                            ? (houseData.bedrooms as number)
                            : 0}{" "}
                          Bedrooms
                        </Text>
                      </Flex>
                      <Flex
                        flexDirection={"column"}
                        className="listing-bathrooms"
                        alignItems={"center"}
                        width={"max-content"}
                      >
                        <Image
                          src={"../media/bathrooms.png"}
                          alt="Bathrooms"
                          objectFit={"cover"}
                          align={"left"}
                          width={"25px"}
                          height={"25px"}
                          className="listing-bathrooms-image"
                        />
                        <Text className="listing-bathrooms-text">
                          {!isNaN(houseData.bathrooms)
                            ? (houseData.bathrooms as number)
                            : 0}{" "}
                          Bathrooms
                        </Text>
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
              {/* End House Summary */}

              {/* Dates and Guests Section */}
              {!editMode && editCouponMode !== true ? (
                <Flex
                  pt={{ base: 2, md: 6 }}
                  flexDirection={{ base: "column", md: "row" }}
                  maxW={{ base: "100vw", md: "1440px" }}
                  gap={3}
                >
                  <Box
                    maxW={{ base: "100%", md: "320px" }}
                    w={{ base: "100%", md: "50%" }}
                    px={{ base: 3, md: 5 }}
                  >
                    <Flex flexDirection={"column"}>
                      <Flex
                        fontSize={{ base: "14px", md: "16px" }}
                        fontWeight={500}
                      >
                        Dates
                      </Flex>
                      <Flex justifyContent={"space-between"}>
                        <Box fontSize={15} color={"#666"} pb={2}>
                          {checkoutStartDate && checkoutEndDate
                            ? `${reformatDateString(
                                checkoutStartDate
                              )} - ${reformatDateString(checkoutEndDate)}`
                            : "Please select dates"}
                        </Box>
                        <Box
                          onClick={editQuote}
                          cursor={"pointer"}
                          textDecoration={"underline"}
                        >
                          edit
                        </Box>
                      </Flex>
                    </Flex>
                    <Flex flexDirection={"column"} pt={{ base: 1, md: 3 }}>
                      <Flex
                        fontSize={{ base: "14px", md: "16px" }}
                        fontWeight={500}
                      >
                        Guests
                      </Flex>
                      <Flex justifyContent={"space-between"}>
                        <Box fontSize={15} color={"#666"} pb={2}>
                          {guests ? `${guests} guests` : "Please select guests"}
                        </Box>
                        <Box
                          onClick={editQuote}
                          cursor={"pointer"}
                          textDecoration={"underline"}
                        >
                          edit
                        </Box>
                      </Flex>
                    </Flex>

                    {rebookCoupon && discount ? (
                      <Flex flexDirection={"column"} pt={{ base: 1, md: 3 }}>
                        <Flex justifyContent={"space-between"} gap={2}>
                          <Alert
                            status="info"
                            borderRadius="md"
                            fontSize="md"
                            mb={3}
                            fontWeight={"normal"}
                          >
                            <AlertIcon />
                            <span>
                              Your rebooking discount code has been
                              automatically applied. You are saving an
                              additional ${(-discount).toFixed(2)} on this
                              reservation.
                            </span>
                          </Alert>
                        </Flex>
                      </Flex>
                    ) : (
                      <Flex flexDirection={"column"} pt={{ base: 1, md: 3 }}>
                        <Flex justifyContent={"space-between"} gap={2}>
                          <Flex
                            fontSize={{ base: "14px", md: "16px" }}
                            fontWeight={500}
                          >
                            Coupon
                          </Flex>
                          {errorMessage && errorMessage.includes("coupons") && (
                            <Text color="red">Invalid Coupon</Text>
                          )}
                        </Flex>
                        {coupon && coupon !== "" && couponValidated === true ? (
                          <Flex justifyContent={"space-between"}>
                            <Flex gap={2} pb={2}>
                              <Box fontSize={15} color={"#666"}>
                                {coupon ? coupon : ""}
                              </Box>
                              <CheckCircleIcon color="green" />
                            </Flex>
                            <Box
                              onClick={editQuote}
                              cursor={"pointer"}
                              textDecoration={"underline"}
                            >
                              {coupon ? "edit" : "add"}
                            </Box>
                          </Flex>
                        ) : (
                          <FormControl
                            height={"30px"}
                            w={"100%"}
                            mt={1}
                          >
                            <Flex
                              gap={2}
                              justifyContent={"space-between"}
                              alignItems={"center"}
                            >
                              <Input
                                textAlign={"center"}
                                flexGrow={1}
                                maxW={"300px"}
                                color="black"
                                type="text"
                                border="solid 1px #D4D8E1"
                                onChange={(e) => {
                                  setCouponValidated(false);
                                  const newValue = e.target.value;
                                  if (newValue && newValue !== "") {
                                    setCoupon(newValue);
                                  }
                                }}
                                value={coupon || ""}
                              />
                              <Button
                                onClick={(e) =>
                                  handleEditSubmit("couponEdit", e)
                                }
                                isLoading={isQuoteLoading}
                              >
                                Save
                              </Button>
                            </Flex>
                          </FormControl>
                        )}
                      </Flex>
                    )}
                  </Box>

                  <Box
                    maxW={{ base: "100%", md: "320px" }}
                    w={{ base: "100%", md: "50%" }}
                    px={{ base: 3, md: 5 }}
                  >
                    <Flex
                      flexDirection={"column"}
                      pt={{ base: 1, md: 0 }}
                      px={{ base: 0, md: 5 }}
                    >
                      <Flex
                        fontSize={{ base: "14px", md: "16px" }}
                        fontWeight={500}
                      >
                        Price Details
                      </Flex>
                      <Flex
                        flexDirection={"column"}
                        fontSize={15}
                        color={"#666"}
                        lineHeight={{ base: 1.5, md: 2 }}
                      >
                        <Flex justifyContent={"space-between"} w="100%">
                          <Flex>Nightly Rates</Flex>
                          <Flex>
                            {nightlyRates.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })}
                          </Flex>
                        </Flex>
                        <Flex flexDirection="column" w="100%">
                          <Flex justifyContent={"space-between"} w="100%">
                            <Flex>
                              <Flex>Fees</Flex>
                              <Box
                                onClick={toggleFeesExpand}
                                aria-label={
                                  isFeesExpanded
                                    ? "Collapse Fees"
                                    : "Expand Fees"
                                }
                                width={8}
                                _hover={{ cursor: "pointer" }}
                                mt={"-1px"}
                                ml={2}
                              >
                                {isFeesExpanded ? (
                                  <ChevronUpIcon />
                                ) : (
                                  <ChevronDownIcon />
                                )}
                              </Box>
                            </Flex>
                            {totalFees && (
                              <Flex>
                                {totalFees.toLocaleString("en-US", {
                                  style: "currency",
                                  currency: "USD",
                                })}
                              </Flex>
                            )}
                          </Flex>
                          {isFeesExpanded && (
                            <Flex
                              flexDirection="column"
                              w="100%"
                              fontSize={"14px"}
                              color={"#00000080"}
                            >
                              {fees.map((item, index) => (
                                <Flex
                                  justifyContent={"space-between"}
                                  w="100%"
                                  key={`fee-${index}`}
                                  mt={1}
                                >
                                  <Flex>
                                    {item.title
                                      .toLowerCase()
                                      .replace(/_(.)/g, " $1")
                                      .replace(/^./, (str) =>
                                        str.toUpperCase()
                                      )}
                                  </Flex>
                                  <Flex>
                                    {item.amount.toLocaleString("en-US", {
                                      style: "currency",
                                      currency: "USD",
                                    })}
                                  </Flex>
                                </Flex>
                              ))}
                            </Flex>
                          )}
                          <Flex
                            justifyContent={"space-between"}
                            w="100%"
                            mt="0px"
                          >
                            <Flex>
                              <Flex>Taxes</Flex>
                              <Box
                                onClick={toggleTaxesExpand}
                                aria-label={
                                  isTaxesExpanded
                                    ? "Collapse Taxes"
                                    : "Expand Taxes"
                                }
                                width={8}
                                _hover={{ cursor: "pointer" }}
                                mt={"-1px"}
                                ml={2}
                              >
                                {isTaxesExpanded ? (
                                  <ChevronUpIcon />
                                ) : (
                                  <ChevronDownIcon />
                                )}
                              </Box>
                            </Flex>
                            {totalTaxes && (
                              <Flex>
                                {totalTaxes.toLocaleString("en-US", {
                                  style: "currency",
                                  currency: "USD",
                                })}
                              </Flex>
                            )}
                          </Flex>
                          {isTaxesExpanded && (
                            <Flex
                              flexDirection="column"
                              w="100%"
                              fontSize={"14px"}
                              color={"#00000080"}
                            >
                              {taxes.map((item, index) => (
                                <Flex
                                  justifyContent={"space-between"}
                                  w="100%"
                                  key={`tax-${index}`}
                                  mt={1}
                                >
                                  <Flex>
                                    {item.title
                                      .toLowerCase()
                                      .replace(/_(.)/g, " $1")
                                      .replace(/^./, (str) =>
                                        str.toUpperCase()
                                      )}
                                  </Flex>
                                  <Flex>
                                    {item.amount.toLocaleString("en-US", {
                                      style: "currency",
                                      currency: "USD",
                                    })}
                                  </Flex>
                                </Flex>
                              ))}
                            </Flex>
                          )}
                          {(coupon || rebookCoupon) && discount && (
                            <Flex
                              justifyContent={"space-between"}
                              w="100%"
                              mt="0px"
                            >
                              <Flex>
                                <Flex>Coupon</Flex>
                                <Box
                                  aria-label={"Coupon"}
                                  width={8}
                                  mt={"-1px"}
                                  ml={2}
                                ></Box>
                              </Flex>
                              {discount && (
                                <Flex>
                                  {discount.toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "USD",
                                  })}
                                </Flex>
                              )}
                            </Flex>
                          )}
                        </Flex>
                        <Divider my={1}></Divider>

                        <Flex
                          justifyContent={"space-between"}
                          w="100%"
                          fontWeight={"500"}
                          color={"dmNavy.500"}
                        >
                          {(daysToCheckin === 0 || daysToCheckin < 60) && (
                            <>
                              <Flex>Total Due Today</Flex>
                              <Flex>
                                {totalPrice.toLocaleString("en-US", {
                                  style: "currency",
                                  currency: "USD",
                                })}
                              </Flex>
                            </>
                          )}
                          {daysToCheckin >= 60 && (
                            <Flex direction={"column"} w="100%">
                              <Flex
                                justifyContent={"space-between"}
                                w="100%"
                                fontWeight={"500"}
                                color={"dmNavy.500"}
                              >
                                <Flex>Total</Flex>
                                <Flex>
                                  {totalPrice.toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "USD",
                                  })}
                                </Flex>
                              </Flex>
                              <Flex
                                justifyContent={"space-between"}
                                w="100%"
                                fontWeight={"500"}
                                color={"dmNavy.500"}
                                fontStyle={"italic"}
                              >
                                <Flex>Total Due Today</Flex>
                                <Flex>
                                  {Number(
                                    (totalPrice * 0.51).toFixed(2)
                                  ).toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "USD",
                                  })}
                                </Flex>
                              </Flex>
                            </Flex>
                          )}
                        </Flex>
                        <Flex
                          gap={3}
                          w={"100%"}
                          justifyContent={"start"}
                          mt={{ base: 1, md: 0 }}
                        >
                          <Link
                            fontSize="xs"
                            color={"dmNavy.500"}
                            textDecoration={"underline"}
                            onClick={onOpenTermsModal}
                          >
                            Payment Terms
                          </Link>
                        </Flex>

                        {/* Payment Terms Modal */}
                        <Modal
                          isOpen={isTermsModalOpen}
                          onClose={onCloseTermsModal}
                          size="2xl"
                        >
                          <ModalOverlay />

                          <ModalContent position="relative">
                            <ModalHeader>
                              <ModalCloseButton />
                            </ModalHeader>
                            <ModalBody mt={0} mb={4} textAlign={"start"}>
                              <Text fontWeight={"bold"} my={2} fontSize={"lg"}>
                                Payment Terms
                              </Text>
                              <Text>
                                If booking with more than 60 days to check in,
                                51% of the payment will be charged upon booking.
                                The remaining 49% will be charged 60 days prior
                                to check in. If booking with less than 60 days
                                to check in, the full amount will be charged
                                upon booking.
                              </Text>
                              {numNights && numNights >= 29 ? (
                                <>
                                  <Text
                                    fontWeight={"bold"}
                                    my={2}
                                    pt={3}
                                    fontSize={"lg"}
                                  >
                                    Long Term Stay Cancellation Policy
                                  </Text>
                                  <Text>
                                    If you book 29 or more nights, you will have
                                    48 hours to cancel penalty free. If you
                                    cancel at any point prior to 30 days before
                                    check in, you will be charged a cancelation
                                    fee of 50% of the room rate. If you cancel
                                    with 29 or less days to check in, you
                                    forfeit all monies paid.
                                  </Text>
                                </>
                              ) : (
                                <>
                                  <Text
                                    fontWeight={"bold"}
                                    my={2}
                                    pt={3}
                                    fontSize={"lg"}
                                  >
                                    Standard Cancellation Policy
                                  </Text>
                                  <Text>
                                    After booking, you have 48 hours to cancel
                                    penalty free. If canceling after 48 hours
                                    and 30 days prior to check in, you will
                                    receive a full refund less 25% of the
                                    nightly rate. If you cancel with 29 or less
                                    days to check in, you forfeit all monies
                                    paid.
                                  </Text>
                                  <Text pt={3}>
                                    Any guest booking more than 28 nights on any booking platform needs to adhere to the long term stay cancellation policy: Cancellations received 48 hours after booking and 30 days prior to arrival have a 50% cancellation fee. Cancellations that occur less than 30 days from the check-in date will result in forfeiture of the entire rental amount. No exceptions.
                                  </Text>
                                </>
                              )}
                              {houseData &&
                                houseData.tags.includes("Dog Friendly") && (
                                  <>
                                    <Text
                                      fontWeight={"bold"}
                                      my={2}
                                      pt={3}
                                      fontSize={"lg"}
                                    >
                                      Dogs
                                    </Text>
                                    <Text>
                                      If you are booking a pet friendly home,
                                      you will have the opportunity to add and
                                      pay for your dogs after you complete your
                                      booking.
                                    </Text>
                                  </>
                                )}
                            </ModalBody>
                          </ModalContent>
                        </Modal>
                        {/* END Payment Terms Modal */}
                      </Flex>
                    </Flex>
                  </Box>
                </Flex>
              ) : (
                <>
                  {/* Edit Dates and Guests */}
                  <Flex
                    flexDirection={{ base: "column", md: "row" }}
                    w="100"
                    pt={2}
                    pb={4}
                    maxW={{ base: "100vw", md: "1440px" }}
                    px={3}
                  >
                    <Box w={"100%"}>
                      {/* Dates Input */}
                      <FormControl
                        h={"50px"}
                        w="100%"
                        fontSize={16}
                        border={"1px"}
                        borderColor={"black"}
                        inputMode="none"
                      >
                        <Flex
                          color={"black"}
                          height={"40px"}
                          w={"100%"}
                          h={"100%"}
                          className="mobile-menu-date-picker"
                        >
                          <DatePicker
                            placeholderText="Select Dates"
                            h={"100%"}
                            isClearable={true}
                            selectsRange={true}
                            startDate={checkoutDateRange[0]}
                            endDate={checkoutDateRange[1]}
                            onChange={(update) => {
                              //setContextDateRange(update)
                              console.log("update", update);
                              //setCheckoutDateRange(update)
                              console.log(
                                "checkoutDateRange",
                                checkoutDateRange
                              );

                              if (update[0]) {
                                setCheckoutStartDate(
                                  formatDateToYYYYMMDD(update[0])
                                );
                                setCheckoutDateRange([update[0], update[1]]);
                                console.log(
                                  "checkoutStartDate",
                                  checkoutStartDate,
                                  "checkoutEndDate",
                                  checkoutEndDate
                                );
                              }
                              if (update[1]) {
                                setCheckoutEndDate(
                                  formatDateToYYYYMMDD(update[1])
                                );
                                setCheckoutDateRange([update[0], update[1]]);
                                console.log(
                                  "checkoutStartDate",
                                  checkoutStartDate,
                                  "checkoutEndDate",
                                  checkoutEndDate
                                );
                              }

                              // Gray out bad check in dates either: (1) on first load (when both are null) *or* (2) when both are NOT null
                              // (So the user can't click on a bad check in date when a date range is already selected)
                              const grayOutBadCheckInDates =
                                (update[0] === null && update[1] === null) ||
                                (update[0] !== null && update[1] !== null);
                              // Gray out bad check out dates when a check out date hasn't been selected yet
                              const grayOutBadCheckOutDates =
                                update[0] !== null && update[1] === null;

                              if (grayOutBadCheckOutDates) {
                                setCurrentlyShowing("checkOut");
                                // Set the datepicker to gray out dates that can't be checked OUT
                                // First, find which dates ARE okay to check out for the user-selected check in date (update[0])
                                const datesOkayToCheckOut = processCheckOuts(
                                  update[0],
                                  guestyData
                                );
                                //console.log("datesOkayToCheckOut", datesOkayToCheckOut)
                                // Create an array of all the dates NOT in that array (ie: the ones that are NOT okay to check out)
                                const datesNotOkayToCheckOut = guestyData
                                  .filter(
                                    (date) =>
                                      !datesOkayToCheckOut.includes(
                                        date.startDate
                                      )
                                  )
                                  .map((date) => date.startDate);
                                //console.log("datesNotOkayToCheckOut", datesNotOkayToCheckOut)
                                // Set those dates (the ones NOT okay to check out) to be grayed out
                                setDatesToGrayOut(datesNotOkayToCheckOut);

                                // We have to create a new array to find the dates that are booked AND not okay to check out because datesNotOkayToCheckOut is dates only, do extra data
                                const datesNotOkayToCheckOutWithExtraData =
                                  guestyData.filter(
                                    (date) =>
                                      !datesOkayToCheckOut.includes(
                                        date.startDate
                                      )
                                  );
                                const getBookedAndNotOkayToCheckOutDates =
                                  datesNotOkayToCheckOutWithExtraData
                                    .filter((date) => date.status === "booked")
                                    .map((date) => Date.parse(date.startDate));
                                setBookedAndNotOkayToCheckOutDates(
                                  getBookedAndNotOkayToCheckOutDates
                                );
                              } else if (grayOutBadCheckInDates) {
                                setCurrentlyShowing("checkIn");
                                // Set the datepicker to gray out dates that can't be checked IN (basically same thing as what it does onload)
                                setDatesToGrayOut(datesNotOkayToCheckIn);
                              }
                            }}
                            dayClassName={(date) => {
                              // If a check in date has NOT been picked yet, set ALL booked dates to hash background
                              // If a check in date HAS been picked (so we're currently showing check out dates), only set booked dates that are *also* NOT okay to check out on to hash background
                              if (currentlyShowing === "checkIn")
                                return bookedDates.includes(Date.parse(date))
                                  ? "datepicker-date-booked"
                                  : undefined;
                              if (currentlyShowing === "checkOut")
                                return bookedAndNotOkayToCheckOutDates.includes(
                                  Date.parse(date)
                                )
                                  ? "datepicker-date-booked"
                                  : undefined;
                            }}
                            excludeDates={datesToGrayOut}
                            includeDateIntervals={[
                              {
                                start: new Date(),
                                end: addDays(new Date(), 366),
                              },
                            ]}
                          />
                        </Flex>
                      </FormControl>
                      {/* END Dates Input */}

                      {/* Guests Number Input */}
                      <FormControl height={"50px"} w={"100%"} mt={4}>
                        <Flex
                          gap={2}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                        >
                          <Text ml={1}>Number of Guests</Text>
                          <Flex alignItems={"center"} gap={2}>
                            <Button
                              onClick={decrementGuestInput}
                              variant="outline"
                              borderColor="dmNavy.500"
                            >
                              -
                            </Button>
                            <Input
                              textAlign={"center"}
                              p="10px"
                              w="40px"
                              color="black"
                              type="number"
                              border="0"
                              onChange={(e) => {
                                const newValue = parseInt(e.target.value, 10);
                                if (
                                  !isNaN(newValue) &&
                                  newValue >= 1 &&
                                  newValue <= houseData.occupancy
                                ) {
                                  setGuests(newValue);
                                }
                              }}
                              value={guests || ""}
                            />
                            <Button
                              onClick={incrementGuestInput}
                              variant="outline"
                              borderColor="dmNavy.500"
                            >
                              +
                            </Button>
                          </Flex>
                        </Flex>
                      </FormControl>
                      {/* END Guests Number Input */}

                      {/* Coupon Input with Date/Guest Edit */}
                      <FormControl height={"50px"} w={"100%"} mt={4}>
                        <Flex
                          gap={2}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                        >
                          <Text ml={1}>Coupon Code</Text>
                          {errorMessage && errorMessage.includes("coupons") && (
                            <Text color="red">Invalid Coupon</Text>
                          )}
                          <Input
                            textAlign={"center"}
                            p="10px"
                            flexGrow={1}
                            maxW={"300px"}
                            color="black"
                            type="text"
                            border="solid 1px black"
                            onChange={(e) => {
                              const newValue = e.target.value;
                              if (newValue && newValue !== "") {
                                setCoupon(newValue);
                              }
                            }}
                            value={coupon || ""}
                          />
                        </Flex>
                      </FormControl>
                      {/* END Coupon Input */}

                      {/* Submit Button */}
                      <Flex gap={2}>
                        <Button
                          h={"50px"}
                          mt={6}
                          w={{ base: "100%", md: "100%" }}
                          onClick={(e) => handleEditSubmit("quoteEdit", e)}
                          colorScheme="dmOrange"
                          isLoading={isQuoteLoading}
                        >
                          Update Pricing
                        </Button>
                        <Button
                          h={"50px"}
                          mt={6}
                          w={{ base: "100%", md: "100%" }}
                          onClick={handleEditCancel}
                          colorScheme="dmOrange"
                          variant="outline"
                        >
                          Cancel
                        </Button>
                      </Flex>
                    </Box>
                  </Flex>
                  {/* End Dates and Guests Form */}
                </>
              )}
              {/* END Dates and Guests Section */}

              {/* Primary Guest Info */}
              <Flex
                px={{ base: 3, md: 5 }}
                flexDirection={"column"}
                pt={5}
                gap={3}
              >
                <Flex fontSize={{ base: "22px", md: "24px" }} fontWeight={500}>
                  Primary Guest Information
                </Flex>
                {/* Formik Form */}
                <Formik
                  innerRef={formikRef}
                  initialValues={{
                    firstName: "",
                    lastName: "",
                    email: "",
                    phone: "",
                    specialRequests: "",
                  }}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                  w={"100%"}
                >
                  {({
                    values,
                    isSubmitting,
                    isValidating,
                    handleBlur,
                    handleChange,
                  }) => (
                    <Form>
                      <ScrollToFieldError />
                      <Flex
                        direction="column"
                        width={"100%"}
                        maxWidth={{ base: "100vw", md: "768px" }}
                        pr={{ md: 2 }}
                        gap={4}
                        px={{ base: 0, md: 0 }}
                        mb={{ base: 4, md: 0 }}
                      >
                        <Flex direction={{ base: "column", md: "row" }} gap={2}>
                          <Field name="firstName">
                            {({ field, form }) => (
                              <FormControl
                                isInvalid={
                                  form.errors.firstName &&
                                  form.touched.firstName
                                }
                              >
                                <FormLabel htmlFor="firstName">
                                  First Name
                                </FormLabel>
                                <Input
                                  {...field}
                                  id="firstName"
                                  placeholder="First Name"
                                  borderRadius={0}
                                  border="solid 1px #D4D8E1"
                                  h="44px"
                                  onBlur={(e) => {
                                    handleBlur(e);
                                    handleFieldBlur(
                                      "firstName",
                                      e.target.value
                                    );
                                  }}
                                />
                                <FormErrorMessage>
                                  {form.errors.firstName}
                                </FormErrorMessage>
                              </FormControl>
                            )}
                          </Field>
                          <Field name="lastName">
                            {({ field, form }) => (
                              <FormControl
                                isInvalid={
                                  form.errors.lastName && form.touched.lastName
                                }
                              >
                                <FormLabel htmlFor="lastName">
                                  Last Name
                                </FormLabel>
                                <Input
                                  {...field}
                                  id="lastName"
                                  placeholder="Last Name"
                                  borderRadius={0}
                                  border="solid 1px #D4D8E1"
                                  h="44px"
                                  onBlur={(e) => {
                                    handleBlur(e);
                                    handleFieldBlur("lastName", e.target.value);
                                  }}
                                />
                                <FormErrorMessage>
                                  {form.errors.lastName}
                                </FormErrorMessage>
                              </FormControl>
                            )}
                          </Field>
                        </Flex>
                        <Flex direction={{ base: "column", md: "row" }} gap={2}>
                          <Field name="email">
                            {({ field, form }) => (
                              <FormControl
                                isInvalid={
                                  form.errors.email && form.touched.email
                                }
                              >
                                <FormLabel htmlFor="email">Email</FormLabel>
                                <Input
                                  {...field}
                                  id="email"
                                  placeholder="Email"
                                  borderRadius={0}
                                  border="solid 1px #D4D8E1"
                                  h="44px"
                                  className="bookingEmail"
                                  onBlur={(e) => {
                                    handleBlur(e);
                                    handleFieldBlur("email", e.target.value);
                                  }}
                                />
                                <FormErrorMessage>
                                  {form.errors.email}
                                </FormErrorMessage>
                              </FormControl>
                            )}
                          </Field>
                          <Field name="phone">
                            {({ field, form }) => (
                              <FormControl
                                isInvalid={
                                  form.errors.phone && form.touched.phone
                                }
                              >
                                <FormLabel htmlFor="phone">Phone</FormLabel>
                                <Input
                                  {...field}
                                  id="phone"
                                  placeholder="Phone"
                                  borderRadius={0}
                                  border="solid 1px #D4D8E1"
                                  h="44px"
                                  className="bookingPhone"
                                  onBlur={(e) => {
                                    handleBlur(e);
                                    handleFieldBlur("phone", e.target.value);
                                  }}
                                />
                                <FormErrorMessage>
                                  {form.errors.phone}
                                </FormErrorMessage>
                              </FormControl>
                            )}
                          </Field>
                        </Flex>
                      </Flex>
                    </Form>
                  )}
                </Formik>
                {/* END Formik Form */}
              </Flex>
              {/* END Primary Guest Info */}
            </Flex>

            {/* GuestyPay Payment Widget */}
            <Flex direction="column" w={"100%"} px={{ base: 3, md: 5 }}>
              <Flex
                pt={{ base: 0, md: 4 }}
                flexDirection={"column"}
                maxWidth={{ base: "100vw", md: "768px" }}
                pb={{ base: 12, md: 0 }}
              >
                <Flex fontSize={{ base: "22px", md: "24px" }} fontWeight={500}>
                  Payment Information
                </Flex>
                <Box
                  pt={5}
                  px={{ base: 0, md: 0 }}
                  id="guesty-tokenization-container"
                ></Box>
              </Flex>
              {/* Payment Submission Modal */}
              <Modal
                isOpen={isModalOpen}
                isCentered
                size="lg"
                onClose={() => {}}
              >
                <ModalOverlay />
                <ModalContent>
                  <Flex
                    direction="column"
                    align="center"
                    justify="center"
                    p={5}
                    mt={5}
                    textAlign={"center"}
                  >
                    {modalContent.isLoading ? <Spinner size="xl" /> : null}
                    <Text mt={4} mx={8}>
                      {modalContent.message}
                      {errorMessage !== "" && (
                        <Text as="span" color="red.500" fontWeight="bold">
                          <br />
                          <br />
                          {errorMessage}
                        </Text>
                      )}
                    </Text>
                  </Flex>
                  <Button
                    colorScheme={"dmOrange"}
                    onClick={() => {
                      if (shouldReturnToListing) {
                        returnToListingBlank(); // Call the function to return to listing
                      } else {
                        // Default action or simply close the modal
                        setIsModalOpen(false);
                        setIsBooking(false);
                      }
                    }}
                    isDisabled={modalContent.isLoading}
                    size={"lg"}
                    m={5}
                    w={"40%"}
                    alignSelf={"center"}
                  >
                    Continue
                  </Button>
                </ModalContent>
              </Modal>
            </Flex>
            {/* END GuestyPay Payment Widget */}
          </Flex>
          <Box
            position={{ base: "fixed", md: "unset" }}
            maxWidth="1440px"
            w={"100%"}
            bgColor={{ base: "dmNavy.500", md: "white" }}
            left={{ base: 0, md: "unset" }}
            boxShadow={{ base: "dmShadow", md: "none" }}
            bottom={{ base: 0, md: "unset" }}
            margin={"0 auto"}
            px={{ base: 5, md: 5 }}
          >
            <Button
              type="submit"
              colorScheme="dmOrange"
              mt={{ base: 5, md: 8 }}
              mb={{ base: 5, md: 20 }}
              h={"45px"}
              isLoading={isModalOpen}
              w={"100%"}
              onClick={() => formikRef.current?.submitForm()}
              isDisabled={editMode}
            >
              Book My Vacation
            </Button>
          </Box>
        </Box>
      ) : editMode ? (
        <Flex>Updating</Flex>
      ) : (
        <Flex
          position={"relative"}
          width={"full"}
          data-id={"checkout"}
          p={{ base: 2, md: 5 }}
          justifyContent={"center"}
          flexDirection={"column"}
          alignItems={"center"}
          minH={"calc(100vh - 236px)"}
        >
          {/* Top Bar */}
          <Flex
            maxWidth="1440px"
            width={"100%"}
            p={2}
            textAlign={"left"}
            alignItems={"center"}
            flexDirection={"column"}
          >
            Reservation quote has expired.
            <Button
              h={"50px"}
              mt={6}
              onClick={returnToListing}
              colorScheme="dmOrange"
            >
              {fromLocation && fromLocation.includes("quote") ? (
                <Text>Return to Quote</Text>
              ) : (
                <Text>Return to Listing</Text>
              )}
            </Button>
          </Flex>
        </Flex>
      )}
    </>
  );
};

export { Checkout };

