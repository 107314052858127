import {
  CheckCircleIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@chakra-ui/icons";
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  Image,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { Elements } from "@stripe/react-stripe-js";
import {
  Appearance,
  StripeElementsOptions,
  loadStripe,
} from "@stripe/stripe-js";
import { differenceInMonths, format } from "date-fns";
import addDays from "date-fns/addDays";
import isEqual from "lodash.isequal";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FiChevronLeft } from "react-icons/fi";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import PaymentForm from "../components/PaymentForm";
import config from "../config";
import { SearchResultsContext } from "../contexts/SearchResultsContext";
import { MAX_END_DATE } from "../helpers/constants";
import {
  fetchHouseCalendar,
  fetchHouseDetails,
  getReservationQuote,
  sendHubspotEvent,
} from "../helpers/fetch";
import {
  processCheckIns,
  processCheckOuts,
  transformGuestyData,
} from "../helpers/guestyService";
import {
  calculateDaysBetweenDates,
  calculateDaysToCheckin,
  createDateAtLocalMidnight,
  decrementGuestInput,
  formatDateToYYYYMMDD,
  incrementGuestInput,
  reformatDateString,
} from "../helpers/helpers";
import {
  fetchBookingPaymentIntent,
  getCheckOutById,
  getPaymentsBreakdown,
} from "../helpers/reservationService";

const stripePromise = loadStripe(config.stripeKey);

const appearance: Appearance = {
  theme: "stripe",
  variables: {
    colorPrimary: "#ed5f74",
    borderRadius: "20px",
    fontFamily: "sans-serif",
    colorBackground: "#fafafa",
  },
};

const amount = 100;

const CheckoutNew: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // House Number from URL
  let { houseNumber } = useParams();
  // Context Data
  const {
    contextDateRange,
    setContextDateRange,
    guests,
    setGuests,
    houseData,
    setHouseData,
    houseCalendar,
    setHouseCalendar,
  } = React.useContext(SearchResultsContext);
  // Stripe Client Secret from Backend
  const [clientSecret, setClientSecret] = useState<string | null>(null);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top when component mounts

    const fetchClientSecret = async () => {
      const response = await fetchBookingPaymentIntent(amount);
      const data = await response.json();
      setClientSecret(data.clientSecret);
    };

    fetchClientSecret();
  }, []);

  const stripeOptions: StripeElementsOptions = {
    clientSecret: clientSecret!,
    appearance,
  };

  /// Rebook Mode Variables ///
  const [rebookMode, setRebookMode] = React.useState(false);
  const [rebookParameterReservation, setRebookParameterReservation] =
    React.useState(null);
  const [rebookCoupon, setRebookCoupon] = React.useState(null);

  React.useEffect(() => {
    // Check localStorage to see if rebook mode is active
    const rebookParameter = localStorage.getItem("dm-rebook-mode");
    setRebookParameterReservation(
      localStorage.getItem("dm-rebook-reservation")
    );
    const oneDayInMilliseconds = 24 * 60 * 60 * 1000;
    const currentTime = Date.now();

    if (rebookParameter) {
      const rebookTimestamp = parseInt(rebookParameter, 10);
      if (currentTime - rebookTimestamp < oneDayInMilliseconds) {
        setRebookMode(true);
      }
    }
  }, []);

  React.useEffect(() => {
    const getRebookCheckoutDateAndCoupon = async () => {
      console.log("rebookParameterReservation", rebookParameterReservation);
      if (rebookParameterReservation === "stayfi") {
        setRebookCoupon("FJCP97YWVP9J");
        return;
      }
      if (rebookParameterReservation === "4be10cd6da4b41ee20492eab7bec109d") {
        setRebookCoupon("1KRYHEMOUFVN"); // 5
        return;
      }
      if (rebookParameterReservation === "2f206aa4d52f82d7ee10d01300aa59b7") {
        setRebookCoupon("DXDJJQDVJJID"); // 10
        return;
      }
      const response = await getCheckOutById(rebookParameterReservation);
      const data = await response.json();
      console.log("rebook data", data);

      if (data && data.checkoutDate) {
        const rebookCheckOutDate = data.checkoutDate; // Date String
        const timeDifference =
          new Date().getTime() - new Date(rebookCheckOutDate).getTime();
        const daysDifference = timeDifference / (1000 * 3600 * 24);
        console.log(
          "daysDifference",
          daysDifference,
          "timeDifference",
          timeDifference
        );
        if (daysDifference < 1) {
          setRebookCoupon("FJCP97YWVP9J"); // 12% (stayfi)
        } else if (daysDifference <= 14) {
          setRebookCoupon("DXDJJQDVJJID"); // 10%
        } else {
          setRebookCoupon("1KRYHEMOUFVN"); // 5%
        }
      }
    };
    if (rebookParameterReservation && rebookMode) {
      getRebookCheckoutDateAndCoupon();
    }
  }, [rebookParameterReservation]);

  /// END Rebook Mode Variables ///

  // Terms Modal Handler
  const {
    isOpen: isTermsModalOpen,
    onOpen: onOpenTermsModal,
    onClose: onCloseTermsModal,
  } = useDisclosure();

  //// Submit for Reservation Quote ////
  const [nightlyRates, setNightlyRates] = React.useState(null);
  const [taxesAndFees, setTaxesAndFees] = React.useState(null); // Array
  const [totalTaxesAndFees, setTotalTaxesAndFees] = React.useState(null); // Sum
  const [taxes, setTaxes] = React.useState(null);
  const [fees, setFees] = React.useState(null);
  const [totalPrice, setTotalPrice] = React.useState(null);
  const [reservationQuote, setReservationQuote] = React.useState(null);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [isQuoteLoading, setIsQuoteLoading] = React.useState(true);
  const [isBooking, setIsBooking] = React.useState(false);
  const [numNights, setNumNights] = React.useState(null);
  const [daysToCheckin, setDaysToCheckin] = React.useState(null);
  const [splitPaymentDetails, setSplitPaymentDetails] = React.useState(null);

  const [shouldReturnToListing, setShouldReturnToListing] =
    React.useState(false);
  const [fromLocation, setFromLocation] = React.useState(null);
  const [editMode, setEditMode] = React.useState(false);
  const [isTaxesExpanded, setIsTaxesExpanded] = React.useState(false);
  const [isFeesExpanded, setIsFeesExpanded] = React.useState(false);
  const toggleTaxesExpand = () => setIsTaxesExpanded(!isTaxesExpanded);
  const toggleFeesExpand = () => setIsFeesExpanded(!isFeesExpanded);
  const [totalTaxes, setTotalTaxes] = React.useState(null);
  const [totalFees, setTotalFees] = React.useState(null);

  const [coupon, setCoupon] = React.useState(null);
  const [discount, setDiscount] = React.useState(null);
  const [couponValidated, setCouponValidated] = React.useState(null);
  const [editCouponMode, setEditCouponMode] = React.useState(false);

  // Handling Cancel Edit Quote
  const [oldDates, setOldDates] = React.useState([null, null]);
  const [oldCoupon, setOldCoupon] = React.useState(null);
  const [oldQuote, setOldQuote] = React.useState(null);

  const [checkoutStartDate, setCheckoutStartDate] = React.useState(
    reservationQuote ? reservationQuote.checkInDateLocalized : null
  );
  const [checkoutEndDate, setCheckoutEndDate] = React.useState(
    reservationQuote ? reservationQuote.checkOutDateLocalized : null
  );
  const [checkoutDateRange, setCheckoutDateRange] = React.useState([
    null,
    null,
  ]);

  /// Abandon Checkout Handling

  const [sessionId] = React.useState(uuidv4());

  const [abandonCheckoutData, setAbandonCheckoutData] = React.useState({
    firstName: "", // String
    lastName: "", // String
    email: "", // String
    phone: "", // String
    houseNumber: houseNumber, // String
    checkinDate: checkoutStartDate || null, // String or null
    checkoutDate: checkoutEndDate || null, // String or null
    guests: guests || null, // Integer or null
    coupon: coupon || null, // String or null
    totalPrice: totalPrice || null, // Float or null
    sessionId: sessionId, // String generated by uuidv4(), ex: 7d1669f1-05e5-4aa9-8669-1a19cb4d0662
  });

  const prevAbandonCheckoutData = React.useRef(abandonCheckoutData);

  const getHubspotUTK = () => {
    const cookies = document.cookie.split(";");
    const hubspotCookie = cookies.find((cookie) =>
      cookie.trim().startsWith("hubspotutk=")
    );
    return hubspotCookie ? hubspotCookie.split("=")[1] : null;
  };

  useEffect(() => {
    const hasChanged = !isEqual(
      abandonCheckoutData,
      prevAbandonCheckoutData.current
    );

    if (hasChanged) {
      prevAbandonCheckoutData.current = abandonCheckoutData;

      if (
        abandonCheckoutData.firstName ||
        abandonCheckoutData.lastName ||
        abandonCheckoutData.email ||
        abandonCheckoutData.phone ||
        abandonCheckoutData.checkinDate ||
        abandonCheckoutData.checkoutDate ||
        abandonCheckoutData.guests ||
        abandonCheckoutData.coupon ||
        abandonCheckoutData.totalPrice
      ) {
        const hubspotUTK = getHubspotUTK();
        const eventName = "pe21053466_checkout_form_initiated";
        const properties = {
          firstname: abandonCheckoutData.firstName,
          lastname: abandonCheckoutData.lastName,
          email: abandonCheckoutData.email,
          phone: abandonCheckoutData.phone,
          housenumber: abandonCheckoutData.houseNumber,
          checkindate: abandonCheckoutData.checkinDate,
          checkoutdate: abandonCheckoutData.checkoutDate,
          guests: abandonCheckoutData.guests,
          coupon: abandonCheckoutData.coupon,
          totalprice: abandonCheckoutData.totalPrice,
          sessionid: abandonCheckoutData.sessionId,
        };

        const eventData = {
          eventName,
          properties,
          objectType: "contacts",
          email: abandonCheckoutData.email || undefined,
          utk: hubspotUTK || undefined,
        };

        if (abandonCheckoutData.email || abandonCheckoutData.phone) {
          // Fire and forget
          (async () => {
            try {
              await sendHubspotEvent(eventData);
            } catch (error) {
              console.error("Error sending HubSpot event:", error);
            }
          })();
        }
      }
      prevAbandonCheckoutData.current = abandonCheckoutData;
    }
  }, [abandonCheckoutData]);

  /// END Abandon Checkout Handling

  /// Load House Data if Not in Context Already
  const [houseLoading, setHouseLoading] = React.useState(true);
  const [calendarLoading, setCalendarLoading] = React.useState(true);

  const fetchHouseData = async () => {
    setHouseLoading(true);
    try {
      //console.log("Fetching data for", houseNumber);
      const params = { houseNumber: houseNumber };

      const response = await fetchHouseDetails(params);

      if (!response.ok) {
        throw new Error(`fetchHouseDetails Error! Status: ${response.status}`);
      }

      const returnedHouseData = await response.json();
      setHouseData(returnedHouseData.results);
      //console.log("returnedHouseData", returnedHouseData.results)
    } catch (error) {
      console.error("Error fetching house data from DB:", error);
    } finally {
      setHouseLoading(false);
      //console.log("returnedHouseData", houseData);
    }
  };

  React.useEffect(() => {
    if (houseData) {
      setHouseLoading(false);
      return;
    } else {
      if (!guests) {
        setGuests(2);
      }
      fetchHouseData();
    }
  }, [houseNumber]);

  const fetchHouseAvailability = async () => {
    try {
      const startDate = format(new Date(), "yyyy-MM-dd"); // Today's date
      const endDate = MAX_END_DATE; // End of 2025

      setCalendarLoading(true);
      //console.log("Fetching house availability for", houseData.guestyHouseId);

      const response = await fetchHouseCalendar(
        houseData.guestyHouseId,
        startDate,
        endDate
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const availabilityData = await response.json();
      // Set the state or handle the data as needed
      //console.log("availabilityData", availabilityData)
      setHouseCalendar(availabilityData);
    } catch (error) {
      console.error("Error fetching house availability:", error);
    } finally {
      setCalendarLoading(false);
    }
  };

  React.useEffect(() => {
    if (houseCalendar) {
      setCalendarLoading(false);
      return;
    } else {
      if (houseData && houseData.guestyHouseId) {
        fetchHouseAvailability();
      }
    }
  }, [houseData]);

  /// END Load House Data if Not in Context Already

  /// Get Payment Date Split ///
  const getPaymentsSplit = async (data) => {
    try {
      const response = await getPaymentsBreakdown(data);

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const paymentSplit = await response.json();

      setSplitPaymentDetails(paymentSplit);
      console.log("paymentSplit", paymentSplit);
    } catch (error) {
      console.error("Error fetching payment split:", error);
    }
  };
  /// END Get Payment Date Split ///

  // Function to Process Reservation Quote on Page Load and on Date/Guests Edit
  function processReservationQuote(reservationQuote) {
    console.log("isQuoteLoading", isQuoteLoading);
    const rates = reservationQuote.rates.ratePlans[0].ratePlan.money;

    setNightlyRates(rates.fareAccommodation);

    const calculatedTaxesAndFees = rates.invoiceItems.filter(
      (item) => item.type !== "ACCOMMODATION_FARE" && item.type !== "DISCOUNT"
    );

    setTaxesAndFees(calculatedTaxesAndFees);

    const taxItems = calculatedTaxesAndFees.filter((item) =>
      item.title.toLowerCase().includes("tax")
    );
    const feeItems = calculatedTaxesAndFees.filter((item) =>
      item.title.toLowerCase().includes("fee")
    );

    setTaxes(taxItems);
    setFees(feeItems);

    const totalTaxes = taxItems.reduce((acc, item) => acc + item.amount, 0);
    const totalFees = feeItems.reduce((acc, item) => acc + item.amount, 0);

    const discountItemAmount = rates.invoiceItems.find(
      (item) => item.type === "DISCOUNT"
    )?.amount;
    if (discountItemAmount) {
      setDiscount(discountItemAmount);
    }

    setTotalTaxesAndFees(totalTaxes + totalFees);
    const localTotalPrice =
      rates.fareAccommodation +
      totalTaxes +
      totalFees +
      (discountItemAmount || 0);
    setTotalPrice(localTotalPrice);
    setTotalTaxes(totalTaxes);
    setTotalFees(totalFees);

    console.log("Reservation Quote:", reservationQuote);
    console.log(
      "Quote Breakdown:",
      "nightlyRates",
      rates.fareAccommodation,
      "taxesAndFees",
      totalTaxes + totalFees,
      "totalPrice",
      rates.fareAccommodation + totalTaxes + totalFees,
      "quoteId",
      reservationQuote._id,
      "reservationQuote.rates.ratePlans[0].ratePlan._id",
      reservationQuote.rates.ratePlans[0].ratePlan._id
    );
    console.log(
      "reservation quote",
      reservationQuote,
      "houseData",
      houseData,
      "editMode",
      editMode
    );

    setNumNights(
      calculateDaysBetweenDates(
        reservationQuote.checkInDateLocalized,
        reservationQuote.checkOutDateLocalized
      )
    );
    //console.log('numNights',calculateDaysBetweenDates(reservationQuote.checkInDateLocalized,reservationQuote.checkOutDateLocalized));

    setDaysToCheckin(
      calculateDaysToCheckin(reservationQuote.checkInDateLocalized)
    );
    console.log(
      "days to checkin:",
      calculateDaysToCheckin(reservationQuote.checkInDateLocalized)
    );

    const paymentSplitData = {
      amount: localTotalPrice,
      checkIn: reservationQuote.checkInDateLocalized,
    };

    getPaymentsSplit(paymentSplitData);

    //console.log('coupon',coupon,'discount',discountItemAmount)

    setCheckoutStartDate(reservationQuote.checkInDateLocalized);
    setCheckoutEndDate(reservationQuote.checkOutDateLocalized);
    setAbandonCheckoutData((prev) => ({
      ...prev,
      checkinDate: reservationQuote.checkInDateLocalized,
      checkoutDate: reservationQuote.checkOutDateLocalized,
      guests: reservationQuote.guestsCount,
      coupon: coupon,
      totalPrice:
        rates.fareAccommodation +
        totalTaxes +
        totalFees +
        (discountItemAmount || 0),
    }));
    setAbandonCheckoutData["checkoutDate"] =
      reservationQuote.checkOutDateLocalized;
    setCheckoutDateRange([
      createDateAtLocalMidnight(reservationQuote.checkInDateLocalized),
      createDateAtLocalMidnight(reservationQuote.checkOutDateLocalized),
    ]);

    if (
      localTotalPrice <= 300 ||
      differenceInMonths(
        new Date(reservationQuote.checkInDateLocalized),
        new Date()
      ) < 7
    ) {
      navigate(`/${houseNumber}/checkout`, {
        state: { reservationQuote: reservationQuote },
      });
    }
  }

  // Receive Initial Reservation Quote from Listing.tsx

  React.useEffect(() => {
    if (location.state?.reservationQuote) {
      setReservationQuote(location.state.reservationQuote);
      processReservationQuote(location.state.reservationQuote);
    }
    setIsQuoteLoading(false);
    if (location.state?.houseData) {
      setHouseData(location.state.houseData);
    }
    if (location.state?.guests) {
      setGuests(location.state.guests);
    }
    if (location.state?.fromLocation) {
      setFromLocation(location.state.fromLocation);
    }
    console.log("location.state", location.state);
  }, [location.state]);

  // Edit Date/Guests
  function editQuote() {
    setOldDates([checkoutStartDate, checkoutEndDate]);
    setOldCoupon(coupon);
    setOldQuote(reservationQuote);
    setEditMode(true);
  }

  // Handler for Checkout Edit, Generate New Quote
  const handleEditSubmit = async (
    action: string,
    e?: React.MouseEvent<HTMLButtonElement>
  ) => {
    if (e) e.preventDefault();

    if (action !== "couponEdit") {
      setEditCouponMode(true);
    } else {
      setEditMode(true);
    }
    setIsQuoteLoading(true);

    const oldQuote = reservationQuote; // Saving oldQuote for coupon error handling

    try {
      if (!houseData) {
        console.error("houseData is not available");
        return;
      }

      setErrorMessage(null);
      setNightlyRates(null);
      setTaxesAndFees(null);
      setTotalPrice(null);
      setErrorMessage(null);

      const formattedStartDate = format(checkoutDateRange[0], "yyyy-MM-dd");
      const formattedEndDate = format(checkoutDateRange[1], "yyyy-MM-dd");

      const listingId = houseData.guestyHouseId;

      const queryParams = {
        checkInDateLocalized: formattedStartDate,
        checkOutDateLocalized: formattedEndDate,
        listingId,
        guestsCount: guests,
        ...(coupon ? { coupons: coupon } : {}), // Conditionally add coupons only if coupon is truthy
      };

      // Fetch call to backend server
      const response = await getReservationQuote(queryParams);

      if (!response.ok) {
        const errorResponse = await response.json();
        throw new Error(
          errorResponse.error.message ||
            `HTTP error! Status: ${response.status}`
        );
      }
      // Process the response as needed
      const reservationQuote = await response.json();
      //console.log('Reservation Quote:', reservationQuote);
      processReservationQuote(reservationQuote);
      setReservationQuote(reservationQuote);
      setCouponValidated(true);
      setEditCouponMode(false);
    } catch (error) {
      console.error("Error:", error.message);
      setErrorMessage(error.message);

      if (error.message.includes("coupons")) {
        processReservationQuote(oldQuote);
        setReservationQuote(oldQuote);
        setCoupon(null);
        //console.log("coupon error")
      } else {
        setReservationQuote(null);
        //console.log("non-coupon error")
      }
    } finally {
      setEditMode(false);
      setIsQuoteLoading(false);
    }
  };

  // Edit Cancel
  const handleEditCancel = () => {
    //console.log(oldDates);
    //setContextDateRange(oldDates);
    setCheckoutStartDate(oldDates[0]);
    setCheckoutEndDate(oldDates[1]);
    setCoupon(oldCoupon);
    setReservationQuote(oldQuote);
    setEditMode(false);
  };

  // Return to Listing Page with Search URL Params
  const returnToListing = async () => {
    if (fromLocation && fromLocation.includes("quote")) {
      navigate(`${fromLocation}`);
      return;
    }

    // Serialize parameters into a query string
    const queryParams = new URLSearchParams();
    if (checkoutStartDate && checkoutEndDate) {
      const startDate = checkoutStartDate;
      const endDate = checkoutEndDate;
      queryParams.set("startdate", startDate);
      queryParams.set("enddate", endDate);
    }
    if (guests) {
      queryParams.set("guests", JSON.stringify(guests));
    }

    const queryString = queryParams.toString();
    const url = `/${houseNumber}?${queryString}`;
    //console.log("queryString",queryString)

    navigate(url);
  };

  // Return to Listing Page without Search URL Params
  const returnToListingBlank = async () => {
    const url = `/${houseNumber}`;
    window.open(url, "_self");
  };

  /// Form Abandonment Handling ///
  const handleFieldBlur = (field, value) => {
    setAbandonCheckoutData((prev) => ({ ...prev, [field]: value }));
  };

  React.useEffect(() => {
    return () => {
      if (
        abandonCheckoutData.firstName ||
        abandonCheckoutData.lastName ||
        abandonCheckoutData.email ||
        abandonCheckoutData.phone
      ) {
        // Handle the submission or storage of the abandon checkout data here
        console.log("Abandon Checkout Data:", abandonCheckoutData);
        // Example: Send the data to your backend or analytics service
        // sendAbandonCheckoutData(abandonCheckoutData);
      }
    };
  }, [abandonCheckoutData]);

  /// End Form Abandonment Handling ///

  //// Guesty Calendar Handling ////

  const [guestyData, setGuestyData] = React.useState([]);
  const [startDatePicker, endDatePicker] = contextDateRange;
  const [bookedDates, setBookedDates] = React.useState([]);
  const [datesNotOkayToCheckIn, setDatesNotOkayToCheckIn] = React.useState([]);
  const [datesToGrayOut, setDatesToGrayOut] = React.useState([]);
  const [bookedAndNotOkayToCheckOutDates, setBookedAndNotOkayToCheckOutDates] =
    React.useState([]);
  const [currentlyShowing, setCurrentlyShowing] = React.useState("checkIn");

  // This is to adjust the Guesty date data if needed to make sure it blanks out the right dates i the datepicker
  const hoursOffset = new Date().getTimezoneOffset() / 60;

  React.useEffect(() => {
    if (houseCalendar) {
      // Get the Guesty data on initial page load (should only do this once, all other changes to datepicker will be done via state changes)
      const guestyDataRaw = houseCalendar;
      // Transform the data to get rid of fields we don't need and alter dates if needed, saves a COPY to be used for Check Outs later
      const guestyDataTransformed = transformGuestyData(
        guestyDataRaw,
        hoursOffset
      );
      setGuestyData([...guestyDataTransformed]);
      // Save the dates that are booked to make them a different style/color in the datepicker
      const getBookedDates = guestyDataTransformed
        .filter((date) => date.status === "booked")
        .map((date) => Date.parse(date.startDate));
      setBookedDates(getBookedDates);
      // Add checkInOkay = true/false field to each date
      const guestyDataCheckInsProcessed = processCheckIns(
        guestyDataTransformed
      );
      // Create an array of all dates that have checkInOkay = false, then grab the startDate from each of them so it's just an array of dates for the datepicker
      const badCheckInDates = guestyDataCheckInsProcessed
        .filter((date) => !date.checkInOkay && date.startDate)
        .map((date) => date.startDate);
      // Set that array of dates to be grayed out
      setDatesToGrayOut(badCheckInDates);
      // (And save that array of dates so we can gray them again after user clicks an end date)
      setDatesNotOkayToCheckIn(badCheckInDates);
    }
  }, [houseCalendar]);

  //console.log('houseLoading', houseLoading, 'houseData', houseData, 'reservationQuote', reservationQuote, 'editMode', editMode, 'editCouponMode', editCouponMode)

  //// END Guesty Calendar Handling ////

  return clientSecret && reservationQuote ? (
    <Flex
      flexDirection={{ base: "column-reverse", md: "row" }}
      gap={{ base: 0, md: 6 }}
      p={{ base: 0, md: 3 }}
      maxW={"1480px"}
      margin={"0 auto"}
      alignItems={"flex-start"}
      minH={"calc(100vh - 236px)"}
    >
      {/* Left Panel */}
      <Flex
        flexDirection={"column"}
        w={"100%"}
        borderTop={{ base: "solid 10px #E9E9E9", md: "none" }}
      >
        <Flex
          maxWidth="1440px"
          width={"100%"}
          pt={{ base: 0, md: 5 }}
          px={{ base: 3, md: 5 }}
          textAlign={"left"}
          alignItems={"start"}
          flexDirection={"column"}
          margin={"0 auto"}
          display={{ base: "none", md: "flex" }}
        >
          <Flex
            alignItems={"center"}
            gap={2}
            pb={{ base: 0, md: 3 }}
            display={{ base: "flex", md: "flex" }}
          >
            <Box _hover={{ cursor: "pointer" }}>
              <FiChevronLeft onClick={returnToListing} />
            </Box>
            {fromLocation && fromLocation.includes("quote") ? (
              <Text>Return to Quote</Text>
            ) : (
              <Text>Return to Listing</Text>
            )}
          </Flex>
        </Flex>
        {houseData && (
          <Flex
            pt={5}
            px={{ base: 3, md: 5 }}
            w={"100%"}
            justifyContent={"center"}
          >
            <Elements stripe={stripePromise} options={stripeOptions}>
              <PaymentForm
                checkInDateLocalized={reservationQuote.checkInDateLocalized}
                checkOutDateLocalized={reservationQuote.checkOutDateLocalized}
                houseNumber={houseNumber}
                guestyHouseId={houseData.guestyHouseId}
                guests={guests}
                fareAccommodation={
                  reservationQuote.rates.ratePlans[0].ratePlan.money
                    .fareAccommodationAdjusted
                }
                fareCleaning={
                  reservationQuote.rates.ratePlans[0].ratePlan.money
                    .fareCleaning
                }
                rebookMode={rebookMode}
                coupon={rebookCoupon || coupon || null}
              />
            </Elements>
          </Flex>
        )}
      </Flex>
      {/* END Left Panel */}

      {/* Right Panel */}
      <Flex
        flexDirection={"column"}
        my={{ base: 0, md: 10 }}
        mx={{ base: 0, md: 10 }}
        w={{ base: "100%", md: "40%" }}
        border={{ base: "0", md: "solid 1px #CCC" }}
        borderRadius={"8px"}
        pb={{ base: 0, md: 5 }}
      >
        <Flex
          maxWidth="1440px"
          width={"100%"}
          pt={{ base: 3, md: 5 }}
          px={{ base: 3, md: 5 }}
          textAlign={"left"}
          alignItems={"start"}
          flexDirection={"column"}
          margin={"0 auto"}
          display={{ base: "flex", md: "none" }}
        >
          <Flex
            alignItems={"center"}
            gap={2}
            pb={{ base: 0, md: 3 }}
            display={{ base: "flex", md: "flex" }}
          >
            <Box _hover={{ cursor: "pointer" }}>
              <FiChevronLeft onClick={returnToListing} />
            </Box>
            <Text fontSize={"1.4em"}>Confirm and Pay</Text>
          </Flex>
        </Flex>
        {houseData && !houseLoading && !calendarLoading && (
          <>
            {/* House Summary */}
            <Flex
              width={{ base: "100%" }}
              py={{ base: 3, md: 2 }}
              px={{ base: 3, md: 5 }}
              textAlign={"left"}
              alignItems={"start"}
              flexDirection={"column"}
            >
              <Flex maxWidth="1440px" width={"100%"} pt={{ base: 1, md: 3 }}>
                <Flex
                  width={{ base: "150px", md: "250px" }}
                  textAlign={"left"}
                  alignItems={"start"}
                  flexDirection={"column"}
                >
                  <Image
                    src={houseData.pictures[0].original}
                    alt="Listing Image 1"
                    height="100%"
                    maxH={"180px"}
                    width="100%"
                    objectFit="cover"
                    borderRadius={8}
                  />
                </Flex>
                <Flex flexDirection={"column"} pl={5}>
                  {/* House Number */}
                  <Flex
                    fontSize={{ base: "16px", md: "20px" }}
                    fontWeight={500}
                    color={"#00000090"}
                    pt={{ base: 0, md: 0 }}
                    display={{ base: "none", md: "flex" }}
                  >
                    #{houseNumber}
                  </Flex>
                  {/* Listing Title */}
                  <Flex
                    fontSize={{ base: "16px", md: "20px" }}
                    mb={{ base: 0, md: 0 }}
                    fontWeight={500}
                  >
                    {houseData.title}
                  </Flex>
                  <Flex
                    fontSize={{ base: "14px", md: "16px" }}
                    mb={{ base: 0, md: 0 }}
                    color={"#00000090"}
                    mt={2}
                  >
                    {houseData.town}, MA
                  </Flex>
                  {/* Occupancy, Bedroom, Bathroom Icons */}
                  <Flex
                    className="listing-details"
                    fontSize={"12px"}
                    alignItems={"flex-start"}
                    mt={{ base: 1, md: 4 }}
                    px={1}
                    display={{ base: "none", md: "flex" }}
                  >
                    <Flex
                      flexDirection={"column"}
                      className="listing-occupancy"
                      alignItems={"center"}
                      width={"max-content"}
                      me={3}
                    >
                      <Image
                        src={"../media/occupancy.png"}
                        alt="Max Occupancy"
                        objectFit={"cover"}
                        align={"left"}
                        width={"25px"}
                        height={"25px"}
                        className="listing-occupancy-image"
                      />
                      <Text className="listing-occupancy-text">
                        {!isNaN(houseData.occupancy)
                          ? (houseData.occupancy as number)
                          : 0}{" "}
                        Guests
                      </Text>
                    </Flex>
                    <Flex
                      flexDirection={"column"}
                      className="listing-bedrooms"
                      alignItems={"center"}
                      width={"max-content"}
                      me={3}
                    >
                      <Image
                        src={"../media/bedrooms.png"}
                        alt="Bedrooms"
                        objectFit={"cover"}
                        align={"left"}
                        width={"25px"}
                        height={"25px"}
                        className="listing-bedrooms-image"
                      />
                      <Text className="listing-bedrooms-text">
                        {!isNaN(houseData.bedrooms)
                          ? (houseData.bedrooms as number)
                          : 0}{" "}
                        Bedrooms
                      </Text>
                    </Flex>
                    <Flex
                      flexDirection={"column"}
                      className="listing-bathrooms"
                      alignItems={"center"}
                      width={"max-content"}
                    >
                      <Image
                        src={"../media/bathrooms.png"}
                        alt="Bathrooms"
                        objectFit={"cover"}
                        align={"left"}
                        width={"25px"}
                        height={"25px"}
                        className="listing-bathrooms-image"
                      />
                      <Text className="listing-bathrooms-text">
                        {!isNaN(houseData.bathrooms)
                          ? (houseData.bathrooms as number)
                          : 0}{" "}
                        Bathrooms
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
            {/* End House Summary */}

            {/* Dates, Guests, Coupon */}
            <Flex w="100%">
              {(reservationQuote && houseData) ||
              (editMode && !editCouponMode) ? (
                <Box w={"100%"}>
                  <Flex
                    position={"relative"}
                    width={"100%"}
                    maxW={"1440px"}
                    data-id={"checkout"}
                    justifyContent={"start"}
                    flexDirection={{ base: "column", md: "row" }}
                    alignItems={"start"}
                    margin={"0 auto"}
                    gap={3}
                  >
                    <Flex direction="column" w={"100%"}>
                      {/* Dates and Guests Section */}
                      {!editMode && editCouponMode !== true ? (
                        <Flex
                          pt={{ base: 2, md: 2 }}
                          flexDirection={{ base: "column", md: "row" }}
                          maxW={{ base: "100vw", md: "1440px" }}
                          gap={3}
                        >
                          <Box
                            w={{ base: "100%", md: "100%" }}
                            px={{ base: 3, md: 5 }}
                          >
                            <Flex flexDirection={"column"}>
                              <Flex
                                fontSize={{ base: "14px", md: "16px" }}
                                fontWeight={500}
                              >
                                Dates
                              </Flex>
                              <Flex justifyContent={"space-between"}>
                                <Box fontSize={15} color={"#666"} pb={2}>
                                  {checkoutStartDate && checkoutEndDate
                                    ? `${reformatDateString(
                                        checkoutStartDate
                                      )} - ${reformatDateString(
                                        checkoutEndDate
                                      )}`
                                    : "Please select dates"}
                                </Box>
                                <Box
                                  onClick={editQuote}
                                  cursor={"pointer"}
                                  textDecoration={"underline"}
                                >
                                  edit
                                </Box>
                              </Flex>
                            </Flex>
                            <Flex
                              flexDirection={"column"}
                              pt={{ base: 1, md: 3 }}
                            >
                              <Flex
                                fontSize={{ base: "14px", md: "16px" }}
                                fontWeight={500}
                              >
                                Guests
                              </Flex>
                              <Flex justifyContent={"space-between"}>
                                <Box fontSize={15} color={"#666"} pb={2}>
                                  {guests
                                    ? `${guests} guests`
                                    : "Please select guests"}
                                </Box>
                                <Box
                                  onClick={editQuote}
                                  cursor={"pointer"}
                                  textDecoration={"underline"}
                                >
                                  edit
                                </Box>
                              </Flex>
                            </Flex>

                            {rebookCoupon && discount ? (
                              <Flex
                                flexDirection={"column"}
                                pt={{ base: 1, md: 3 }}
                              >
                                <Flex justifyContent={"space-between"} gap={2}>
                                  <Alert
                                    status="info"
                                    borderRadius="md"
                                    fontSize="md"
                                    mb={3}
                                    fontWeight={"normal"}
                                  >
                                    <AlertIcon />
                                    <span>
                                      Your rebooking discount code has been
                                      automatically applied. You are saving an
                                      additional ${(-discount).toFixed(2)} on
                                      this reservation.
                                    </span>
                                  </Alert>
                                </Flex>
                              </Flex>
                            ) : (
                              <Flex
                                flexDirection={"column"}
                                pt={{ base: 1, md: 3 }}
                              >
                                <Flex justifyContent={"space-between"} gap={2}>
                                  <Flex
                                    fontSize={{ base: "14px", md: "16px" }}
                                    fontWeight={500}
                                  >
                                    Coupon
                                  </Flex>
                                  {errorMessage &&
                                    errorMessage.includes("coupons") && (
                                      <Text color="red">Invalid Coupon</Text>
                                    )}
                                </Flex>
                                {coupon &&
                                coupon !== "" &&
                                couponValidated === true ? (
                                  <Flex justifyContent={"space-between"}>
                                    <Flex gap={2} pb={2}>
                                      <Box fontSize={15} color={"#666"}>
                                        {coupon ? coupon : ""}
                                      </Box>
                                      <CheckCircleIcon color="green" />
                                    </Flex>
                                    <Box
                                      onClick={editQuote}
                                      cursor={"pointer"}
                                      textDecoration={"underline"}
                                    >
                                      {coupon ? "edit" : "add"}
                                    </Box>
                                  </Flex>
                                ) : (
                                  <FormControl w={"100%"} mt={1}>
                                    <Flex
                                      gap={2}
                                      justifyContent={"space-between"}
                                      alignItems={"center"}
                                    >
                                      <Input
                                        textAlign={"center"}
                                        flexGrow={1}
                                        maxW={"300px"}
                                        color="black"
                                        type="text"
                                        border="solid 1px #D4D8E1"
                                        onChange={(e) => {
                                          setCouponValidated(false);
                                          const newValue = e.target.value;
                                          if (newValue && newValue !== "") {
                                            setCoupon(newValue);
                                          }
                                        }}
                                        value={coupon || ""}
                                      />
                                      <Button
                                        onClick={(e) =>
                                          handleEditSubmit("couponEdit", e)
                                        }
                                        isLoading={isQuoteLoading}
                                      >
                                        Save
                                      </Button>
                                    </Flex>
                                  </FormControl>
                                )}
                              </Flex>
                            )}
                          </Box>
                        </Flex>
                      ) : (
                        <>
                          {/* Edit Dates and Guests */}
                          <Flex
                            flexDirection={{ base: "column", md: "row" }}
                            w="100"
                            pt={2}
                            pb={4}
                            maxW={{ base: "100vw", md: "1440px" }}
                            px={3}
                          >
                            <Box w={"100%"}>
                              {/* Dates Input */}
                              <FormControl
                                h={"50px"}
                                w="100%"
                                fontSize={16}
                                border={"1px"}
                                borderColor={"black"}
                                inputMode="none"
                              >
                                <Flex
                                  color={"black"}
                                  height={"40px"}
                                  w={"100%"}
                                  h={"100%"}
                                  className="mobile-menu-date-picker"
                                >
                                  <DatePicker
                                    placeholderText="Select Dates"
                                    h={"100%"}
                                    isClearable={true}
                                    selectsRange={true}
                                    startDate={checkoutDateRange[0]}
                                    endDate={checkoutDateRange[1]}
                                    onChange={(update) => {
                                      //setContextDateRange(update)
                                      console.log("update", update);
                                      //setCheckoutDateRange(update)
                                      console.log(
                                        "checkoutDateRange",
                                        checkoutDateRange
                                      );

                                      if (update[0]) {
                                        setCheckoutStartDate(
                                          formatDateToYYYYMMDD(update[0])
                                        );
                                        setCheckoutDateRange([
                                          update[0],
                                          update[1],
                                        ]);
                                        console.log(
                                          "checkoutStartDate",
                                          checkoutStartDate,
                                          "checkoutEndDate",
                                          checkoutEndDate
                                        );
                                      }
                                      if (update[1]) {
                                        setCheckoutEndDate(
                                          formatDateToYYYYMMDD(update[1])
                                        );
                                        setCheckoutDateRange([
                                          update[0],
                                          update[1],
                                        ]);
                                        console.log(
                                          "checkoutStartDate",
                                          checkoutStartDate,
                                          "checkoutEndDate",
                                          checkoutEndDate
                                        );
                                      }

                                      // Gray out bad check in dates either: (1) on first load (when both are null) *or* (2) when both are NOT null
                                      // (So the user can't click on a bad check in date when a date range is already selected)
                                      const grayOutBadCheckInDates =
                                        (update[0] === null &&
                                          update[1] === null) ||
                                        (update[0] !== null &&
                                          update[1] !== null);
                                      // Gray out bad check out dates when a check out date hasn't been selected yet
                                      const grayOutBadCheckOutDates =
                                        update[0] !== null &&
                                        update[1] === null;

                                      if (grayOutBadCheckOutDates) {
                                        setCurrentlyShowing("checkOut");
                                        // Set the datepicker to gray out dates that can't be checked OUT
                                        // First, find which dates ARE okay to check out for the user-selected check in date (update[0])
                                        const datesOkayToCheckOut =
                                          processCheckOuts(
                                            update[0],
                                            guestyData
                                          );
                                        //console.log("datesOkayToCheckOut", datesOkayToCheckOut)
                                        // Create an array of all the dates NOT in that array (ie: the ones that are NOT okay to check out)
                                        const datesNotOkayToCheckOut =
                                          guestyData
                                            .filter(
                                              (date) =>
                                                !datesOkayToCheckOut.includes(
                                                  date.startDate
                                                )
                                            )
                                            .map((date) => date.startDate);
                                        //console.log("datesNotOkayToCheckOut", datesNotOkayToCheckOut)
                                        // Set those dates (the ones NOT okay to check out) to be grayed out
                                        setDatesToGrayOut(
                                          datesNotOkayToCheckOut
                                        );

                                        // We have to create a new array to find the dates that are booked AND not okay to check out because datesNotOkayToCheckOut is dates only, do extra data
                                        const datesNotOkayToCheckOutWithExtraData =
                                          guestyData.filter(
                                            (date) =>
                                              !datesOkayToCheckOut.includes(
                                                date.startDate
                                              )
                                          );
                                        const getBookedAndNotOkayToCheckOutDates =
                                          datesNotOkayToCheckOutWithExtraData
                                            .filter(
                                              (date) => date.status === "booked"
                                            )
                                            .map((date) =>
                                              Date.parse(date.startDate)
                                            );
                                        setBookedAndNotOkayToCheckOutDates(
                                          getBookedAndNotOkayToCheckOutDates
                                        );
                                      } else if (grayOutBadCheckInDates) {
                                        setCurrentlyShowing("checkIn");
                                        // Set the datepicker to gray out dates that can't be checked IN (basically same thing as what it does onload)
                                        setDatesToGrayOut(
                                          datesNotOkayToCheckIn
                                        );
                                      }
                                    }}
                                    dayClassName={(date) => {
                                      // If a check in date has NOT been picked yet, set ALL booked dates to hash background
                                      // If a check in date HAS been picked (so we're currently showing check out dates), only set booked dates that are *also* NOT okay to check out on to hash background
                                      if (currentlyShowing === "checkIn")
                                        return bookedDates.includes(
                                          Date.parse(date)
                                        )
                                          ? "datepicker-date-booked"
                                          : undefined;
                                      if (currentlyShowing === "checkOut")
                                        return bookedAndNotOkayToCheckOutDates.includes(
                                          Date.parse(date)
                                        )
                                          ? "datepicker-date-booked"
                                          : undefined;
                                    }}
                                    excludeDates={datesToGrayOut}
                                    includeDateIntervals={[
                                      {
                                        start: new Date(),
                                        end: addDays(new Date(), 366),
                                      },
                                    ]}
                                  />
                                </Flex>
                              </FormControl>
                              {/* END Dates Input */}

                              {/* Guests Number Input */}
                              <FormControl height={"50px"} w={"100%"} mt={4}>
                                <Flex
                                  gap={2}
                                  justifyContent={"space-between"}
                                  alignItems={"center"}
                                >
                                  <Text ml={1}>Number of Guests</Text>
                                  <Flex alignItems={"center"} gap={2}>
                                    <Button
                                      onClick={() =>
                                        decrementGuestInput(setGuests, guests)
                                      }
                                      variant="outline"
                                      borderColor="dmNavy.500"
                                    >
                                      -
                                    </Button>
                                    <Input
                                      textAlign={"center"}
                                      p="10px"
                                      w="40px"
                                      color="black"
                                      type="number"
                                      border="0"
                                      onChange={(e) => {
                                        const newValue = parseInt(
                                          e.target.value,
                                          10
                                        );
                                        if (
                                          !isNaN(newValue) &&
                                          newValue >= 1 &&
                                          newValue <= houseData.occupancy
                                        ) {
                                          setGuests(newValue);
                                        }
                                      }}
                                      value={guests || ""}
                                    />
                                    <Button
                                      onClick={() =>
                                        incrementGuestInput(
                                          setGuests,
                                          guests,
                                          houseData.occupancy
                                        )
                                      }
                                      variant="outline"
                                      borderColor="dmNavy.500"
                                    >
                                      +
                                    </Button>
                                  </Flex>
                                </Flex>
                              </FormControl>
                              {/* END Guests Number Input */}

                              {/* Coupon Input with Date/Guest Edit */}
                              <FormControl height={"50px"} w={"100%"} mt={4}>
                                <Flex
                                  gap={2}
                                  justifyContent={"space-between"}
                                  alignItems={"center"}
                                >
                                  <Text ml={1}>Coupon Code</Text>
                                  {errorMessage &&
                                    errorMessage.includes("coupons") && (
                                      <Text color="red">Invalid Coupon</Text>
                                    )}
                                  <Input
                                    textAlign={"center"}
                                    p="10px"
                                    flexGrow={1}
                                    maxW={"300px"}
                                    color="black"
                                    type="text"
                                    border="solid 1px black"
                                    onChange={(e) => {
                                      const newValue = e.target.value;
                                      if (newValue && newValue !== "") {
                                        setCoupon(newValue);
                                      }
                                    }}
                                    value={coupon || ""}
                                  />
                                </Flex>
                              </FormControl>
                              {/* END Coupon Input */}

                              {/* Submit Button */}
                              <Flex gap={2}>
                                <Button
                                  h={"50px"}
                                  mt={6}
                                  w={{ base: "100%", md: "100%" }}
                                  onClick={(e) =>
                                    handleEditSubmit("quoteEdit", e)
                                  }
                                  colorScheme="dmOrange"
                                  isLoading={isQuoteLoading}
                                >
                                  Update Pricing
                                </Button>
                                <Button
                                  h={"50px"}
                                  mt={6}
                                  w={{ base: "100%", md: "100%" }}
                                  onClick={handleEditCancel}
                                  colorScheme="dmOrange"
                                  variant="outline"
                                >
                                  Cancel
                                </Button>
                              </Flex>
                            </Box>
                          </Flex>
                          {/* End Dates and Guests Form */}
                        </>
                      )}
                      {/* END Dates and Guests Section */}
                    </Flex>
                  </Flex>
                </Box>
              ) : editMode ? (
                <Flex>Updating</Flex>
              ) : (
                <Flex
                  position={"relative"}
                  width={"full"}
                  data-id={"checkout"}
                  p={{ base: 2, md: 5 }}
                  justifyContent={"center"}
                  flexDirection={"column"}
                  alignItems={"center"}
                  minH={"calc(100vh - 236px)"}
                >
                  {/* Top Bar */}
                  <Flex
                    maxWidth="1440px"
                    width={"100%"}
                    p={2}
                    textAlign={"left"}
                    alignItems={"center"}
                    flexDirection={"column"}
                  >
                    Reservation quote has expired.
                    <Button
                      h={"50px"}
                      mt={6}
                      onClick={returnToListing}
                      colorScheme="dmOrange"
                    >
                      {fromLocation && fromLocation.includes("quote") ? (
                        <Text>Return to Quote</Text>
                      ) : (
                        <Text>Return to Listing</Text>
                      )}
                    </Button>
                  </Flex>
                </Flex>
              )}
            </Flex>
            {/* END Dates, Guests, Coupon */}

            {/* Price Details */}
            <Box w={"100%"} pt={2}>
              <Flex
                flexDirection={"column"}
                pt={{ base: 1, md: 3 }}
                px={{ base: 3, md: 5 }}
                pb={{ base: 5, md: 0 }}
              >
                <Flex fontSize={{ base: "14px", md: "16px" }} fontWeight={500}>
                  Price Details
                </Flex>
                <Flex
                  flexDirection={"column"}
                  fontSize={15}
                  color={"#666"}
                  lineHeight={{ base: 1.5, md: 2 }}
                >
                  <Flex justifyContent={"space-between"} w="100%">
                    <Flex>Nightly Rates</Flex>
                    <Flex>
                      {nightlyRates &&
                        nightlyRates.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })}
                    </Flex>
                  </Flex>
                  <Flex flexDirection="column" w="100%">
                    <Flex justifyContent={"space-between"} w="100%">
                      <Flex>
                        <Flex>Fees</Flex>
                        <Box
                          onClick={toggleFeesExpand}
                          aria-label={
                            isFeesExpanded ? "Collapse Fees" : "Expand Fees"
                          }
                          width={8}
                          _hover={{ cursor: "pointer" }}
                          mt={"-1px"}
                          ml={2}
                        >
                          {isFeesExpanded ? (
                            <ChevronUpIcon />
                          ) : (
                            <ChevronDownIcon />
                          )}
                        </Box>
                      </Flex>
                      {totalFees && (
                        <Flex>
                          {totalFees.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })}
                        </Flex>
                      )}
                    </Flex>
                    {isFeesExpanded && (
                      <Flex
                        flexDirection="column"
                        w="100%"
                        fontSize={"14px"}
                        color={"#00000080"}
                      >
                        {fees.map((item, index) => (
                          <Flex
                            justifyContent={"space-between"}
                            w="100%"
                            key={`fee-${index}`}
                            mt={1}
                          >
                            <Flex>
                              {item.title
                                .toLowerCase()
                                .replace(/_(.)/g, " $1")
                                .replace(/^./, (str) => str.toUpperCase())}
                            </Flex>
                            <Flex>
                              {item.amount.toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                              })}
                            </Flex>
                          </Flex>
                        ))}
                      </Flex>
                    )}
                    <Flex justifyContent={"space-between"} w="100%" mt="0px">
                      <Flex>
                        <Flex>Taxes</Flex>
                        <Box
                          onClick={toggleTaxesExpand}
                          aria-label={
                            isTaxesExpanded ? "Collapse Taxes" : "Expand Taxes"
                          }
                          width={8}
                          _hover={{ cursor: "pointer" }}
                          mt={"-1px"}
                          ml={2}
                        >
                          {isTaxesExpanded ? (
                            <ChevronUpIcon />
                          ) : (
                            <ChevronDownIcon />
                          )}
                        </Box>
                      </Flex>
                      {totalTaxes && (
                        <Flex>
                          {totalTaxes.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })}
                        </Flex>
                      )}
                    </Flex>
                    {isTaxesExpanded && (
                      <Flex
                        flexDirection="column"
                        w="100%"
                        fontSize={"14px"}
                        color={"#00000080"}
                      >
                        {taxes.map((item, index) => (
                          <Flex
                            justifyContent={"space-between"}
                            w="100%"
                            key={`tax-${index}`}
                            mt={1}
                          >
                            <Flex>
                              {item.title
                                .toLowerCase()
                                .replace(/_(.)/g, " $1")
                                .replace(/^./, (str) => str.toUpperCase())}
                            </Flex>
                            <Flex>
                              {item.amount.toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                              })}
                            </Flex>
                          </Flex>
                        ))}
                      </Flex>
                    )}
                    {(coupon || rebookCoupon) && discount && (
                      <Flex justifyContent={"space-between"} w="100%" mt="0px">
                        <Flex>
                          <Flex>Coupon</Flex>
                          <Box
                            aria-label={"Coupon"}
                            width={8}
                            mt={"-1px"}
                            ml={2}
                          ></Box>
                        </Flex>
                        {discount && (
                          <Flex>
                            {discount.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })}
                          </Flex>
                        )}
                      </Flex>
                    )}
                  </Flex>
                  <Divider my={1}></Divider>

                  <Flex
                    justifyContent={"space-between"}
                    w="100%"
                    fontWeight={"500"}
                    color={"dmNavy.500"}
                  >
                    {(daysToCheckin === 0 || daysToCheckin < 60) &&
                      !rebookMode && (
                        <>
                          <Flex>Total Due Today</Flex>
                          <Flex>
                            {totalPrice &&
                              totalPrice.toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                              })}
                          </Flex>
                        </>
                      )}
                    {daysToCheckin >= 60 && !rebookMode && (
                      <Flex direction={"column"} w="100%">
                        <Flex
                          justifyContent={"space-between"}
                          w="100%"
                          fontWeight={"500"}
                          color={"dmNavy.500"}
                        >
                          <Flex>Total</Flex>
                          <Flex>
                            {totalPrice &&
                              totalPrice.toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                              })}
                          </Flex>
                        </Flex>
                        <Flex
                          justifyContent={"space-between"}
                          w="100%"
                          fontWeight={"500"}
                          color={"dmNavy.500"}
                          fontStyle={"italic"}
                        >
                          <Flex>Total Due Today</Flex>
                          <Flex>
                            {totalPrice &&
                              Number(
                                (totalPrice * 0.51).toFixed(2)
                              ).toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                              })}
                          </Flex>
                        </Flex>
                      </Flex>
                    )}
                    {rebookMode && splitPaymentDetails && (
                      <Flex direction={"column"} w="100%" fontWeight={"300"}>
                        <Flex
                          justifyContent={"space-between"}
                          w="100%"
                          fontWeight={"500"}
                          color={"dmNavy.500"}
                        >
                          <Flex>Total</Flex>
                          <Flex>
                            {totalPrice &&
                              totalPrice.toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                              })}
                          </Flex>
                        </Flex>
                        <Flex
                          justifyContent={"space-between"}
                          w="100%"
                          color={"dmNavy.500"}
                          fontStyle={"italic"}
                        >
                          <Flex>Rebook Deposit Due Today</Flex>
                          <Flex>
                            {Number((300).toFixed(2)).toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })}
                          </Flex>
                        </Flex>
                        <Flex
                          justifyContent={"space-between"}
                          w="100%"
                          color={"dmNavy.500"}
                          fontStyle={"italic"}
                        >
                          <Flex>
                            Due on{" "}
                            {reformatDateString(
                              splitPaymentDetails[1].payAt.split("T")[0]
                            )}
                          </Flex>
                          <Flex>
                            {Number(
                              splitPaymentDetails[1].amount.toFixed(2)
                            ).toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })}
                          </Flex>
                        </Flex>
                        <Flex
                          justifyContent={"space-between"}
                          w="100%"
                          color={"dmNavy.500"}
                          fontStyle={"italic"}
                        >
                          <Flex>
                            Due on{" "}
                            {reformatDateString(
                              splitPaymentDetails[2].payAt.split("T")[0]
                            )}
                          </Flex>
                          <Flex>
                            {Number(
                              splitPaymentDetails[2].amount.toFixed(2)
                            ).toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })}
                          </Flex>
                        </Flex>
                      </Flex>
                    )}
                  </Flex>
                  <Flex
                    gap={3}
                    w={"100%"}
                    justifyContent={"start"}
                    mt={{ base: 1, md: 0 }}
                  >
                    <Link
                      fontSize="xs"
                      color={"dmNavy.500"}
                      textDecoration={"underline"}
                      onClick={onOpenTermsModal}
                    >
                      Payment Terms
                    </Link>
                  </Flex>

                  {/* Payment Terms Modal */}
                  <Modal
                    isOpen={isTermsModalOpen}
                    onClose={onCloseTermsModal}
                    size={{ base: "full", md: "2xl" }}
                  >
                    <ModalOverlay />

                    <ModalContent position="relative">
                      <ModalHeader>
                        <ModalCloseButton />
                      </ModalHeader>
                      {rebookMode ? (
                        <ModalBody mt={0} mb={4} textAlign={"start"}>
                          <Text fontWeight={"bold"} my={2} fontSize={"lg"}>
                            Payment Terms
                          </Text>
                          <Text>
                            When reservation check in date is 7+ months ahead,
                            and after previous check out date, the payment
                            schedule is as follows:
                          </Text>
                          <Text pt={3}>
                            $300 due at booking (non-refundable).
                          </Text>
                          <Text pt={3}>
                            50% of remaining total due 180 days prior to check
                            in.
                          </Text>
                          <Text pt={3}>
                            Remaining balance due 60 days prior to check in.
                          </Text>
                          <Text
                            fontWeight={"bold"}
                            my={2}
                            fontSize={"lg"}
                            pt={4}
                          >
                            Cancellation
                          </Text>
                          <Text>
                            If a reservation is canceled with 180+ days prior to
                            check in, the cancellation fee is $300. If a
                            reservation is canceled between 179 and 30 days
                            prior to check in, the cancellation fee is 25% of
                            the total cost of the reservation. If the
                            reservation is canceled with less than 29 days to
                            check in, all monies paid are forfeited. Up to 30
                            days prior to check in, the reservation dates or
                            home can be modified for no additional fee, only the
                            difference in price between dates/homes will be
                            charged/credited.
                          </Text>
                          <Text pt={3}>
                            Any guest booking more than 28 nights on any booking platform needs to adhere to the long term stay cancellation policy: Cancellations received 48 hours after booking and 30 days prior to arrival have a 50% cancellation fee. Cancellations that occur less than 30 days from the check-in date will result in forfeiture of the entire rental amount. No exceptions.
                          </Text>
                          <Text pt={3}>
                            If this home's availability changes for any reason,
                            you will be offered a full refund, or relocated to
                            another similar home of your choosing.
                          </Text>
                        </ModalBody>
                      ) : (
                        <ModalBody mt={0} mb={4} textAlign={"start"}>
                          <Text fontWeight={"bold"} my={2} fontSize={"lg"}>
                            Payment Terms
                          </Text>
                          <Text>
                            If booking with more than 60 days to check in, 51%
                            of the payment will be charged upon booking. The
                            remaining 49% will be charged 60 days prior to check
                            in. If booking with less than 60 days to check in,
                            the full amount will be charged upon booking.
                          </Text>
                          {numNights && numNights >= 29 ? (
                            <>
                              <Text
                                fontWeight={"bold"}
                                my={2}
                                pt={3}
                                fontSize={"lg"}
                              >
                                Long Term Stay Cancellation Policy
                              </Text>
                              <Text>
                                If you book 29 or more nights, you will have 48
                                hours to cancel penalty free. If you cancel at
                                any point prior to 30 days before check in, you
                                will be charged a cancelation fee of 50% of the
                                room rate. If you cancel with 29 or less days to
                                check in, you forfeit all monies paid.
                              </Text>
                            </>
                          ) : (
                            <>
                              <Text
                                fontWeight={"bold"}
                                my={2}
                                pt={3}
                                fontSize={"lg"}
                              >
                                Standard Cancellation Policy
                              </Text>
                              <Text>
                                After booking, you have 48 hours to cancel
                                penalty free. If canceling after 48 hours and 30
                                days prior to check in, you will receive a full
                                refund less 25% of the nightly rate. If you
                                cancel with 29 or less days to check in, you
                                forfeit all monies paid.
                              </Text>
                            </>
                          )}
                          {houseData &&
                            houseData.tags.includes("Dog Friendly") && (
                              <>
                                <Text
                                  fontWeight={"bold"}
                                  my={2}
                                  pt={3}
                                  fontSize={"lg"}
                                >
                                  Dogs
                                </Text>
                                <Text>
                                  If you are booking a pet friendly home, you
                                  will have the opportunity to add and pay for
                                  your dogs after you complete your booking.
                                </Text>
                              </>
                            )}
                        </ModalBody>
                      )}
                    </ModalContent>
                  </Modal>
                  {/* END Payment Terms Modal */}
                </Flex>
              </Flex>
            </Box>
            {/* End Price Details */}
          </>
        )}
      </Flex>
      {/* END Right Panel */}
    </Flex>
  ) : calendarLoading || houseLoading || isQuoteLoading || !clientSecret ? (
    <Flex
      position={"relative"}
      width={"full"}
      data-id={"checkout"}
      p={{ base: 2, md: 5 }}
      justifyContent={"center"}
      flexDirection={"column"}
      alignItems={"center"}
      minH={"calc(100vh - 236px)"}
    >
      <Spinner size="xl" color="dmNavy.500" />
    </Flex>
  ) : (
    <Flex
      position={"relative"}
      width={"full"}
      data-id={"checkout"}
      p={{ base: 2, md: 5 }}
      justifyContent={"center"}
      flexDirection={"column"}
      alignItems={"center"}
      minH={"calc(100vh - 236px)"}
    >
      {/* Top Bar */}
      <Flex
        maxWidth="1440px"
        width={"100%"}
        p={2}
        textAlign={"left"}
        alignItems={"center"}
        flexDirection={"column"}
      >
        Reservation quote has expired.
        <Button
          h={"50px"}
          mt={6}
          onClick={returnToListing}
          colorScheme="dmOrange"
        >
          Start New Search
        </Button>
      </Flex>
    </Flex>
  );
};

export { CheckoutNew };

