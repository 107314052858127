import { Alert, AlertIcon, Box, Button, Checkbox, Flex, FormControl, FormLabel, Icon, Input, InputGroup, InputLeftElement, Stack, Text } from '@chakra-ui/react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useState } from 'react';
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import * as Yup from 'yup';
import { useTrips } from "../../contexts/TripContext";
import { createAgreement } from '../../helpers/agreementService';
import { calculateDaysBetweenDates } from '../../helpers/helpers';
import { updateReservation } from "../../helpers/reservationService";
import HouseRules from '../confirmation/HouseRules';

// Validation schema using Yup
const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string().email('Invalid email address').required('Email is required'),
  phone: Yup.string().required('Phone number is required'),
  street: Yup.string().required('Street is required'),
  city: Yup.string().required('City is required'),
  state: Yup.string().required('State is required'),
  zip: Yup.string().required('ZIP/Postal Code is required'),
});

const ErrorMessageText = ({ children }) => (
  <Text fontSize="sm" color="red.500">
    {children}
  </Text>
);

export const GuestAgreementSignaturePortal = ({ onSuccessfulSubmit }) => {
  const { selectedTrip, updateAllTrips } = useTrips();

  // Regex for channel placeholder emails
  var emailRegex = /(@guest.airbnb)|(@host.airbnb)|(@guest.booking)|(@messages.homeaway)|(@mchat.booking)|(@reply.airbnb)|(https:)|(@e.tr)|(@e.tripadvisor)|(\.SYNTAX-ERROR)|(@message.weneedavacation)/;

  // Check if selectedTrip.tenantEmail matches the regex and set accordingly
  const initialEmail = emailRegex.test(selectedTrip.tenantEmail) ? null : selectedTrip.tenantEmail;

  const [agreementEmail, setAgreementEmail] = useState(initialEmail);
  const [phone, setPhone] = useState(selectedTrip.phone || '');

	const [errorMessage, setErrorMessage] = useState("");
  
  const handleSubmitGuestAgreement = async (values, actions) => {
    //console.log("attempting to submit guest agreement")
    setErrorMessage("");

    const agreementData = {
      ...values,
      phone: phone, // using the phone state
      signedAt: new Date().toISOString(),
      type: 'guest', // since this is for guest agreements
      reservationNumber: selectedTrip.reservationNumber, // assuming this is provided in the selectedTrip prop
      houseNumber: selectedTrip.houseNumber,
      houseName: selectedTrip.house.name,
      address: selectedTrip.house.address,
      maxOccupants: selectedTrip.house.maxOccupancy,
      bedrooms: selectedTrip.house.bedrooms,
      bathrooms: selectedTrip.house.bathrooms,
      bookedOccupants: selectedTrip.adults,
      checkinDate: selectedTrip.checkinDate,
      checkoutDate: selectedTrip.checkoutDate,
      checkinTime: selectedTrip.checkinTime,
      checkoutTime: selectedTrip.checkoutTime,
      numNights: calculateDaysBetweenDates(selectedTrip.checkinDate,selectedTrip.checkoutDate),
      nightlyRates: selectedTrip.nightlyRates,
      taxesAndFees: selectedTrip.totalPrice - selectedTrip.nightlyRates,
      cleaningFee: selectedTrip.cleaningFee,
      totalPrice: selectedTrip.totalPrice,
      channel: selectedTrip.channel || selectedTrip.typename,
      dogFriendly: selectedTrip.house.tags !== null ? selectedTrip.house.tags.includes('Dog Friendly') : false,
      // other fields like 'metadata' are omitted
    };
    //console.log("attempting to submit guest agreement with:", agreementData)
    try {
      const createAgreementResult = await createAgreement(agreementData);
      //console.log("createAgreementResult",createAgreementResult);

      const updateReservationAgreementEmail = await updateReservation(selectedTrip.reservationNumber, { agreementEmail: agreementEmail });
      //console.log("updateReservationAgreementEmail",updateReservationAgreementEmail);

      updateAllTrips();
      onSuccessfulSubmit();
      // handle success (e.g., show success message, redirect, etc.)
    } catch (error) {
      console.error('Error submitting agreement:', error);
      setErrorMessage("Error submitting Guest Agreement. Please try again later or contact Del Mar Vacations for assistance.");
      // handle error (e.g., show error message)
    } finally {
      actions.setSubmitting(false); // This ensures Formik knows the submission has ended
    }
  };  

  return (
    <Box mt={6}>
    {errorMessage && <Alert status="error"><AlertIcon/>{errorMessage}</Alert>}
      <Formik
        initialValues={{
          firstName: selectedTrip.firstName || '',
          lastName: selectedTrip.lastName || '',
          email: agreementEmail || '',
          phone: selectedTrip.phone || '',
          street: '',
          city: '',
          state: '',
          zip: '',
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmitGuestAgreement}
      >
        {({ errors, touched, setFieldValue, setFieldTouched, isSubmitting, isValid }) => (
          <>
            <Form>
              <Stack spacing="3">
                {/* Disclaimer text */}
                <Text fontSize={'0.9em'} pt={3}>
                  <i>By clicking the submit button below, I, the undersigned, hereby apply for the above listed accommodation and warrant that I have read, understand and agree to the terms and conditions of this application, and I agree to abide by house rules and homeowners/agents/association restrictions on use of said property. I further understand and represent that I am executing this application on behalf of all parties on the premises during the dates of my stay.</i>
                </Text>

                {/* Name fields */}
                <Stack direction={{ base: 'column', md: 'row' }}>
                  <Field name="firstName">
                    {({ field, form }) => (
                      <FormControl isInvalid={form.errors.firstName && form.touched.firstName}>
                        <FormLabel fontSize={'0.9em'} mb={0}>First Name</FormLabel>
                        <Input {...field} fontSize={'0.9em'} placeholder="First Name" />
                        <ErrorMessage name="firstName" component={ErrorMessageText} />
                      </FormControl>
                    )}
                  </Field>
                  <Field name="lastName">
                    {({ field, form }) => (
                      <FormControl isInvalid={form.errors.lastName && form.touched.lastName}>
                        <FormLabel fontSize={'0.9em'} mb={0}>Last Name</FormLabel>
                        <Input {...field} fontSize={'0.9em'} placeholder="Last Name" />
                        <ErrorMessage name="lastName" component={ErrorMessageText} />
                      </FormControl>
                    )}
                  </Field>
                </Stack>

                {/* Email and Phone fields */}
                <Stack direction={{ base: 'column', md: 'row' }}>
                  <Field name="email">
                    {({ field, form }) => (
                      <FormControl isInvalid={form.errors.email && form.touched.email}>
                        <FormLabel fontSize={'0.9em'} mb={0}>Email</FormLabel>
                        <InputGroup>
                          <InputLeftElement pointerEvents='none'>
                            <Icon as={MdEmail} color='gray.300' />
                          </InputLeftElement>
                          <Input 
                            {...field} 
                            fontSize={'0.9em'} 
                            placeholder="Email"
                            onChange={(e) => {
                              field.onChange(e); // Formik's handler
                              setAgreementEmail(e.target.value); // Your custom handler
                            }}
                          />
                        </InputGroup>
                        <ErrorMessage name="email" component={ErrorMessageText} />
                      </FormControl>
                    )}
                  </Field>
                  <Field name="phone">
                    {({ form }) => (
                      <FormControl isInvalid={form.errors.phone && form.touched.phone}>
                        <FormLabel fontSize={'0.9em'} mb={0}>Cell Phone</FormLabel>
                        <InputGroup>
                          <InputLeftElement pointerEvents='none'>
                            <Icon as={FaPhoneAlt} color='gray.300' />
                          </InputLeftElement>
                          <PhoneInput
                            placeholder="Cell Phone"
                            defaultCountry="US"
                            value={phone}
                            onChange={(value) => {
                              setPhone(value);
                              setFieldValue("phone", value);
                            }}
                            onBlur={() => {
                              setFieldTouched("phone", true, false);
                            }}
                            style={{"width":"100%","height":"40px","border":"solid 1px rgb(226, 232, 240)", "paddingLeft":"10px"}}
                          />
                        </InputGroup>
                        <ErrorMessage name="phone" component={ErrorMessageText} />
                      </FormControl>
                    )}
                  </Field>
                </Stack>

                {/* Address fields */}
                <Stack spacing="3" direction={{ base: 'column', md: 'row' }}>
                  <Field name="street">
                    {({ field, form }) => (
                      <FormControl isInvalid={form.errors.street && form.touched.street}>
                        <FormLabel fontSize={'0.9em'} mb={0}>Street</FormLabel>
                        <Input {...field} fontSize={'0.9em'} placeholder="Street" />
                        <ErrorMessage name="street" component={ErrorMessageText} />
                      </FormControl>
                    )}
                  </Field>
                  <Field name="city">
                    {({ field, form }) => (
                      <FormControl isInvalid={form.errors.city && form.touched.city}>
                        <FormLabel fontSize={'0.9em'} mb={0}>City</FormLabel>
                        <Input {...field} fontSize={'0.9em'} placeholder="City" />
                        <ErrorMessage name="city" component={ErrorMessageText} />
                      </FormControl>
                    )}
                  </Field>
                  <Field name="state">
                    {({ field, form }) => (
                      <FormControl isInvalid={form.errors.state && form.touched.state}>
                        <FormLabel fontSize={'0.9em'} mb={0}>State</FormLabel>
                        <Input {...field} fontSize={'0.9em'} placeholder="State / Province" />
                        <ErrorMessage name="state" component={ErrorMessageText} />
                      </FormControl>
                    )}
                  </Field>
                  <Field name="zip">
                    {({ field, form }) => (
                      <FormControl isInvalid={form.errors.zip && form.touched.zip}>
                        <FormLabel fontSize={'0.9em'} mb={0}>ZIP</FormLabel>
                        <Input {...field} fontSize={'0.9em'} placeholder="ZIP / Postal Code" />
                        <ErrorMessage name="zip" component={ErrorMessageText} />
                      </FormControl>
                    )}
                  </Field>
                </Stack>
              </Stack>
              
              {!(selectedTrip && selectedTrip.house.tags && selectedTrip.house.tags.includes('Dog Friendly')) &&
                <Field name="noDogAgreement">
                  {({ field, form }) => (
                    <FormControl  pt={'20px'} isInvalid={form.errors.noDogAgreement && form.touched.noDogAgreement} isRequired>
                      <Checkbox {...field} fontSize={'0.9em'}>
                        I understand that this home does not permit animals, and agree to abide by the No Pets Permitted policy.
                      </Checkbox>
                      <ErrorMessage name="noDogAgreement" component={ErrorMessageText} />
                    </FormControl>
                  )}
                </Field>
              }
              
              <HouseRules 
                channel={selectedTrip.channel}
              />

              <Flex direction="row" py="4">
                <Button type="submit" colorScheme="dmOrange" isDisabled={!isValid} isLoading={isSubmitting}>Submit</Button>
              </Flex>
            </Form>
          </>
        )}
      </Formik>
    </Box>
  )
}

export default GuestAgreementSignaturePortal;
