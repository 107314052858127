import {
  CheckCircleIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@chakra-ui/icons";
import {
  AspectRatio,
  Box,
  Button,
  Card,
  CardBody,
  Divider,
  Flex,
  FormControl,
  Grid,
  Image,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  Tooltip,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import { useUser } from "@clerk/clerk-react";
import { differenceInMonths, format } from "date-fns";
import addDays from "date-fns/addDays";
import * as React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { SearchResultsContext } from "../../../contexts/SearchResultsContext";
import { MAX_END_DATE } from "../../../helpers/constants";
import { fetchHouseCalendar, fetchHouseDetails } from "../../../helpers/fetch";
import {
  ScrollToTop,
  calculateDaysBetweenDates,
  formatFriendlyDate,
  parseDateToLocalTime,
} from "../../../helpers/helpers";
import {
  addQuoteItem,
  checkDateRange,
  checkQuoteParams,
  getActiveQuoteVariable,
} from "../../../helpers/quoteService";
import {
  fetchReservationQuote,
  getCheckOutById,
} from "../../../helpers/reservationService";
import HouseMap from "../../HouseMap";
import InquiryModal from "../../InquiryModal";
import { Details } from "./Details";
import { NotAcceptedBlock } from "./NotAccepted";
import { PhotoGallery } from "./PhotoGallery";
import { SkeletonBlock } from "./Skeleton";
import { TopBar } from "./Topbar";

const ListingBlock = () => {
  const navigate = useNavigate();

  // Get House Number from URL (e.g., book.thisisdelmar.com/20001)
  let { houseNumber } = useParams();

  // All Contexts
  const {
    contextDateRange,
    guests,
    setGuests,
    houseData,
    setHouseData,
    houseCalendar,
    setHouseCalendar,
    globalCheckIns,
    activeQuoteNumber,
    setActiveQuoteNumber,
  } = React.useContext(SearchResultsContext);

  // Admin Query Handling
  const user = useUser();
  const userIsAdmin =
    user &&
    user.isSignedIn &&
    user.user.primaryEmailAddress &&
    user.user.primaryEmailAddress.emailAddress &&
    (user.user.primaryEmailAddress.emailAddress.includes("darrenhe.com") ||
      user.user.primaryEmailAddress.emailAddress.includes(
        "cloudthoughts.com"
      ) ||
      user.user.primaryEmailAddress.emailAddress.includes("thisisdelmar.com"));

  // Handling for Params in Listing Link
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // https://booking-api-docs.guesty.com/docs/google#supported-variables
  const paramstartdate =
    queryParams.get("startdate") || queryParams.get("checkIn");

  const paramenddate =
    queryParams.get("enddate") || queryParams.get("checkOut");

  const linkedGuests = Number(
    queryParams.get("guests") || queryParams.get("minOccupancy") || 2
  );

  React.useEffect(() => {
    const linkedContextDateRange = [
      parseDateToLocalTime(paramstartdate),
      parseDateToLocalTime(paramenddate),
    ].filter(Boolean);

    if (
      linkedContextDateRange &&
      checkDateRange(paramstartdate, paramenddate)
    ) {
      setListingStartDate(linkedContextDateRange[0]);
      setListingEndDate(linkedContextDateRange[1]);
    }

    if (linkedGuests) {
      setGuests(linkedGuests);
    } else {
      setGuests(2);
    }

    // Admin Quote Handling
    if (userIsAdmin && !activeQuoteNumber) {
      const localQuoteVariable = getActiveQuoteVariable();
      setActiveQuoteNumber(localQuoteVariable);
    }
  }, [userIsAdmin, paramstartdate, paramenddate]);

  /// Rebook Mode Variables ///
  const [rebookMode, setRebookMode] = React.useState(false);
  const [rebookParameterReservation, setRebookParameterReservation] =
    React.useState(null);
  const [rebookCoupon, setRebookCoupon] = React.useState(null);
  const [rebookCouponReady, setRebookCouponReady] = React.useState(false);

  React.useEffect(() => {
    // Moving this here from ReservationContext to make sure it fires in time
    const setAttributionFromUrl = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const attribution = urlParams.get("source");
      const rebookMode = urlParams.get("rebook");
      if (attribution) {
        localStorage.setItem("dm-attribution", attribution);
      }
      if (rebookMode) {
        localStorage.setItem("dm-rebook-mode", Date.now().toString());
        localStorage.setItem("dm-rebook-reservation", rebookMode);
      }
    };

    setAttributionFromUrl();

    // Check localStorage to see if rebook mode is active
    const rebookParameter = localStorage.getItem("dm-rebook-mode");
    const rebookValue = localStorage.getItem("dm-rebook-reservation"); // local version of variable for rebookParameterReservation
    setRebookParameterReservation(
      localStorage.getItem("dm-rebook-reservation")
    );
    const oneDayInMilliseconds = 24 * 60 * 60 * 1000;
    const currentTime = Date.now();

    //console.log('rebookParameter', rebookParameter, 'rebookParameterReservation', localStorage.getItem('dm-rebook-reservation'), 'rebookValue', rebookValue)

    let isRebook; // local version of variable for rebookMode

    // If rebookParameter is set, check if it's been less than 24 hours since the last rebook
    if (rebookParameter) {
      //console.log('rebookParameter Time check')
      const rebookTimestamp = parseInt(rebookParameter, 10);
      if (currentTime - rebookTimestamp < oneDayInMilliseconds) {
        //console.log('rebookMode set')
        isRebook = true;
        setRebookMode(true);
      } else {
        //console.log('rebookMode not set')
        isRebook = false;
        setRebookMode(false);
      }
    }

    //console.log('isRebook', isRebook, 'rebookMode', rebookMode, 'rebookParameterReservation', rebookParameterReservation,'rebookValue', rebookValue )

    // Function to get the checkout date and coupon code for rebook mode
    const getRebookCheckoutDateAndCoupon = async () => {
      //console.log('rebookParameterReservation', rebookValue)
      if (rebookValue === "stayfi") {
        //console.log("stayfi branch")
        setRebookCoupon("FJCP97YWVP9J"); // 12% (stayfi)
        setRebookCouponReady(true);
        return;
      }
      if (rebookValue === "4be10cd6da4b41ee20492eab7bec109d") {
        setRebookCoupon("1KRYHEMOUFVN"); // 5%
        setRebookCouponReady(true);
        return;
      }
      if (rebookValue === "2f206aa4d52f82d7ee10d01300aa59b7") {
        setRebookCoupon("DXDJJQDVJJID"); // 10%
        setRebookCouponReady(true);
        return;
      }
      const response = await getCheckOutById(rebookValue);
      const data = await response.json();
      //console.log('rebook data', data);

      if (data && data.checkoutDate) {
        const rebookCheckOutDate = data.checkoutDate; // Date String
        const timeDifference =
          new Date().getTime() - new Date(rebookCheckOutDate).getTime();
        const daysDifference = timeDifference / (1000 * 3600 * 24);
        console.log(
          "daysDifference",
          daysDifference,
          "timeDifference",
          timeDifference
        );
        if (daysDifference < 1) {
          setRebookCoupon("FJCP97YWVP9J"); // 12% (stayfi)
        } else if (daysDifference <= 14) {
          setRebookCoupon("DXDJJQDVJJID"); // 10%
        } else {
          setRebookCoupon("1KRYHEMOUFVN"); // 5%
        }
      }
      setRebookCouponReady(true);
    };

    // If rebookParameter and rebookMode are set, get the checkout date and coupon code
    if (rebookValue && isRebook) {
      getRebookCheckoutDateAndCoupon();
    }
    //console.log('rebookCoupon', rebookCoupon, 'rebookCouponReady', rebookCouponReady)
    setRebookCouponReady(true);
  }, []);

  /// END Rebook Mode Variables ///

  //// Datepicker and Guests ////
  const [dateRange, setDateRange] = React.useState([null, null]);

  function clearDates() {
    setDateRange([null, null]);
    setListingStartDate(null);
    setListingEndDate(null);
  }

  // Determine if the date picker should be inline based on screen size
  const isInline = useBreakpointValue({ base: true, md: false });

  //// END Datepicker and Guests ////

  //// Mobile Booking Menu Scroll ////
  const bookingSectionRef = React.useRef(null);

  //// END Mobile Booking Menu Scroll ////

  //// Submit for Reservation Quote ////
  const [guestyData, setGuestyData] = React.useState([]);
  const [startDatePicker, endDatePicker] = contextDateRange;
  const [listingStartDate, setListingStartDate] = React.useState(null);
  const [listingEndDate, setListingEndDate] = React.useState(null);
  const [nightlyRates, setNightlyRates] = React.useState(null);
  const [taxesAndFees, setTaxesAndFees] = React.useState(null);
  const [totalPrice, setTotalPrice] = React.useState(null);
  const [reservationQuote, setReservationQuote] = React.useState(null);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [avgNightlyRate, setAvgNightlyRate] = React.useState(null);
  const [addedToQuote, setAddedToQuote] = React.useState(false);
  const [discount, setDiscount] = React.useState(null);

  const [isTaxesExpanded, setIsTaxesExpanded] = React.useState(false);
  const [isFeesExpanded, setIsFeesExpanded] = React.useState(false);

  const toggleTaxesExpand = () => setIsTaxesExpanded(!isTaxesExpanded);
  const toggleFeesExpand = () => setIsFeesExpanded(!isFeesExpanded);

  const taxes = (taxesAndFees || []).filter((item) =>
    item.title.toLowerCase().includes("tax")
  );
  const fees = (taxesAndFees || []).filter((item) =>
    item.title.toLowerCase().includes("fee")
  );

  const totalTaxes = taxes.reduce((acc, item) => acc + item.amount, 0);
  const totalFees = fees.reduce((acc, item) => acc + item.amount, 0);
  const totalTaxesAndFees = totalTaxes + totalFees;

  const [isSubmittingForQuote, setIsSubmittingForQuote] = React.useState(false);

  // Update Total Pricing and Avg Nightly Rate
  React.useEffect(() => {
    if (taxesAndFees && taxesAndFees.length > 0) {
      console.log("setTotalPrice");
      const total = taxesAndFees.reduce((acc, item) => acc + item.amount, 0);
      setTotalPrice(total + nightlyRates);
      if (listingStartDate && listingEndDate) {
        const formattedStartDate = format(listingStartDate, "yyyy-MM-dd");
        const formattedEndDate = format(listingEndDate, "yyyy-MM-dd");
        setAvgNightlyRate(
          (total + nightlyRates) /
            calculateDaysBetweenDates(formattedStartDate, formattedEndDate)
        );
      } else {
        setAvgNightlyRate(null);
      }
    } else {
      setTotalPrice(null);
    }
  }, [taxesAndFees, nightlyRates]);

  const addToQuote = async () => {
    try {
      if (activeQuoteNumber && listingStartDate && listingEndDate && guests) {
        const formattedStartDate = format(listingStartDate, "yyyy-MM-dd");
        const formattedEndDate = format(listingEndDate, "yyyy-MM-dd");

        const quoteItemData = {
          houseNumber: houseNumber,
          checkinDate: formattedStartDate,
          checkoutDate: formattedEndDate,
          guests: guests,
        };
        await addQuoteItem(activeQuoteNumber, quoteItemData);
        setAddedToQuote(true);
      }
    } catch (error) {
      console.error("Error adding to quote:", error);
    }
  };

  const handleSubmit = async () => {
    console.log("handleSubmit");
    setIsSubmittingForQuote(true);

    try {
      if (!houseData) {
        console.error("houseData is not available");
        return;
      }
      if (!guests) {
        setErrorMessage("Please select guests.");
        return;
      }
      if (!listingStartDate || !listingEndDate) {
        setErrorMessage("Please select dates.");
        return;
      }
      if (totalPrice && reservationQuote) {
        // Logic to navigate to checkout page
        //console.log('totalPrice:', totalPrice, 'rebookMode:', rebookMode, 'differenceInMonths:', differenceInMonths(listingStartDate, new Date()));
        if (
          totalPrice > 300 &&
          rebookMode &&
          differenceInMonths(listingStartDate, new Date()) > 6
        ) {
          navigate(`/${houseNumber}/payment`, {
            state: { reservationQuote: reservationQuote },
          });
        } else {
          navigate(`/${houseNumber}/checkout`, {
            state: { reservationQuote: reservationQuote },
          });
        }
      } else {
        setErrorMessage(null);
        setNightlyRates(null);
        setTaxesAndFees(null);
        setTotalPrice(null);
        setErrorMessage(null);
        setReservationQuote(null);
        setDiscount(null);
        const formattedStartDate = format(listingStartDate, "yyyy-MM-dd");
        const formattedEndDate = format(listingEndDate, "yyyy-MM-dd");
        const listingId = houseData.guestyHouseId;

        const queryParams = {
          checkInDateLocalized: formattedStartDate,
          checkOutDateLocalized: formattedEndDate,
          listingId,
          guestsCount: guests,
          ...(rebookMode && rebookCoupon ? { coupons: rebookCoupon } : {}), // Conditionally add coupons only if rebookCoupon is truthy
        };

        // Fetch call to backend server
        await fetchReservationQuote(
          queryParams,
          setNightlyRates,
          setTaxesAndFees,
          setReservationQuote,
          setErrorMessage,
          setDiscount
        );

        if (reservationQuote && reservationQuote.status === "valid") {
          navigate(`/${houseNumber}/payment`, {
            state: { reservationQuote: reservationQuote },
          });
        }
      }
    } catch (error) {
      console.error("Error:", error.message);
      setErrorMessage(error.message);
      clearDates();
      // Clear the URL query parameters
      const searchParams = new URLSearchParams(location.search);
      if (searchParams.has("startdate") || searchParams.has("enddate")) {
        searchParams.delete("startdate");
        searchParams.delete("enddate");
        navigate({ search: searchParams.toString() }, { replace: true });
      }
    } finally {
      setIsSubmittingForQuote(false);
    }
  };

  // Attempt to Load a Reservation Quote Only if Dates, Guests, and House Data Available on Initial Page Load
  React.useEffect(() => {
    async function initialQuoteLoad() {
      console.log("initialQuoteLoad");

      try {
        setErrorMessage(null);
        setNightlyRates(null);
        setTaxesAndFees(null);
        setTotalPrice(null);
        setErrorMessage(null);
        setReservationQuote(null);
        setDiscount(null);

        const formattedStartDate = format(listingStartDate, "yyyy-MM-dd");
        const formattedEndDate = format(listingEndDate, "yyyy-MM-dd");
        const listingId = houseData.guestyHouseId;

        const queryParams = {
          checkInDateLocalized: formattedStartDate,
          checkOutDateLocalized: formattedEndDate,
          listingId,
          guestsCount: guests,
          ...(rebookMode && rebookCoupon ? { coupons: rebookCoupon } : {}), // Conditionally add coupons only if rebookCoupon is truthy
        };

        // Check if the queryParams are valid

        const checkParams = checkQuoteParams(queryParams);

        if (!checkParams) {
          // silently fail if the params are not valid
          setIsSubmittingForQuote(false);
          return;
        }

        fetchReservationQuote(
          queryParams,
          setNightlyRates,
          setTaxesAndFees,
          setReservationQuote,
          setErrorMessage,
          setDiscount
        );
      } catch (error) {
        console.error("Error:", error.message);
        setErrorMessage(error.message);
      } finally {
        setIsSubmittingForQuote(false);
      }
    }

    if (
      houseData &&
      guests &&
      listingStartDate &&
      listingEndDate &&
      !reservationQuote &&
      rebookCouponReady
    ) {
      setIsSubmittingForQuote(true);
      initialQuoteLoad();
    }
  }, [
    houseData,
    listingStartDate,
    listingEndDate,
    rebookMode,
    rebookCoupon,
    rebookCouponReady,
  ]);

  React.useEffect(() => {
    if (reservationQuote) {
      setReservationQuote(null);
    }
  }, [listingStartDate, listingEndDate]);

  //// End Submit for Reservation Quote ////

  function decrementGuestInput() {
    setGuests((prevGuests) => {
      const currentGuests = isNaN(prevGuests) ? 1 : prevGuests;
      return Math.max(1, currentGuests - 1);
    });
  }

  function incrementGuestInput() {
    setGuests((prevGuests) => {
      const currentGuests = isNaN(prevGuests) ? 1 : prevGuests;
      return Math.min(houseData.occupancy, currentGuests + 1);
    });
  }

  //// Disable Full Screen Image View on Mobile ////
  const [isMobile, setIsMobile] = React.useState(window.innerWidth < 768);

  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  //// END Disable Full Screen Image View on Mobile ////

  //// Get House Data from Backend ////

  //const [houseData, setHouseData] = React.useState(null);
  const [houseLoading, setHouseLoading] = React.useState(true);

  const fetchHouseData = async () => {
    console.log("fetchHouseData");

    setHouseLoading(true);

    try {
      //console.log("Fetching data for", houseNumber);
      const params = { houseNumber: houseNumber };

      const response = await fetchHouseDetails(params);

      if (!response.ok) {
        throw new Error(`fetchHouseDetails Error! Status: ${response.status}`);
      }

      const returnedHouseData = await response.json();
      console.log("returnedHouseData", returnedHouseData);
      setHouseData(returnedHouseData.results);
      //console.log("returnedHouseData", returnedHouseData.results)
    } catch (error) {
      console.error("Error fetching house data from DB:", error);
    } finally {
      setHouseLoading(false);
      //console.log("returnedHouseData", houseData);
    }
  };

  React.useEffect(() => {
    fetchHouseData();
  }, [houseNumber]);

  //const [houseCalendar, setHouseCalendar] = React.useState(null);
  const [calendarLoading, setCalendarLoading] = React.useState(true);

  const fetchHouseAvailability = async () => {
    try {
      console.log("fetchHouseAvailability");
      const startDate = format(new Date(), "yyyy-MM-dd"); // Today's date
      const endDate = MAX_END_DATE; // End of 2025

      setCalendarLoading(true);

      const response = await fetchHouseCalendar(
        houseData.guestyHouseId,
        startDate,
        endDate
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const availabilityData = await response.json();
      console.log("availabilityData", availabilityData);
      // Set the state or handle the data as needed
      //console.log("availabilityData", availabilityData)
      setHouseCalendar(availabilityData);
    } catch (error) {
      console.error("Error fetching house availability:", error);
    } finally {
      setCalendarLoading(false);
    }
  };

  React.useEffect(() => {
    if (houseData && houseData.guestyHouseId) {
      fetchHouseAvailability();
    }
  }, [houseData]);

  //// END Get House Data from Backend ////

  //// Modal Handlers ////
  const {
    isOpen: isReviewModalOpen,
    onOpen: onOpenReviewModal,
    onClose: onCloseReviewModal,
  } = useDisclosure({
    id: "review-modal",
  });

  const {
    isOpen: isBookingModalOpen,
    onOpen: onOpenBookingModal,
    onClose: onCloseBookingModal,
  } = useDisclosure({
    id: "booking-modal",
  });
  const {
    isOpen: isInquiryModalOpen,
    onOpen: onOpenInquiryModal,
    onClose: onCloseInquiryModal,
  } = useDisclosure({
    id: "inquiry-modal",
  });

  //// END Modal Handlers ////

  //// Date Picker Resize Handler ////
  const [monthsShown, setMonthsShown] = React.useState(
    window.innerWidth <= 767 ? 1 : 2
  );

  React.useEffect(() => {
    function handleResize() {
      setMonthsShown(window.innerWidth <= 767 ? 1 : 2);
    }

    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  //// END Date Picker Resize Handler ////

  //// Guesty Calendar Handling ////

  const [bookedDates, setBookedDates] = React.useState([]);
  const [datesNotOkayToCheckIn, setDatesNotOkayToCheckIn] = React.useState([]);
  const [datesNotOkayToCheckInEpoch, setDatesNotOkayToCheckInEpoch] =
    React.useState([]);
  const [datesToGrayOut, setDatesToGrayOut] = React.useState([]);
  const [datesToGrayOutEpoch, setDatesToGrayOutEpoch] = React.useState([]);
  const [bookedAndNotOkayToCheckOutDates, setBookedAndNotOkayToCheckOutDates] =
    React.useState([]);
  const [currentlyShowing, setCurrentlyShowing] = React.useState("checkIn");
  const [firstAvailableCheckIn, setFirstAvailableCheckIn] =
    React.useState(null);

  // This is to adjust the Guesty date data if needed to make sure it blanks out the right dates i the datepicker
  const hoursOffset = new Date().getTimezoneOffset() / 60;

  React.useEffect(() => {
    console.log("houseCalendar", houseCalendar);

    if (houseCalendar) {
      // Get the Guesty data on initial page load (should only do this once, all other changes to datepicker will be done via state changes)
      const guestyDataRaw = houseCalendar;

      // Transform the data to get rid of fields we don't need and alter dates if needed, saves a COPY to be used for Check Outs later
      const guestyDataTransformed = transformGuestyData(
        guestyDataRaw,
        hoursOffset
      );
      setGuestyData([...guestyDataTransformed]);

      // Save the dates that are booked to make them a different style/color in the datepicker
      const getBookedDates = guestyDataTransformed
        .filter((date) => date.status === "booked")
        .map((date) => Date.parse(date.startDate));
      setBookedDates(getBookedDates);

      // Add checkInOkay = true/false field to each date
      const guestyDataCheckInsProcessed = processCheckIns(
        guestyDataTransformed
      );

      //console.log('guestyDataCheckInsProcessed',guestyDataCheckInsProcessed)
      const firstAvailableCheckIn = guestyDataCheckInsProcessed.find(
        (date) => date.checkInOkay === true
      );

      setFirstAvailableCheckIn(firstAvailableCheckIn);
      //console.log('firstAvailableCheckIn',firstAvailableCheckIn);

      // Create an array of all dates that have checkInOkay = false, then grab the startDate from each of them so it's just an array of dates for the datepicker
      const badCheckInDates = guestyDataCheckInsProcessed
        .filter((date) => !date.checkInOkay && date.startDate)
        .map((date) => date.startDate);

      const badCheckInDatesEpoch = guestyDataCheckInsProcessed
        .filter((date) => !date.checkInOkay && date.startDate)
        .map((date) => Date.parse(date.startDate));

      // Set that array of dates to be grayed out
      setDatesToGrayOut(badCheckInDates);
      setDatesToGrayOutEpoch(badCheckInDatesEpoch);

      // (And save that array of dates so we can gray them again after user clicks an end date)
      setDatesNotOkayToCheckIn(badCheckInDates);
      setDatesNotOkayToCheckInEpoch(badCheckInDatesEpoch);
      //console.log("datesNotOkayToCheckInEpoch INTIIAL", datesNotOkayToCheckInEpoch)
    }
  }, [houseCalendar]);

  let showInfo = true;

  function renderDayContents(day, date) {
    let tooltipText = "";
    const dateEpoch = Date.parse(date);
    const today = new Date();
    const lastDateAvailable = new Date(
      globalCheckIns[globalCheckIns.length - 1]
    );

    if (showInfo) {
      console.log("datesToGrayOutEpoch", datesToGrayOutEpoch);
      showInfo = false;
    }

    if (currentlyShowing === "checkIn") {
      if (bookedDates.includes(dateEpoch)) {
        tooltipText = "Unavailable";
      } else if (dateEpoch > lastDateAvailable.getTime()) {
        tooltipText = "Booking season not open yet";
      } else if (datesToGrayOutEpoch.includes(dateEpoch)) {
        tooltipText = "No check-in";
      } else if (dateEpoch <= today.getTime()) {
        tooltipText = "Invalid check-in";
      } else {
        tooltipText = "Available for check-in";
      }
    } else if (currentlyShowing === "checkOut") {
      if (bookedAndNotOkayToCheckOutDates.includes(dateEpoch)) {
        tooltipText = "Unavailable";
      } else if (dateEpoch > lastDateAvailable.getTime()) {
        tooltipText = "Booking season not open yet";
      } else if (datesToGrayOutEpoch.includes(dateEpoch)) {
        tooltipText = "No check-out";
      } else if (dateEpoch <= today.getTime()) {
        tooltipText = "Invalid check-out";
      } else {
        tooltipText = "Available for check-out";
      }
    }

    return (
      <Tooltip
        hasArrow
        placement="top"
        label={tooltipText}
        aria-label={tooltipText}
      >
        <span>{day}</span>
      </Tooltip>
    );
  }

  // Trims down the Guesty data to only the parts we care about within the next year
  function transformGuestyData(guestyDataRaw, hoursOffset) {
    const lastDateAvailable = new Date(
      globalCheckIns[globalCheckIns.length - 1]
    );

    return (
      guestyDataRaw.data.days
        // Filter out dates that are past a year from now
        .filter(
          (day) => new Date(day.date).getTime() <= lastDateAvailable.getTime()
        )
        .map((day) => {
          let guestyDate = new Date(day.date);
          guestyDate.setHours(0);
          guestyDate.setMinutes(0);
          guestyDate.setSeconds(0);
          // If hoursOffset is greater than 0, add 1 day to all dates
          if (hoursOffset > 0)
            guestyDate = new Date(guestyDate.setDate(guestyDate.getDate() + 1));

          return {
            startDate: guestyDate,
            status: day.status,
            booked: day.status === "available" ? false : true,
            minDays: day.minNights,
            price: day.price,
            cta: day.cta,
            ctd: day.ctd,
          };
        })
    );
  }

  // Can only check in on a day that's *not* already book AND where CTA is false
  // AND if that date will have a valid Check Out date too
  function processCheckIns(guestyDataTransformed) {
    for (const date of guestyDataTransformed) {
      if (
        date.booked === false &&
        date.cta !== true &&
        processCheckOuts(date.startDate, guestyDataTransformed).length > 0
      ) {
        date.checkInOkay = true;
      } else {
        date.checkInOkay = false;
      }
    }

    return guestyDataTransformed;
  }

  function processCheckOuts(pickedDate, allDates) {
    const pickedDateRange = allDates.find(
      (range) => range.startDate.getTime() === pickedDate.getTime()
    );
    if (!pickedDateRange)
      throw new Error(
        `Date range not found for the picked date: ${pickedDate}`
      );

    // Sort allDates in ascending order of startDate
    allDates.sort((a, b) => a.startDate.getTime() - b.startDate.getTime());

    // Find the first startDate after the pickedDate that is booked
    const firstBookedDateRange = allDates.find(
      (range) =>
        range.booked === true &&
        range.startDate.getTime() > pickedDate.getTime()
    );
    const availableDates = [];

    for (const dateRange of allDates) {
      const startDate = dateRange.startDate;
      const diffTime = startDate.getTime() - pickedDate.getTime();
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      // If the start date of the range is not booked and is at least minDays in the future or is in the past, add it to the available dates
      if (
        dateRange.booked === false &&
        startDate.getTime() >= pickedDate.getTime() &&
        diffDays >= pickedDateRange.minDays &&
        dateRange.ctd !== true
      ) {
        // Stop adding to availableDates if the date is later than the first booked date
        if (
          firstBookedDateRange &&
          startDate.getTime() >= firstBookedDateRange.startDate.getTime()
        )
          break;

        availableDates.push(startDate);
      }
    }

    // Add the first booked startDate after the pickedDate as checkout on this date should be allowed
    if (
      firstBookedDateRange &&
      Math.ceil(
        (firstBookedDateRange.startDate.getTime() - pickedDate.getTime()) /
          (1000 * 60 * 60 * 24)
      ) >= pickedDateRange.minDays
    ) {
      availableDates.push(firstBookedDateRange.startDate);
    }

    //console.log(availableDates);
    return availableDates;
  }

  //// END Guesty Calendar Handling ////

  React.useEffect(() => {
    // Check if totalPrice is known (not null)
    if (totalPrice !== null) {
      setTotalPrice(null);
    }
  }, [listingStartDate, listingEndDate]);

  React.useEffect(() => {
    if (errorMessage && errorMessage.includes("rate plans")) {
      // Clear the URL query parameters
      const searchParams = new URLSearchParams(location.search);
      if (searchParams.has("startdate") || searchParams.has("enddate")) {
        searchParams.delete("startdate");
        searchParams.delete("enddate");
        navigate({ search: searchParams.toString() }, { replace: true });
      }
    }
  }, [errorMessage]);

  const bookingContent = (
    <Flex w={{ base: "100%", md: "100%" }} ref={bookingSectionRef}>
      {houseData && (
        <>
          {/* Floating Card */}
          <Flex
            borderRadius={8}
            height={"fit-content"}
            w="100%"
            p={2}
            position="sticky"
            top="100px"
            boxShadow={{ base: "none", md: "dmShadow" }}
            flexDirection="column"
          >
            {/* Price and Reviews */}
            <Flex
              flexDirection={{ base: "column", xl: "row" }}
              justifyContent={"space-between"}
              w="100%"
              p={{ base: 2, md: 4 }}
              gap="5px"
            >
              <Flex fontSize={"20px"} alignItems={"top"}>
                {avgNightlyRate && avgNightlyRate > 0 && !isInline ? (
                  <Text>${avgNightlyRate.toFixed(0)} total per night</Text>
                ) : (
                  <Text>Book your trip</Text>
                )}
              </Flex>
              {/* Rating and Review Count */}
              {houseData.reviews > 0 && (
                <>
                  <Flex
                    className="listing-reviews-parent"
                    alignItems={"center"}
                    display={{ base: "none", md: "flex" }}
                  >
                    <Flex className="listing-rating">
                      {!isNaN(houseData.rating) && houseData.rating !== null
                        ? houseData.rating.toFixed(1)
                        : "0"}
                    </Flex>
                    <Image
                      src={"../media/ratingstar.png"}
                      alt="Home Rating"
                      objectFit={"contain"}
                      align={"left"}
                      width={"18px"}
                      height={"18px"}
                      className="listing-rating-star"
                      mx={1.5}
                    />
                    <Flex
                      className="listing-reviews"
                      fontSize={"14px"}
                      fontWeight={500}
                      pt={0.5}
                      as="u"
                      onClick={onOpenReviewModal}
                      sx={{ "&:hover": { cursor: "pointer" } }}
                    >
                      {!isNaN(houseData.reviews)
                        ? (houseData.reviews as number)
                        : 0}{" "}
                      Reviews
                    </Flex>
                  </Flex>
                </>
              )}
            </Flex>
            {/* End Price and Reviews */}

            {/* Datepicker Form */}
            <Flex
              flexDirection="column"
              w="100"
              px={2}
              pt={{ base: 1, md: 2 }}
              pb={4}
            >
              {/* Dates Input */}
              <FormControl
                h={{ base: "unset", md: "50px" }}
                w="100%"
                fontSize={16}
                borderColor={"black"}
                inputMode="none"
                border={{ base: "none", md: "1px" }}
              >
                <Flex
                  color={"black"}
                  height={"40px"}
                  w={"100%"}
                  h={"100%"}
                  className="mobile-menu-date-picker"
                >
                  {globalCheckIns && globalCheckIns.length > 0 && (
                    <DatePicker
                      placeholderText="Select Dates"
                      h={{ base: "500px", md: "100%" }}
                      disabled={calendarLoading}
                      isClearable={true}
                      selectsRange={true}
                      startDate={listingStartDate}
                      endDate={listingEndDate}
                      inline={isInline}
                      monthsShown={isInline ? 1 : 1}
                      onChange={(update) => {
                        console.log("update", update);
                        //setDateRange(update)
                        //setContextDateRange(update)
                        setListingStartDate(update[0]);
                        setListingEndDate(update[1]);
                        //console.log("update", update)

                        // Gray out bad check in dates either: (1) on first load (when both are null) *or* (2) when both are NOT null
                        // (So the user can't click on a bad check in date when a date range is already selected)
                        const grayOutBadCheckInDates =
                          (update[0] === null && update[1] === null) ||
                          (update[0] !== null && update[1] !== null);
                        // Gray out bad check out dates when a check out date hasn't been selected yet
                        const grayOutBadCheckOutDates =
                          update[0] !== null && update[1] === null;

                        if (grayOutBadCheckOutDates) {
                          console.log("grayOutBadCheckOutDates");
                          setCurrentlyShowing("checkOut");
                          // Set the datepicker to gray out dates that can't be checked OUT
                          // First, find which dates ARE okay to check out for the user-selected check in date (update[0])
                          const datesOkayToCheckOut = processCheckOuts(
                            update[0],
                            guestyData
                          );
                          //console.log("datesOkayToCheckOut", datesOkayToCheckOut)
                          // Create an array of all the dates NOT in that array (ie: the ones that are NOT okay to check out)
                          const datesNotOkayToCheckOut = guestyData
                            .filter(
                              (date) =>
                                !datesOkayToCheckOut.includes(date.startDate)
                            )
                            .map((date) => date.startDate);
                          const datesNotOkayToCheckOutEpoch = guestyData
                            .filter(
                              (date) =>
                                !datesOkayToCheckOut.includes(date.startDate)
                            )
                            .map((date) => Date.parse(date.startDate));
                          //console.log("datesNotOkayToCheckOut", datesNotOkayToCheckOut)
                          // Set those dates (the ones NOT okay to check out) to be grayed out
                          setDatesToGrayOut(datesNotOkayToCheckOut);
                          setDatesToGrayOutEpoch(datesNotOkayToCheckOutEpoch);

                          // We have to create a new array to find the dates that are booked AND not okay to check out because datesNotOkayToCheckOut is dates only, do extra data
                          const datesNotOkayToCheckOutWithExtraData =
                            guestyData.filter(
                              (date) =>
                                !datesOkayToCheckOut.includes(date.startDate)
                            );
                          const getBookedAndNotOkayToCheckOutDates =
                            datesNotOkayToCheckOutWithExtraData
                              .filter((date) => date.status === "booked")
                              .map((date) => Date.parse(date.startDate));
                          setBookedAndNotOkayToCheckOutDates(
                            getBookedAndNotOkayToCheckOutDates
                          );
                        } else if (grayOutBadCheckInDates) {
                          console.log("grayOutBadCheckInDates");
                          setCurrentlyShowing("checkIn");
                          // Set the datepicker to gray out dates that can't be checked IN (basically same thing as what it does onload)
                          setDatesToGrayOut(datesNotOkayToCheckIn);
                          setDatesToGrayOutEpoch(datesNotOkayToCheckInEpoch);
                        }
                      }}
                      dayClassName={(date) => {
                        // If a check in date has NOT been picked yet, set ALL booked dates to hash background
                        // If a check in date HAS been picked (so we're currently showing check out dates), only set booked dates that are *also* NOT okay to check out on to hash background
                        if (currentlyShowing === "checkIn")
                          return bookedDates.includes(Date.parse(date))
                            ? "datepicker-date-booked"
                            : undefined;
                        if (currentlyShowing === "checkOut")
                          return bookedAndNotOkayToCheckOutDates.includes(
                            Date.parse(date)
                          )
                            ? "datepicker-date-booked"
                            : undefined;
                      }}
                      excludeDates={datesToGrayOut}
                      renderDayContents={renderDayContents}
                      calendarClassName={
                        isInline ? "custom-datepicker-scroll" : ""
                      }
                      includeDateIntervals={[
                        {
                          start: new Date(),
                          end: new Date(MAX_END_DATE),
                        },
                      ]}
                    />
                  )}
                </Flex>
              </FormControl>
              {/* END Dates Input */}

              {/* Guests Number Input */}
              <FormControl height={"50px"} w={"100%"} mt={4}>
                <Flex
                  gap={2}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Flex ml={1} w={"100%"}>
                    <Box display={{ base: "none", xl: "flex" }}>
                      Number of&nbsp;
                    </Box>
                    Guests
                  </Flex>
                  <Flex alignItems={"center"} gap={2}>
                    <Button
                      onClick={decrementGuestInput}
                      variant="outline"
                      borderColor="dmNavy.500"
                    >
                      -
                    </Button>
                    <Input
                      textAlign={"center"}
                      p="10px"
                      w="40px"
                      color="black"
                      type="number"
                      border="0"
                      onChange={(e) => {
                        const newValue = parseInt(e.target.value, 10);
                        if (
                          !isNaN(newValue) &&
                          newValue >= 1 &&
                          newValue <= houseData.occupancy
                        ) {
                          setGuests(newValue);
                        }
                      }}
                      value={guests || ""}
                    />
                    <Button
                      onClick={incrementGuestInput}
                      variant="outline"
                      borderColor="dmNavy.500"
                    >
                      +
                    </Button>
                  </Flex>
                </Flex>
              </FormControl>
              {/* END Guests Number Input */}

              {/* Display Spinner While Quote Loading */}
              {listingStartDate &&
                listingEndDate &&
                guests &&
                !totalPrice &&
                !errorMessage && (
                  <Flex
                    w={"100%"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    mt={7}
                    mb={5}
                  >
                    <Spinner size="xl" color="dmOrange" />
                  </Flex>
                )}

              {/* Pricing Info */}
              {listingStartDate && listingEndDate && totalPrice && (
                <>
                  {isInline ? (
                    <Flex flexDirection={"column"} gap={2} mt={2}>
                      <Flex justifyContent={"space-between"} w="100%">
                        <Flex>Nightly Rates</Flex>
                        <Flex>
                          {nightlyRates.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })}
                        </Flex>
                      </Flex>
                      <Flex flexDirection="column" w="100%">
                        <Flex justifyContent={"space-between"} w="100%">
                          <Flex>
                            <Flex>Taxes and Fees</Flex>
                          </Flex>
                          <Flex>
                            {totalTaxesAndFees.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })}
                          </Flex>
                        </Flex>
                        {discount && (
                          <Flex
                            justifyContent={"space-between"}
                            w="100%"
                            mt="10px"
                            fontWeight={"bold"}
                          >
                            <Flex gap={2} alignItems={"center"}>
                              <Flex>Rebooking Discount</Flex>
                              <CheckCircleIcon color="green" />
                            </Flex>
                            <Flex>
                              {discount.toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                              })}
                            </Flex>
                          </Flex>
                        )}
                      </Flex>
                      <Divider my={1}></Divider>
                      <Flex
                        justifyContent={"space-between"}
                        w="100%"
                        fontWeight={"500"}
                      >
                        <Flex>Total</Flex>
                        <Flex>
                          {totalPrice.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })}
                        </Flex>
                      </Flex>
                    </Flex>
                  ) : (
                    <Flex flexDirection={"column"} gap={2} mt={2}>
                      <Flex fontWeight={"500"} fontSize="18px">
                        Price Details
                      </Flex>
                      <Flex justifyContent={"space-between"} w="100%">
                        <Flex>Nightly Rates</Flex>
                        <Flex>
                          {nightlyRates.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })}
                        </Flex>
                      </Flex>
                      <Flex flexDirection="column" w="100%">
                        <Flex justifyContent={"space-between"} w="100%">
                          <Flex>
                            <Flex>Fees</Flex>
                            <Box
                              onClick={toggleFeesExpand}
                              aria-label={
                                isFeesExpanded ? "Collapse Fees" : "Expand Fees"
                              }
                              width={8}
                              _hover={{ cursor: "pointer" }}
                              mt={"-1px"}
                              ml={2}
                            >
                              {isFeesExpanded ? (
                                <ChevronUpIcon />
                              ) : (
                                <ChevronDownIcon />
                              )}
                            </Box>
                          </Flex>
                          <Flex>
                            {totalFees.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })}
                          </Flex>
                        </Flex>
                        {isFeesExpanded && (
                          <Flex
                            flexDirection="column"
                            w="100%"
                            fontSize={"14px"}
                            color={"#00000080"}
                          >
                            {fees.map((item, index) => (
                              <Flex
                                justifyContent={"space-between"}
                                w="100%"
                                key={`fee-${index}`}
                                mt={1}
                              >
                                <Flex>
                                  {item.title
                                    .toLowerCase()
                                    .replace(/_(.)/g, " $1")
                                    .replace(/^./, (str) => str.toUpperCase())}
                                </Flex>
                                <Flex>
                                  {item.amount.toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "USD",
                                  })}
                                </Flex>
                              </Flex>
                            ))}
                          </Flex>
                        )}
                        <Flex
                          justifyContent={"space-between"}
                          w="100%"
                          mt="10px"
                        >
                          <Flex>
                            <Flex>Taxes</Flex>
                            <Box
                              onClick={toggleTaxesExpand}
                              aria-label={
                                isTaxesExpanded
                                  ? "Collapse Taxes"
                                  : "Expand Taxes"
                              }
                              width={8}
                              _hover={{ cursor: "pointer" }}
                              mt={"-1px"}
                              ml={2}
                            >
                              {isTaxesExpanded ? (
                                <ChevronUpIcon />
                              ) : (
                                <ChevronDownIcon />
                              )}
                            </Box>
                          </Flex>
                          <Flex>
                            {totalTaxes.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })}
                          </Flex>
                        </Flex>
                        {isTaxesExpanded && (
                          <Flex
                            flexDirection="column"
                            w="100%"
                            fontSize={"14px"}
                            color={"#00000080"}
                          >
                            {taxes.map((item, index) => (
                              <Flex
                                justifyContent={"space-between"}
                                w="100%"
                                key={`tax-${index}`}
                                mt={1}
                              >
                                <Flex>
                                  {item.title
                                    .toLowerCase()
                                    .replace(/_(.)/g, " $1")
                                    .replace(/^./, (str) => str.toUpperCase())}
                                </Flex>
                                <Flex>
                                  {item.amount.toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "USD",
                                  })}
                                </Flex>
                              </Flex>
                            ))}
                          </Flex>
                        )}
                        {discount && (
                          <Flex
                            justifyContent={"space-between"}
                            w="100%"
                            mt="10px"
                            fontWeight={"bold"}
                          >
                            <Flex gap={2} alignItems={"center"}>
                              <Flex>Rebooking Discount</Flex>
                              <CheckCircleIcon color="green" />
                            </Flex>
                            <Flex>
                              {discount.toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                              })}
                            </Flex>
                          </Flex>
                        )}
                      </Flex>
                      <Divider my={1}></Divider>
                      <Flex
                        justifyContent={"space-between"}
                        w="100%"
                        fontWeight={"500"}
                      >
                        <Flex>Total</Flex>
                        <Flex>
                          {totalPrice.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })}
                        </Flex>
                      </Flex>
                    </Flex>
                  )}
                </>
              )}
              {/* Submit Button - Book This Home */}
              {listingStartDate && listingEndDate && guests && totalPrice ? (
                <Button
                  h={{ base: "50px", md: "50px" }}
                  mt={{ base: 3, md: 6 }}
                  onClick={handleSubmit}
                  colorScheme="dmOrange"
                  disabled={!dateRange || !guests} // Disable if either is not known
                >
                  Continue to checkout
                </Button>
              ) : (
                <Button
                  h={{ base: "50px", md: "50px" }}
                  mt={{ base: 3, md: 6 }}
                  colorScheme="dmOrange"
                  isDisabled
                >
                  Select dates for pricing
                </Button>
              )}

              {/* Inquiry Button, hidden from ADMIN user */}
              {!userIsAdmin && (
                <Button
                  h={{ base: "50px", md: "50px" }}
                  mt={{ base: 3, md: 4 }}
                  onClick={onOpenInquiryModal}
                  colorScheme="dmOrange"
                  variant="outline"
                  //disabled={!dateRange || !guests} // Disable if either is not known
                >
                  Inquire about this home
                </Button>
              )}
              <InquiryModal
                isOpen={isInquiryModalOpen}
                onClose={onCloseInquiryModal}
              />

              {/* ADMIN Only: Add to Quote Button */}
              {userIsAdmin && activeQuoteNumber && (
                <Flex w={"100%"} gap={3}>
                  <Tooltip
                    isDisabled={
                      !(
                        !listingStartDate ||
                        !listingEndDate ||
                        !guests ||
                        !totalPrice ||
                        !activeQuoteNumber
                      )
                    }
                    label="Dates, guests, and price must be set to add to quote."
                    aria-label="Dates, guests, and price must be set to add to quote."
                    p={3}
                    placement={"top"}
                    hasArrow
                  >
                    <Button
                      h={{ base: "50px", md: "50px" }}
                      mt={{ base: 3, md: 4 }}
                      onClick={addToQuote}
                      colorScheme="dmNavy"
                      variant="outline"
                      w={"60%"}
                      isDisabled={
                        addedToQuote ||
                        !listingStartDate ||
                        !listingEndDate ||
                        !guests ||
                        !totalPrice ||
                        !activeQuoteNumber
                      }
                    >
                      {addedToQuote ? "Added to Quote " : "Add to Quote "}
                      {activeQuoteNumber}
                    </Button>
                  </Tooltip>
                  <Button
                    h={{ base: "50px", md: "50px" }}
                    mt={{ base: 3, md: 4 }}
                    onClick={() => navigate(`../quotes/${activeQuoteNumber}`)}
                    colorScheme="dmNavy"
                    variant="outline"
                    w={"40%"}
                  >
                    View Quote
                  </Button>
                </Flex>
              )}
            </Flex>
            {/* End Datepicker Form */}

            {errorMessage && (
              <>
                {errorMessage.includes("rate plans are not applicable") ? (
                  <Text color="red.500" px={4} pb={3}>
                    {
                      "The dates you have selected are not available for this property. Please try again."
                    }
                  </Text>
                ) : (
                  <Text color="red.500" px={4} pb={3}>
                    {
                      "Error retrieving reservation quote. Please contact us for assistance."
                    }
                  </Text>
                )}
              </>
            )}
          </Flex>
          {/* End Floating Card */}
        </>
      )}
    </Flex>
  );

  if (houseLoading) {
    return <SkeletonBlock />;
  }

  if (!houseLoading && houseData && !houseData.activeListing) {
    return <NotAcceptedBlock />;
  }

  return (
    <Flex
      position={"relative"}
      width={"full"}
      borderTop={{ base: 0, md: "10px" }}
      borderStyle={"solid"}
      borderColor={"white"}
      data-id={"listing"}
      p={{ base: 0, md: 5 }}
      justifyContent={"center"}
      flexDirection={"column"}
      alignItems={"center"}
    >
      <ScrollToTop />
      <Flex
        w={"100%"}
        flexDirection={{ base: "column-reverse", md: "column" }}
        alignItems={"center"}
      >
        {/* Top Bar */}
        <TopBar />

        {/* Photogallery */}
        <PhotoGallery />
      </Flex>

      {/* Body Section */}
      <Flex
        maxWidth="1440px"
        w={"100%"}
        p={{ base: 4, md: 2 }}
        flexDirection={{ base: "column", md: "row" }}
      >
        {/* Home Details Section */}
        <Flex w={{ base: "100%", md: "67%" }} flexDirection={"column"}>
          {/* Occupancy, Bedroom, Bathroom Icons */}
          <Details />

          {/* Calendar */}
          <Flex flexDirection={"column"} w={"100%"}>
            <Box fontWeight={500} pb={1} fontSize={20}>
              {listingStartDate
                ? listingEndDate
                  ? houseData.neighborhood
                    ? `${Math.ceil(
                        (listingEndDate - listingStartDate) / 86400000
                      )} Nights in ${houseData.neighborhood}`
                    : `${Math.ceil(
                        (listingEndDate - listingStartDate) / 86400000
                      )} Nights In ${houseData.town}`
                  : "Select check-out date"
                : "Select check-in date:"}
            </Box>
            <Box fontSize={15} color={"#666"} pb={2}>
              {listingStartDate && listingEndDate
                ? `${formatFriendlyDate(
                    listingStartDate
                  )} - ${formatFriendlyDate(listingEndDate)}`
                : "Please select dates"}
            </Box>
            {globalCheckIns && globalCheckIns.length > 0 && (
              <DatePicker
                placeholderText="Select Dates"
                h={"100%"}
                isClearable={true}
                selectsRange={true}
                startDate={listingStartDate}
                endDate={listingEndDate}
                monthsShown={monthsShown}
                inline
                onChange={(update) => {
                  //setDateRange(update)
                  //setContextDateRange(update)
                  setListingStartDate(update[0]);
                  setListingEndDate(update[1]);
                  //console.log("update", update)

                  // Gray out bad check in dates either: (1) on first load (when both are null) *or* (2) when both are NOT null
                  // (So the user can't click on a bad check in date when a date range is already selected)
                  const grayOutBadCheckInDates =
                    (update[0] === null && update[1] === null) ||
                    (update[0] !== null && update[1] !== null);
                  // Gray out bad check out dates when a check out date hasn't been selected yet
                  const grayOutBadCheckOutDates =
                    update[0] !== null && update[1] === null;

                  if (grayOutBadCheckOutDates) {
                    setCurrentlyShowing("checkOut");
                    // Set the datepicker to gray out dates that can't be checked OUT
                    // First, find which dates ARE okay to check out for the user-selected check in date (update[0])
                    const datesOkayToCheckOut = processCheckOuts(
                      update[0],
                      guestyData
                    );
                    //console.log("datesOkayToCheckOut", datesOkayToCheckOut)
                    // Create an array of all the dates NOT in that array (ie: the ones that are NOT okay to check out)
                    const datesNotOkayToCheckOut = guestyData
                      .filter(
                        (date) => !datesOkayToCheckOut.includes(date.startDate)
                      )
                      .map((date) => date.startDate);
                    const datesNotOkayToCheckOutEpoch = guestyData
                      .filter(
                        (date) => !datesOkayToCheckOut.includes(date.startDate)
                      )
                      .map((date) => Date.parse(date.startDate));
                    //console.log("datesNotOkayToCheckOut", datesNotOkayToCheckOut)
                    // Set those dates (the ones NOT okay to check out) to be grayed out
                    setDatesToGrayOut(datesNotOkayToCheckOut);
                    setDatesToGrayOutEpoch(datesNotOkayToCheckOutEpoch);

                    // We have to create a new array to find the dates that are booked AND not okay to check out because datesNotOkayToCheckOut is dates only, do extra data
                    const datesNotOkayToCheckOutWithExtraData =
                      guestyData.filter(
                        (date) => !datesOkayToCheckOut.includes(date.startDate)
                      );
                    const getBookedAndNotOkayToCheckOutDates =
                      datesNotOkayToCheckOutWithExtraData
                        .filter((date) => date.status === "booked")
                        .map((date) => Date.parse(date.startDate));
                    setBookedAndNotOkayToCheckOutDates(
                      getBookedAndNotOkayToCheckOutDates
                    );
                  } else if (grayOutBadCheckInDates) {
                    setCurrentlyShowing("checkIn");
                    // Set the datepicker to gray out dates that can't be checked IN (basically same thing as what it does onload)
                    setDatesToGrayOut(datesNotOkayToCheckIn);
                    setDatesToGrayOutEpoch(datesNotOkayToCheckInEpoch);
                  }
                }}
                dayClassName={(date) => {
                  // If a check in date has NOT been picked yet, set ALL booked dates to hash background
                  // If a check in date HAS been picked (so we're currently showing check out dates), only set booked dates that are *also* NOT okay to check out on to hash background
                  if (currentlyShowing === "checkIn")
                    return bookedDates.includes(Date.parse(date))
                      ? "datepicker-date-booked"
                      : undefined;
                  if (currentlyShowing === "checkOut")
                    return bookedAndNotOkayToCheckOutDates.includes(
                      Date.parse(date)
                    )
                      ? "datepicker-date-booked"
                      : undefined;
                }}
                excludeDates={datesToGrayOut}
                renderDayContents={renderDayContents}
                includeDateIntervals={[
                  {
                    start: new Date(),
                    end: addDays(new Date(), 366),
                  },
                ]}
              />
            )}
            <Link
              textDecoration={"underline"}
              fontSize={"14px"}
              onClick={clearDates}
              mt={1}
            >
              Clear Dates
            </Link>
          </Flex>

          <Divider my={8} color={"black"} />

          {/* Reviews Section */}
          {houseData.reviews > 0 && (
            <Flex flexDirection={"column"}>
              <Flex
                fontWeight={500}
                pb={5}
                fontSize={24}
                className="listing-reviews-parent"
                alignItems={"center"}
              >
                <Image
                  src={"../media/ratingstar.png"}
                  alt="Home Rating"
                  objectFit={"contain"}
                  align={"left"}
                  width={"28px"}
                  height={"28px"}
                  className="listing-rating-star"
                  mx={3}
                />
                <Flex className="listing-rating" pr={10}>
                  {!isNaN(houseData.rating) && houseData.rating !== null
                    ? houseData.rating.toFixed(1)
                    : "0"}
                </Flex>
                <Flex
                  className="listing-reviews"
                  fontSize={20}
                  fontWeight={500}
                  onClick={onOpenReviewModal}
                  sx={{ "&:hover": { cursor: "pointer" } }}
                >
                  {!isNaN(houseData.reviews)
                    ? (houseData.reviews as number)
                    : 0}{" "}
                  Reviews
                </Flex>
              </Flex>
              <Grid
                templateColumns={{
                  base: "repeat(1, 1fr)",
                  md: "repeat(2, 1fr)",
                }}
                gap={6}
                alignItems="stretch"
              >
                {houseData.rawReviews
                  .sort(
                    (a, b) =>
                      new Date(b.submittedAt).getTime() -
                      new Date(a.submittedAt).getTime()
                  )
                  .slice(0, 4)
                  .map((review, index) => {
                    const roundedRating = Math.round(review.rating * 2) / 2; // Round to nearest 0.5
                    const fullStars = Math.floor(roundedRating);
                    const halfStar = roundedRating % 1 !== 0;
                    const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);

                    return (
                      <Card
                        key={index + review.rating + review.publicReview}
                        boxShadow={"dmShadow"}
                      >
                        <CardBody p={8}>
                          <Flex className="star-rating" h={5} ml={0} mb={2}>
                            {Array.from({ length: fullStars }, (_, i) => (
                              <Image
                                key={i}
                                src="../media/ratingstar.png"
                                alt="star"
                              />
                            ))}
                            {halfStar && (
                              <Image
                                src="../media/halfstar.png"
                                alt="half star"
                              />
                            )}
                            {Array.from({ length: emptyStars }, (_, i) => (
                              <Image
                                key={`empty-${i}`}
                                src="../media/emptystar.png"
                                alt="empty star"
                              />
                            ))}
                          </Flex>
                          <Flex my={1}>
                            {review.firstName && (
                              <Text fontSize={"14px"} fontWeight={"500"}>
                                {review.firstName}
                                <Text as="span" mx={2}>
                                  &#8226;
                                </Text>
                              </Text>
                            )}
                          </Flex>
                          <Text>{review.publicReview}</Text>
                        </CardBody>
                      </Card>
                    );
                  })}
              </Grid>

              {houseData.rawReviews.length > 4 && (
                <Button
                  mt={10}
                  colorScheme="dmNavy"
                  variant="outline"
                  onClick={onOpenReviewModal}
                >
                  Show {houseData.rawReviews.length} recent reviews
                </Button>
              )}

              {/* Modal for displaying all reviews */}
              <Modal
                isOpen={isReviewModalOpen}
                onClose={onCloseReviewModal}
                scrollBehavior="inside"
                size="4xl"
              >
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>
                    Recent Reviews for {houseData.houseNumber}
                  </ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <Flex flexDirection="column" overflowY="auto">
                      <Flex
                        fontWeight={500}
                        pb={4}
                        fontSize={24}
                        className="listing-reviews-parent"
                        alignItems={"center"}
                      >
                        <Image
                          src={"../media/ratingstar.png"}
                          alt="Home Rating"
                          objectFit={"contain"}
                          align={"left"}
                          width={"28px"}
                          height={"28px"}
                          className="listing-rating-star"
                          mx={3}
                        />
                        <Flex className="listing-rating" pr={8}>
                          {!isNaN(houseData.rating) && houseData.rating !== null
                            ? houseData.rating.toFixed(1)
                            : "0"}
                        </Flex>
                        <Flex
                          className="listing-reviews"
                          fontSize={20}
                          fontWeight={500}
                        >
                          {!isNaN(houseData.reviews)
                            ? (houseData.reviews as number)
                            : 0}{" "}
                          Reviews
                        </Flex>
                      </Flex>
                      <Grid
                        templateColumns={{
                          base: "repeat(1, 1fr)",
                          md: "repeat(1, 1fr)",
                        }}
                        gap={2}
                        alignItems="stretch"
                      >
                        {houseData.rawReviews
                          .sort(
                            (a, b) =>
                              new Date(b.submittedAt).getTime() -
                              new Date(a.submittedAt).getTime()
                          )
                          .map((review, index) => {
                            const roundedRating =
                              Math.round(review.rating * 2) / 2; // Round to nearest 0.5
                            const fullStars = Math.floor(roundedRating);
                            const halfStar = roundedRating % 1 !== 0;

                            return (
                              <Card
                                key={
                                  index +
                                  review.rating +
                                  review.publicReview +
                                  "modal"
                                }
                                boxShadow={"none"}
                              >
                                <CardBody p={8}>
                                  <Flex
                                    className="star-rating"
                                    h={5}
                                    ml={0}
                                    mb={2}
                                  >
                                    {Array.from(
                                      { length: fullStars },
                                      (_, i) => (
                                        <Image
                                          key={i}
                                          src="../media/ratingstar.png"
                                          alt="star"
                                        />
                                      )
                                    )}
                                    {halfStar && (
                                      <Image
                                        src="../media/halfstar.png"
                                        alt="half star"
                                      />
                                    )}
                                  </Flex>
                                  <Flex my={1}>
                                    {review.firstName && (
                                      <Text
                                        fontSize={"14px"}
                                        fontWeight={"500"}
                                      >
                                        {review.firstName}
                                        <Text as="span" mx={2}>
                                          &#8226;
                                        </Text>
                                      </Text>
                                    )}
                                    <Text fontSize={"14px"}>
                                      Visited{" "}
                                      {new Date(
                                        review.submittedAt
                                      ).toLocaleString("en-US", {
                                        month: "long",
                                        year: "numeric",
                                      })}
                                    </Text>
                                  </Flex>
                                  <Text>{review.publicReview}</Text>
                                </CardBody>
                              </Card>
                            );
                          })}
                      </Grid>
                    </Flex>
                  </ModalBody>
                </ModalContent>
              </Modal>
            </Flex>
          )}
          {/* End Reviews Section */}

          {houseData.reviews > 0 && <Divider my={8} color={"black"} />}

          {/* Location Section */}
          <Flex flexDirection={"column"} minH={"550px"}>
            <Box fontWeight={500} mb={4} fontSize={20}>
              House Location
            </Box>
            <AspectRatio ratio={16 / 9}>
              <HouseMap
                latitude={parseFloat(houseData.latitude)}
                longitude={parseFloat(houseData.longitude)}
              />
            </AspectRatio>
          </Flex>
          {/* End Location Section */}
        </Flex>

        {/* Mobile Fixed Bottom Booking Menu */}
        <Flex
          bgColor={"dmNavy.500"}
          width={"100vw"}
          height="75px"
          display={{ base: "flex", md: "none" }}
          position="fixed"
          bottom="0"
          left="0"
          zIndex="500"
          justifyContent={"space-between"}
          px={4}
          alignItems={"center"}
        >
          <Flex
            color={"white"}
            fontSize={14}
            flexDirection={"column"}
            textAlign={"left"}
            onClick={onOpenBookingModal}
          >
            <Box pb={1}>
              {listingStartDate && listingEndDate
                ? `${formatFriendlyDate(
                    listingStartDate
                  )} - ${formatFriendlyDate(listingEndDate)}`
                : "Please select dates"}
            </Box>
            {guests > 0 && totalPrice ? (
              <Box fontWeight={"400"}>
                {totalPrice.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}{" "}
                Total
              </Box>
            ) : (
              listingStartDate &&
              listingEndDate &&
              !guests && <Box fontWeight={"400"}>Please select guests</Box>
            )}
          </Flex>
          {/* Submit Button */}
          {listingStartDate && listingEndDate && guests && totalPrice ? (
            <Button
              h={"50px"}
              onClick={handleSubmit}
              disabled={!houseData}
              colorScheme="dmOrange"
            >
              Book this home
            </Button>
          ) : listingStartDate && listingEndDate && guests ? (
            <Button
              h={"50px"}
              px={8}
              onClick={handleSubmit}
              colorScheme="dmOrange"
            >
              Get Pricing
            </Button>
          ) : (
            <Button
              h={"50px"}
              px={8}
              onClick={onOpenBookingModal}
              colorScheme="dmOrange"
            >
              Get Pricing
            </Button>
          )}
        </Flex>
        {/* END Mobile Fixed Bottom Booking Menu */}

        {/* Booking Section */}
        {isMobile ? (
          <Modal
            isOpen={isBookingModalOpen}
            onClose={onCloseBookingModal}
            motionPreset="slideInBottom"
            size={"full"}
          >
            <ModalOverlay />
            <ModalContent
              top={"0px"}
              position="fixed"
              w="100%"
              maxW="100vw"
              h="auto"
              mt={"0px"}
              borderRadius="0px"
            >
              <ModalCloseButton zIndex={"50"} />
              {bookingContent}
            </ModalContent>
          </Modal>
        ) : (
          <Flex
            w={{ base: "100%", md: "38%", lg: "33%" }}
            ml={{ base: 0, md: 10 }}
            mt={5}
          >
            {bookingContent}
          </Flex>
        )}
      </Flex>
      {/* End Body Section */}
    </Flex>
  );
};

export { ListingBlock };
