import { ChevronLeftIcon } from "@chakra-ui/icons";
import { Button, Flex, Text, useDisclosure } from "@chakra-ui/react";
import { useUser } from "@clerk/clerk-react";
import * as React from "react";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import PlacesMap from "../components/PlacesMap";
import { MobileMenu } from "../components/guestportal/MobileMenu";
import { PlacesGridWidget } from "../components/guestportal/PlacesGridWidget";
import { WelcomePopup } from "../components/guestportal/WelcomePopup";
import { useTrips } from "../contexts/TripContext";

const Places = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { paramReservationNumber } = useParams();

  const { user } = useUser();
  //console.log("user",user)
  const userFirstName = user.firstName;
  const userEmail = user.primaryEmailAddress.emailAddress;
  const userFirstSignIn = user.unsafeMetadata?.loggedIn ? false : true;
  const { unsafeMetadata = {} } = user;

  if (userFirstSignIn) {
    user.update({
      unsafeMetadata: { ...unsafeMetadata, loggedIn: true },
    });
  }

  const { allTripsData, updateAllTrips, selectedTrip, setSelectedTrip, pastTrips, currentFutureTrips, currentFutureCount, isTripsContextLoaded, isTripsContextValid } = useTrips();
  const [reservationSearchSuccessful, setReservationSearchSuccessful] = React.useState(false);
  const [validTripParam, setValidTripParam] = React.useState(false);

  const [places, setPlaces] = React.useState([]);
  const [placesLoading, setPlacesLoading] = React.useState(true);
  const [placesType, setPlacesType] = React.useState('Discounts/Partners');

  const handlePlacesChange = (newPlaces) => {
    setPlaces(newPlaces);
  };
  const handlePlacesLoadingChange = (isLoading) => {
    setPlacesLoading(isLoading);
  };

  React.useEffect(() => {
    updateAllTrips();
  }, [user,reservationSearchSuccessful, paramReservationNumber]);

  React.useEffect(() => {
    if (currentFutureTrips && currentFutureTrips.length > 0) {
      setSelectedTrip(selectedTrip || currentFutureTrips[0] || allTripsData[0]);
    }
  }, [currentFutureTrips]);

  //console.log("selected trip", selectedTrip)

  // Handler for No Upcoming Reservations Rebook Button Opening Past Reservations Accordion
  const accordionRef = React.useRef(null);
  const { isOpen: isAccordionOpen, onToggle: toggleAccordion, onClose: closeAccordion } = useDisclosure();

  React.useEffect(() => {
    if (location.pathname.includes('/places')) {
      setPlacesType('Beaches/Ponds')
    } else {
      setPlacesType('Discounts/Partners')
    }
  }, [user,reservationSearchSuccessful, paramReservationNumber]);
  
  //console.log("paramReservationNumber",paramReservationNumber)

  React.useEffect(() => {
    if (paramReservationNumber && isTripsContextLoaded) {
      //console.log("paramReservationNumber",paramReservationNumber)
      // Check if the current selectedTrip is not the one specified by paramReservationNumber
      if (selectedTrip?.reservationNumber !== paramReservationNumber) {
        // Find the matching trip from allTripsData using the paramReservationNumber
        const matchingTrip = allTripsData.find(trip => trip.reservationNumber === paramReservationNumber);
        // If a matching trip is found, update selectedTrip with it
        if (matchingTrip) {
          setSelectedTrip(matchingTrip);
          setValidTripParam(true);
        } else {
          // Optional: Handle the case where no matching trip is found (e.g., navigate to a "trip not found" page or show a message)
          //console.log('No matching trip found for reservation number:', paramReservationNumber);
          setValidTripParam(false);
          // navigate('/some-path'); // Uncomment and replace '/some-path' with your desired route
        }
      } else if (selectedTrip?.reservationNumber === paramReservationNumber) {
        setValidTripParam(true);
        //console.log("trip already loaded")
      }
    }
  }, [paramReservationNumber, isTripsContextLoaded]);
  

  return (
    <>
      <Flex className={'tripcontainer'} w={'100vw'}>
        { validTripParam && paramReservationNumber && selectedTrip ?
          <Flex
            pt={{ base: 2, lg: 8 }}
            justifyContent={"start"}
            alignItems={"center"}
            direction={"column"}
            w={'100vw'}
            maxW={'720px'}
            className={'places-container'}
          >
            <Flex
              direction={{ base: "column" }}
              textAlign={"left"}
              justifyContent={"center"}
              maxW={"680px"} mx={'20px'} w={'calc(100% - 40px)'}
              pb={3}
            >
              <Flex alignItems="center" fontSize={{base:16, md:20}} mb={{base:2, md:4}} justifyContent={'space-between'}>
                <Flex my={3}>
                  <ChevronLeftIcon onClick={() => window.open(`/trips/${paramReservationNumber}`,'_self')} />
                  <Text as="span" cursor="pointer" ml={2} lineHeight={'16px'} onClick={() => navigate(`/trips/${paramReservationNumber}`)} >
                    Back to Trip Details
                  </Text>
                </Flex>                
              </Flex>
              <Flex gap={{base:1,md:2}} mb={3}>
                <Button variant={'outline'} colorScheme="dmNavy" fontSize={{base:'sm',md:'md'}}
                  isDisabled={placesType==='Beaches/Ponds'}
                  onClick={() => {setPlacesType('Beaches/Ponds')}}
                >
                  Beaches
                </Button>
                <Button variant={'outline'} colorScheme="dmNavy" fontSize={{base:'sm',md:'md'}}
                  isDisabled={placesType==='Restaurants'}
                  onClick={() => {setPlacesType('Restaurants')}}
                >
                  Restaurants
                </Button>
                <Button variant={'outline'} colorScheme="dmNavy" fontSize={{base:'sm',md:'md'}}
                  isDisabled={placesType==='Shopping/Retail'}
                  onClick={() => {setPlacesType('Shopping/Retail')}}
                >
                  Shopping
                </Button>
                <Button variant={'outline'} colorScheme="dmNavy" fontSize={{base:'sm',md:'md'}}
                  isDisabled={placesType==='Discounts/Partners'}
                  onClick={() => {setPlacesType('Discounts/Partners')}}
                >
                  Discounts
                </Button>
              </Flex>
              <PlacesGridWidget
                title={placesType}
                subtitle="From our local partner businesses in Cape Cod."
                subtitleColor="#777"
                subType={placesType}
                houseData={selectedTrip.house}
                onPlacesChange={handlePlacesChange}
                onPlacesLoadingChange={setPlacesLoading}
              />
            </Flex>
          </Flex>
          :
          <Flex
            pt={{ base: 2, lg: 16 }}
            justifyContent={"start"}
            alignItems={"center"}
            direction={"column"}
            w={{base:'100vw',md:'50vw'}}
            maxW={'720px'}
            className={'places-container'}
          >
            <Flex
              direction={{ base: "column" }}
              textAlign={"left"}
              justifyContent={"center"}
              maxW={"680px"} mx={'20px'} w={'calc(100% - 40px)'}
              pb={3}
            >
            </Flex>
              <Flex alignItems="center" fontSize={{base:16, md:20}} mb={{base:2, md:4}} justifyContent={'space-between'}>
                <Flex my={3}>
                  <ChevronLeftIcon onClick={() => navigate('/trips')} />
                  <Text as="span" cursor="pointer" ml={2} lineHeight={'16px'} onClick={() => navigate('/trips')} >
                    Back to My Trips
                  </Text>
                </Flex>
              </Flex>
          </Flex>
        }

        {!placesLoading &&
          <Flex className="places-map-container" h={{base:'calc(100vh - 150px)',md:'calc(100vh - 90px)'}} 
            w={{ base: '100%' }}
            position={'sticky'} right='0px' top='80px'
            display={{base:'none',md:'flex'}}
            >          
            <PlacesMap navigate={navigate} places={places} houseData={selectedTrip.house} />
          </Flex>
        }
      </Flex>

      {isTripsContextLoaded &&
      <WelcomePopup modalInitiateOpen={userFirstSignIn} />
      }

      {isTripsContextLoaded && allTripsData.length > 0 && (
        <MobileMenu
          activeReservationData={allTripsData[allTripsData.length - 1]}
          contactOwner={{
            name: "Amanda Perry",
            title: "Guest Services Associate",
            avatar: "./media/Amanda.png",
          }}
        />
      )}
    </>
  );
};

export { Places };

